import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, RatingDescription } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllRatingDescriptions = async () => {
    return await new Promise<RatingDescription[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Rating Descriptions"));
        callServerAPI<ServerAPIResponse<RatingDescription[]>>('GET', '/api/RatingDescription/GetAllRatingDescriptions', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function updateRatingDescription(ratingDescription: RatingDescription) {
    return await new Promise<RatingDescription>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Rating Description"));
        callServerAPI<ServerAPIResponse<RatingDescription>>('PATCH', '/api/RatingDescription/UpdateRatingDescription?id=' + ratingDescription.id, ratingDescription, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Rating Description Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}
