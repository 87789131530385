import { useState } from "react";
import { Stack, TextField, MenuItem } from "@mui/material";
import { Risk } from "../helpers/Interfaces";
import { useAppSelector } from "../store/store";
import { useLocation } from "react-router-dom";
import { getRiskStatusString, getRiskPrioritisationZoneString, getFilteredAndSortedRisks } from "../helpers/Utils";
import { RiskStatus, RiskSortType, RiskAssignmentDisplay, RiskPrioritisationZones, DisplayRefreshTrigger, RiskGroupFilters } from "../helpers/Constants";
import { acceptChampionNomination, notifyNominatedChampion, rejectChampionNomination } from "../api-services/RiskApi";
import { HorizontalSplitPane } from "../layouts/HorizontalSplitPane";
import { useDisplay, useRisksForAssignment, useSelectedRisk } from "../helpers/GlobalHooks";
import { RiskAssignmentWidget } from "../components/risk-assignment/RiskAssignmentWidget";
import { RiskAssignmentDetails } from "../components/risk-assignment/RiskAssignmentDetails";

export function RiskAssignmentEditor() {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const location = useLocation();
    let queryParams = new URLSearchParams(location.search);
    const defaultRiskId: number | null = queryParams.get("defaultRiskId") == null ? null : Number(queryParams.get("defaultRiskId"))
    const defaultDisplay: number = queryParams.get("defaultDisplay") != null ? Number(queryParams.get("defaultDisplay")) : RiskAssignmentDisplay.VIEW_RISK
    // states
    const [risks, setRisks, refreshRisks] = useRisksForAssignment()
    const [riskSortType, setRiskSortType] = useState<RiskSortType>(RiskSortType.SortDateCreatedDesc)
    const [prioritisationFilter, setPrioritisationFilter] = useState('All')
    const [entityFilter, setEntityFilter] = useState('All')
    const [riskAssessmentFilter, setRiskAssessmentFilter] = useState('All')
    const [statusFilter, setStatusFilter] = useState('All')
    const [displayRefreshTrigger, setDisplayRefreshTrigger] = useState<DisplayRefreshTrigger | null>(null)
    const filteredRisks = getFilteredAndSortedRisks(loggedOnUserProfile, risks, entityFilter, "All", statusFilter, riskAssessmentFilter, prioritisationFilter, RiskGroupFilters.GLOBAL_RISKS, riskSortType)
    const { selectedRisk, setSelectedRisk } = useSelectedRisk(filteredRisks, defaultRiskId, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected)
    const { display, setDisplay } = useDisplay(filteredRisks, defaultDisplay, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected, RiskAssignmentDisplay.VIEW_RISK, RiskAssignmentDisplay.VIEW_RISK)

    function handleNotifyNominatedChampion(risk: Risk) {
        notifyNominatedChampion(risk.id)
    }

    function handleAcceptChampionNomination(risk: Risk) {
        acceptChampionNomination(risk.id).then(result => {
            setRisks(risks => risks.map(inst => inst.id === result.id ? result : inst))
            setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
            setSelectedRisk(result)
            setDisplay(RiskAssignmentDisplay.VIEW_RISK)
        })
    }

    function handleRejectChampionNomination(risk: Risk, rejectionComments: string) {
        rejectChampionNomination(risk.id, rejectionComments).then(result => {
            setRisks(risks => risks.map(inst => inst.id === result.id ? result : inst))
            setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
            setSelectedRisk(result)
            setDisplay(RiskAssignmentDisplay.VIEW_RISK)
        })
    }


    function handleRiskChange(id: number, risk: Risk) {
        setRisks(risks => risks.map(inst => inst.id === id ? risk : inst))
        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
        setSelectedRisk(risk)
        setDisplay(RiskAssignmentDisplay.VIEW_RISK)
    }

    function handleChangeRiskAssignmentDisplay(display: RiskAssignmentDisplay, risk?: Risk | null) {
        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
        if (risk != null) setSelectedRisk(risk)
        setDisplay(display);;
    }

    return (
        loggedOnUserProfile &&
        <>
            <Stack direction="row" spacing={2} style={{ width: '100%', paddingLeft: 5, paddingRight: 5, paddingTop: 0, paddingBottom: 5 }} >
                <TextField fullWidth select variant="standard" size="small" name="statusFilter" value={statusFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setStatusFilter(e.target.value) }}>
                    <MenuItem key={0} value="All">All Status</MenuItem >
                    <MenuItem key={3} value={RiskStatus.APPROVED}>{getRiskStatusString(RiskStatus.APPROVED)}</MenuItem >
                    <MenuItem key={5} value={RiskStatus.CHAMPION_NOMINATED}>{getRiskStatusString(RiskStatus.CHAMPION_NOMINATED)}</MenuItem >
                    <MenuItem key={6} value={RiskStatus.CHAMPION_NOMINATION_REJECTED}>{getRiskStatusString(RiskStatus.CHAMPION_NOMINATION_REJECTED)}</MenuItem >
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="riskAssessmentFilter" value={riskAssessmentFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setRiskAssessmentFilter(e.target.value) }}>
                    <MenuItem value="All">All Risk Types</MenuItem >
                    {[...new Map(risks.filter(row => row.sourceAssessment != null).map(item => [item.sourceAssessment!.id, item.sourceAssessment!])).values()].map(row => <MenuItem key={row!.id} value={row!.id}>{row!.assessmentName}</MenuItem >)}
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="entityFilter" value={entityFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setEntityFilter(e.target.value) }}>
                    <MenuItem value="All">All Entities</MenuItem >
                    {riskRelatedTables.entities.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem >)}
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="prioritisationFilter" value={prioritisationFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setPrioritisationFilter(e.target.value) }}>
                    <MenuItem value="All">All Prioritisation Zones</MenuItem>
                    <MenuItem value={RiskPrioritisationZones.DELEGATION_ZONE}>{getRiskPrioritisationZoneString(RiskPrioritisationZones.DELEGATION_ZONE)}</MenuItem >
                    <MenuItem value={RiskPrioritisationZones.VIGILANCE_ZONE}>{getRiskPrioritisationZoneString(RiskPrioritisationZones.VIGILANCE_ZONE)}</MenuItem >
                    <MenuItem value={RiskPrioritisationZones.VERIFICATION_ZONE}>{getRiskPrioritisationZoneString(RiskPrioritisationZones.VERIFICATION_ZONE)}</MenuItem >
                    <MenuItem value={RiskPrioritisationZones.PRIORITY_REMEDIATION}>{getRiskPrioritisationZoneString(RiskPrioritisationZones.PRIORITY_REMEDIATION)}</MenuItem >
                </TextField>
            </Stack>

            <HorizontalSplitPane leftWidth={299} rightWidthDynamic
                left={
                    <RiskAssignmentWidget risks={filteredRisks} selectedRisk={selectedRisk} onRiskSortTypeChange={setRiskSortType} onChangeRiskAssignmentDisplay={handleChangeRiskAssignmentDisplay}
                         onClickRefreshRisks={() => refreshRisks()}
                    />}
                right={
                    <RiskAssignmentDetails risks={risks} selectedRisk={selectedRisk} onRiskChange={handleRiskChange}
                        display={display} onChangeRiskAssignmentDisplay={(display, risk) => handleChangeRiskAssignmentDisplay(display, risk)}
                        onClickAcceptChampionNomination={(risk) => handleAcceptChampionNomination(risk)}
                        onClickRejectChampionNomination={(risk, comments) => handleRejectChampionNomination(risk, comments)}
                        onClickNotifyNominatedChampion={(risk) => handleNotifyNominatedChampion(risk)}
                    />}
            />

        </>

    )
}
