import { useState, useEffect } from "react";
import { getAllRisks, getAllRisksForAssignment, getAllRisksForMonitoring, getAllRisksForTreatment } from "../api-services/RiskApi";
import { getAllRiskAssessments, getRiskAssessment } from "../api-services/RiskAssessmentApi";
import { RiskAssessment, Risk } from "./Interfaces";


export function useRiskAssessment(id: number | null): [RiskAssessment | null, (riskAssessment: RiskAssessment | null) => void] {
    const [riskAssessment, setRiskAssessment] = useState<RiskAssessment | null>(null)

    useEffect(() => {
        if (id == null) return
        getRiskAssessment(id).then(result => setRiskAssessment(result));
    }, [id]);

    function updateRiskAssessment(riskAssessment: RiskAssessment | null) {
        setRiskAssessment(riskAssessment)
    }

    return [riskAssessment, updateRiskAssessment]
}

export function useRisks(): [Risk[], React.Dispatch<React.SetStateAction<Risk[]>>, () => void] {
    const [risks, setRisks] = useState<Risk[]>([])

    useEffect(() => {
        refreshRisks()
    }, [])

    function refreshRisks() {
        getAllRisks().then(risks => setRisks(risks))
    }

    return [risks, setRisks, refreshRisks]
}

export function useRisksForAssignment(): [Risk[], React.Dispatch<React.SetStateAction<Risk[]>>, () => void] {
    const [risks, setRisks] = useState<Risk[]>([])

    useEffect(() => {
        refreshRisks()
    }, [])

    function refreshRisks() {
        getAllRisksForAssignment().then(risks => setRisks(risks))
    }

    return [risks, setRisks, refreshRisks]
}

export function useRisksForTreatment(): [Risk[], React.Dispatch<React.SetStateAction<Risk[]>>, () => void] {
    const [risks, setRisks] = useState<Risk[]>([])

    useEffect(() => {
        refreshRisks()
    }, [])

    function refreshRisks() {
        getAllRisksForTreatment().then(risks => setRisks(risks))
    }

    return [risks, setRisks, refreshRisks]
}

export function useRisksForMonitoring(): [Risk[], React.Dispatch<React.SetStateAction<Risk[]>>, () => void] {
    const [risks, setRisks] = useState<Risk[]>([])

    useEffect(() => {
        refreshRisks()
    }, [])

    function refreshRisks() {
        getAllRisksForMonitoring().then(risks => setRisks(risks))
    }

    return [risks, setRisks, refreshRisks]
}

export function useSelectedRisk(risks: Risk[], defaultRiskId: number | null, useDefault: boolean, skip: boolean) {
    const [selectedRisk, setSelectedRisk] = useState<Risk | null | undefined>(null)

    useEffect(() => {//update Selected Risk when list of risks change or a default is specified
        if (skip) return //needed for when we arbitrarily select a risk
        if (useDefault && defaultRiskId != null)
            setSelectedRisk(risks.find(r => r.id === defaultRiskId))
        else
            setSelectedRisk(risks.length === 0 ? null : risks[0])
    }, [risks, defaultRiskId, useDefault, skip])

    return { selectedRisk, setSelectedRisk }
}

export function useDisplay(items: any[], defaultDisplay: number, useDefault: boolean, skip: boolean, createDisplay: number, viewDisplay: number) {
    const [display, setDisplay] = useState(defaultDisplay)

    useEffect(() => { //update display when when list of risks change or a default is specified
        if (skip) return //needed for when we arbitrarily choose a display
        if (useDefault && defaultDisplay != null)
            setDisplay(defaultDisplay)
        else
            setDisplay((items.length === 0 ? createDisplay : viewDisplay))
    }, [items, defaultDisplay, useDefault, skip, createDisplay, viewDisplay])

    return { display, setDisplay }
}

export function useRiskAssessments(): [RiskAssessment[], React.Dispatch<React.SetStateAction<RiskAssessment[]>>, () => void] {
    const [riskAssessments, setRiskAssessments] = useState<RiskAssessment[]>([])

    useEffect(() => {
        refreshRiskAssessments()
    }, [])

    function refreshRiskAssessments() {
        getAllRiskAssessments().then(result => setRiskAssessments(result))
    }

    return [riskAssessments, setRiskAssessments, refreshRiskAssessments]
}

export function useSelectedRiskAssessment(riskAssessments: RiskAssessment[], defaultRiskAssessmentId: number | null, useDefault: boolean, skip: boolean) {
    const [selectedRiskAssessment, setSelectedRiskAssessment] = useState<RiskAssessment | null | undefined>(null)

    useEffect(() => {//update Selected RiskAssessment when list of riskAssessments change or a default is specified
        if (skip) return //needed for when we arbitrarily select a risk
        if (useDefault && defaultRiskAssessmentId != null)
            setSelectedRiskAssessment(riskAssessments.find(r => r.id === defaultRiskAssessmentId))
        else
            setSelectedRiskAssessment(riskAssessments.length === 0 ? null : riskAssessments[0])
    }, [riskAssessments, defaultRiskAssessmentId, useDefault, skip])

    return { selectedRiskAssessment, setSelectedRiskAssessment }
}