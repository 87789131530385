import { useState } from "react";
import { Stack, TextField, MenuItem } from "@mui/material";
import { Risk } from "../helpers/Interfaces";
import { useAppSelector } from "../store/store";
import { useLocation } from "react-router-dom";
import { getRiskStatusString, getFilteredAndSortedRisks } from "../helpers/Utils";
import { RiskStatus, RiskSortType, DisplayRefreshTrigger, RiskGroupFilters, RiskMonitoringDisplay } from "../helpers/Constants";
import { HorizontalSplitPane } from "../layouts/HorizontalSplitPane";
import { useDisplay, useRisksForMonitoring, useSelectedRisk } from "../helpers/GlobalHooks";
import { RiskMonitoringWidget } from "../components/risk-monitoring/RiskMonitoringWidget";
import { RiskMonitoringDetails } from "../components/risk-monitoring/RiskMonitoringDetails";
import { flagRiskAsExposed, flagRiskAsUnexposed } from "../api-services/RiskApi";

export function RiskMonitoringEditor() {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const location = useLocation();
    let queryParams = new URLSearchParams(location.search);
    const defaultRiskId: number | null = queryParams.get("defaultRiskId") == null ? null : Number(queryParams.get("defaultRiskId"))
    const defaultDisplay: number = queryParams.get("defaultDisplay") != null ? Number(queryParams.get("defaultDisplay")) : RiskMonitoringDisplay.VIEW_RISK
    // states
    const [risks, setRisks, refreshRisks] = useRisksForMonitoring()
    const [riskSortType, setRiskSortType] = useState<RiskSortType>(RiskSortType.SortDateCreatedDesc)
    const [categoryFilter, setCategoryFilter] = useState('All')
    const [entityFilter, setEntityFilter] = useState('All')
    const [riskAssessmentFilter, setRiskAssessmentFilter] = useState('All')
    const [statusFilter, setStatusFilter] = useState('All')
    const [displayRefreshTrigger, setDisplayRefreshTrigger] = useState<DisplayRefreshTrigger | null>(null)
    const filteredRisks = getFilteredAndSortedRisks(loggedOnUserProfile, risks, entityFilter, categoryFilter, statusFilter, riskAssessmentFilter, "All", RiskGroupFilters.GLOBAL_RISKS, riskSortType)
    const { selectedRisk, setSelectedRisk } = useSelectedRisk(filteredRisks, defaultRiskId, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected)
    const { display, setDisplay } = useDisplay(filteredRisks, defaultDisplay, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected, RiskMonitoringDisplay.VIEW_RISK, RiskMonitoringDisplay.VIEW_RISK)


    function handleRiskChange(id: number, risk: Risk) {
        setRisks(risks => risks.map(inst => inst.id === id ? risk : inst))
        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
        setSelectedRisk(risk)
        setDisplay(RiskMonitoringDisplay.VIEW_RISK)
    }

    function handleChangeRiskMonitoringDisplay(display: RiskMonitoringDisplay, risk?: Risk | null) {
        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
        if (risk != null) setSelectedRisk(risk)
        setDisplay(display);;
    }

    function handleFlagAsUnexposed(risk: Risk | null | undefined) {
        if (risk == null) return
        flagRiskAsUnexposed(risk).then(() => {
            setRisks(risks => risks.map(inst => inst.id !== risk.id ? inst : { ...inst, status: RiskStatus.UNEXPOSED }))
            setSelectedRisk({ ...risk, status: RiskStatus.UNEXPOSED })
        })
    }

    function handleFlagAsExposed(risk: Risk | null | undefined) {
        if (risk == null) return
        flagRiskAsExposed(risk).then(() => {
            setRisks(risks => risks.map(inst => inst.id !== risk.id ? inst : { ...inst, status: RiskStatus.APPROVED }))
            setSelectedRisk({ ...risk, status: RiskStatus.APPROVED })
        })
    }

    return (
        loggedOnUserProfile &&
        <>
            <Stack direction="row" spacing={2} style={{ width: '100%', paddingLeft: 5, paddingRight: 5, paddingTop: 0, paddingBottom: 5 }} >
                <TextField fullWidth select variant="standard" size="small" name="statusFilter" value={statusFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setStatusFilter(e.target.value) }}>
                    <MenuItem key={0} value="All">All Status</MenuItem >
                    <MenuItem key={1} value={RiskStatus.IDENTIFIED}>{getRiskStatusString(RiskStatus.IDENTIFIED)}</MenuItem >
                    <MenuItem key={2} value={RiskStatus.SUBMITTED}>{getRiskStatusString(RiskStatus.SUBMITTED)}</MenuItem >
                    <MenuItem key={3} value={RiskStatus.APPROVED}>{getRiskStatusString(RiskStatus.APPROVED)}</MenuItem >
                    <MenuItem key={5} value={RiskStatus.CHAMPION_NOMINATED}>{getRiskStatusString(RiskStatus.CHAMPION_NOMINATED)}</MenuItem >
                    <MenuItem key={6} value={RiskStatus.CHAMPION_NOMINATION_REJECTED}>{getRiskStatusString(RiskStatus.CHAMPION_NOMINATION_REJECTED)}</MenuItem >
                    <MenuItem key={7} value={RiskStatus.ASSIGNED_TO_CHAMPION}>{getRiskStatusString(RiskStatus.ASSIGNED_TO_CHAMPION)}</MenuItem >
                    <MenuItem key={8} value={RiskStatus.ACTION_PLAN_SUBMITTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_SUBMITTED)}</MenuItem >
                    <MenuItem key={9} value={RiskStatus.ACTION_PLAN_REJECTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_REJECTED)}</MenuItem >
                    <MenuItem key={10} value={RiskStatus.ACTION_PLAN_APPROVED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_APPROVED)}</MenuItem >
                    <MenuItem key={12} value={RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED)}</MenuItem >
                    <MenuItem key={13} value={RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR}>{getRiskStatusString(RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR)}</MenuItem >
                    <MenuItem key={19} value={RiskStatus.UNEXPOSED}>{getRiskStatusString(RiskStatus.UNEXPOSED)}</MenuItem >
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="riskAssessmentFilter" value={riskAssessmentFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setRiskAssessmentFilter(e.target.value) }}>
                    <MenuItem value="All">All Risk Types</MenuItem >
                    <MenuItem value='0'>Unassessed Risk</MenuItem >
                    {[...new Map(risks.filter(row => row.sourceAssessment != null).map(item => [item.sourceAssessment!.id, item.sourceAssessment!])).values()].map(row => <MenuItem key={row!.id} value={row!.id}>{row!.assessmentName}</MenuItem >)}
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="entityFilter" value={entityFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setEntityFilter(e.target.value) }}>
                    <MenuItem value="All">All Entities</MenuItem >
                    {riskRelatedTables.entities.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem >)}
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="categoryFilter" value={categoryFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setCategoryFilter(e.target.value) }}>
                    <MenuItem value="All">All Categories</MenuItem >
                    {riskRelatedTables.categories.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem >)}
                </TextField>
            </Stack>

            <HorizontalSplitPane leftWidth={299} rightWidthDynamic
                left={
                    <RiskMonitoringWidget risks={filteredRisks} selectedRisk={selectedRisk} onRiskSortTypeChange={setRiskSortType}
                        onChangeRiskMonitoringDisplay={handleChangeRiskMonitoringDisplay} onClickRefreshRisks={() => refreshRisks()}
                    />}
                right={
                    <RiskMonitoringDetails risks={risks} selectedRisk={selectedRisk} onRiskChange={handleRiskChange}
                        display={display} onChangeRiskMonitoringDisplay={handleChangeRiskMonitoringDisplay}
                        onFlagRiskAsUnexposed={handleFlagAsUnexposed} onFlagRiskAsExposed={handleFlagAsExposed}
                    />}
            />
        </>
    )
}
