import { configureStore } from '@reduxjs/toolkit';
import loggedOnUserProfile from './loggedOnUserProfile';
import accessToken from './accessToken';
import serverGlobalSettings from './serverGlobalSettings';
import { useDispatch, useSelector } from 'react-redux'
import riskRelatedTables from './riskRelatedTables';
import appConfig from './appConfig';

export const store = configureStore({
  reducer: {
    loggedOnUserProfile: loggedOnUserProfile,
    appConfig: appConfig,
    accessToken: accessToken,
    serverGlobalSettings: serverGlobalSettings,
    riskRelatedTables: riskRelatedTables
  },
})

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()