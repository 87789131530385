import { getImpactAreaRatingDescription, getRatingDescription, getUserFullName, getRiskStatusString, getRiskPrioritisationZoneString, getEntitiesListAsSpan, formatLongDateString, getRiskActionStatusString } from "../../helpers/Utils";
import { ImpactLikelihoodColor, PrioritisationColor, MitigationColor, SeverityDescription, SeverityColor } from "../../helpers/Constants"
import { Grid, Typography, Divider, Stack, Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PictureAsPdf } from "@mui/icons-material";
import { Risk } from "../../helpers/Interfaces";
import { useAppSelector } from "../../store/store";
import { getRiskPdf } from "../../api-services/RiskApi";

interface ViewRiskProps {
    risk: Risk
}

export function ViewRisk({ risk }: ViewRiskProps) {
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);

    function exportRiskToPDF() {
        getRiskPdf(risk.id).then(blob => window.open(URL.createObjectURL(blob)))
    }

    return (

        <Grid id="ViewRiskFormDetails" container spacing={4} className="FormDetails">
            <Grid item xs={4}>
                <Typography ><span style={{ color: "#0288D1" }}>Risk ID:  &nbsp; &nbsp; &nbsp; &nbsp;</span><span style={{ color: "#000000" }}>{'ERM-' + risk.id} </span></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography ><span style={{ color: "#0288D1" }}>Status:  &nbsp; &nbsp; &nbsp; &nbsp;</span><span style={{ color: "#000000" }}>{getRiskStatusString(risk.status)} </span></Typography>
            </Grid>
            <Grid item xs={4} alignItems="flex-end" alignContent="flex-end">
                <Button onClick={() => exportRiskToPDF()} variant="contained" size="medium" color="info"  ><PictureAsPdf /> &nbsp; Export </Button>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Name</Typography>
                <Divider />
                <Typography color="textPrimary" >{risk.name ?? ''} </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Description</Typography>
                <Divider />
                <Typography color="textPrimary" >{risk.description ?? ''} </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Assessment</Typography>
                <Divider />
                <Typography color="textPrimary" >{risk.sourceAssessment == null ? 'N/A' : risk.sourceAssessment.assessmentName} </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Strategic Objective Impacted</Typography>
                <Divider />
                <Typography color="textPrimary" >{risk.businessObjectiveImpacted?.name} </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Category</Typography>
                <Divider />
                <Typography color="textPrimary" >{risk.category?.name} </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Entities Impacted {risk.isEntityRisk ? '(This is an Entity Risk Identified by Correspondent)' : ''}</Typography>
                <Divider />
                <Typography color="textPrimary" >
                    {getEntitiesListAsSpan(risk.entitiesImpacted, ',', 4)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Entity Objective Impacted</Typography>
                <Divider />
                <Typography color="textPrimary" >{risk.entityObjectivesImpacted ?? ''} </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >
                    Impact <span style={{ fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 30, width: 30, height: 30, color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[risk.impact] }}>{risk.impact}</span>
                </Typography>
                <br />
                <Divider />
                <Table style={{ minWidth: 650 }} size="small">
                    <TableHead color="primary">
                        <TableRow>
                            <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}>Impact Area</TableCell>
                            <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}>Rating</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {riskRelatedTables.impactAreas.map(row =>
                            <TableRow key={row.id}>
                                <TableCell align="left"><Typography>{row.name}</Typography></TableCell>
                                <TableCell align="left"><Typography>{getImpactAreaRatingDescription(risk.riskImpactAreaRatings.find(i => i.impactArea.id === row.id), row)} </Typography></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Grid >
            {risk.likelihood != null && <Grid item xs={12}>
                <Stack direction="column">
                    <Typography color="textSecondary" >Likelihood</Typography>
                    <Stack direction="row" spacing={2} alignItems='center'>
                        <Typography align="center" fontSize={16} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 30, width: 30, height: 30, color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[risk.likelihood] }}>{risk.likelihood}</Typography>
                        <Typography style={{ padding: 5 }} color="textPrimary" >{getRatingDescription(risk.likelihood, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}</Typography>
                    </Stack>
                </Stack>
            </Grid>}
            {risk.mitigation != null && <Grid item xs={12}>
                <Stack direction="column">
                    <Typography color="textSecondary" >Mitigation</Typography>
                    <Stack direction="row" spacing={2} alignItems='center'>
                        <Typography align="center" fontSize={16} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 30, width: 30, height: 30, color: '#FFFFFF', backgroundColor: MitigationColor[risk.mitigation] }}>{risk.mitigation}</Typography>
                        <Typography style={{ padding: 5 }} color="textPrimary" >{getRatingDescription(risk.mitigation, riskRelatedTables.ratingDescriptions, "MITIGATION")}</Typography>
                    </Stack>
                </Stack>
            </Grid>}
            {risk.severity != null && <Grid item xs={12}>
                <Stack direction="column">
                    <Typography color="textSecondary" >Severity</Typography>
                    <Stack direction="row" spacing={2} alignItems='center'>
                        <Typography align="center" fontSize={16} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 30, width: 30, height: 30, color: '#FFFFFF', backgroundColor: SeverityColor[risk.severity] }}>{risk.severity}</Typography>
                        <Typography style={{ padding: 5 }} color="textPrimary" >{SeverityDescription[risk.severity]}</Typography>
                    </Stack>
                </Stack>
            </Grid>}
            <Grid item xs={12}>
                <Typography color="textSecondary" >Existing Mitigations</Typography>
                <Divider />
                <Typography color="textPrimary" >{risk.existingMitigations ?? ''} </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Suggested Mitigations</Typography>
                <Divider />
                <Typography color="textPrimary" >{risk.suggestedMitigations ?? ''} </Typography>
            </Grid>
            {loggedOnUserProfile?.isERMTeamMember && risk.createdBy && <Grid item xs={12}>
                <Typography color="textSecondary" >Created By</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.createdBy}> {getUserFullName(risk.createdBy, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.createdDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Date Created</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.createdDate, true)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.submittedBy && <Grid item xs={12}>
                <Typography color="textSecondary" >Submitted By</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.submittedBy}> {getUserFullName(risk.submittedBy, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {risk.submittedDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Date Submitted</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.submittedDate, true)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.aggregatedBy && <Grid item xs={12}>
                <Typography color="textSecondary" >Aggregated By</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.aggregatedBy}> {getUserFullName(risk.aggregatedBy, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.aggregatedDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Date Aggregated</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.aggregatedDate, true)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.approvedBy && <Grid item xs={12}>
                <Typography color="textSecondary" >Approved By</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.approvedBy}> {getUserFullName(risk.approvedBy, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.approvedDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Date Approved</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.approvedDate, true)}</Typography>
            </Grid>}
            {risk.approvalComments !== "" && <Grid item xs={12}>
                <Typography color="textSecondary" >Approval Comments</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.approvalComments}> {risk.approvalComments}</Typography>
            </Grid>}
            {risk.prioritisation != null && <Grid item xs={12}>
                <Stack direction="column">
                    <Typography color="textSecondary" >Prioritisation</Typography>
                    <Stack direction="row" spacing={2} alignItems='center'>
                        <Typography align="center" fontSize={16} style={{ padding: 5, width: '30px', color: '#FFFFFF', backgroundColor: PrioritisationColor[risk.prioritisation] }}>{risk.prioritisation}</Typography>
                        <Typography style={{ padding: 5 }} color="textPrimary" >{getRiskPrioritisationZoneString(risk.prioritisation)}</Typography>
                    </Stack>
                </Stack>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.assignedBy && <Grid item xs={12}>
                <Typography color="textSecondary" >Assigned By</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.assignedBy}> {getUserFullName(risk.assignedBy, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.assignedDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Date Assigned</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.assignedDate, true)}</Typography>
            </Grid>}
            {risk.targetMitigation != null && <Grid item xs={12}>
                <Stack direction="column">
                    <Typography color="textSecondary" >Target Mitigation</Typography>
                    <Stack direction="row" spacing={2} alignItems='center'>
                        <Typography align="center" fontSize={16} style={{ padding: 5, width: '30px', color: '#FFFFFF', backgroundColor: MitigationColor[risk.targetMitigation] }}>{risk.targetMitigation}</Typography>
                        <Typography style={{ padding: 5 }} color="textPrimary" >{getRatingDescription(risk.targetMitigation, riskRelatedTables.ratingDescriptions, "MITIGATION")}</Typography>
                    </Stack>
                </Stack>
            </Grid>}
            {risk.responseDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Target Risk Closure Date</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.responseDate, true)}</Typography>
            </Grid>}
            {risk.sponsor && <Grid item xs={12}>
                <Typography color="textSecondary" >Sponsor</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.sponsor}> {getUserFullName(risk.sponsor, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {risk.champion && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Champion</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.champion}> {getUserFullName(risk.champion, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {risk.champion && <Grid item xs={12}>
                <Typography color="textSecondary" >Alternate Risk Champion</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.alternateChampion ?? ""}> {getUserFullName(risk.alternateChampion, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {risk.championEntity && <Grid item xs={12}>
                <Typography color="textSecondary" >Champion Entity</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.championEntity.baseRadicalShort}> {risk.championEntity.name}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.championNominationAcceptedDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Date Champion Nomination Accepted</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.championNominationAcceptedDate, true)}</Typography>
            </Grid>}
            {risk.riskActions != null && risk.riskActions.length > 0 && <Grid item xs={12}>
                <Typography color="textSecondary" >Action Plan</Typography>
                <Divider />
                <Table style={{ minWidth: 650 }} size="small">
                    <TableHead color="primary">
                        <TableRow>
                            <TableCell align="left" style={{ backgroundColor: '#EEEEEE', minWidth: 100 }}>Timescale</TableCell>
                            <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}>Description</TableCell>
                            <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}>Status</TableCell>
                            <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}>Other Entity Responsible</TableCell>
                            <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}>Other Entity Contact</TableCell>
                            <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}>Contacted &amp; Plan Agreed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {risk.riskActions.map(row =>
                            <TableRow key={row.id}>
                                <TableCell align="left"><Typography style={{ fontSize: 12 }}>{row.timeScale} &nbsp;{row.timeScale > 1 ? row.timeScaleUnit + 's' : row.timeScaleUnit}</Typography></TableCell>
                                <TableCell align="left"><Typography style={{ fontSize: 12 }}>{row.name} </Typography></TableCell>
                                <TableCell align="left"><Typography style={{ fontSize: 12 }}>{getRiskActionStatusString(row.status, risk)} </Typography></TableCell>
                                <TableCell align="left"><Typography color="textPrimary" title={row.otherEntityResponsible?.baseRadicalShort}> {row.otherEntityResponsible?.name}</Typography></TableCell>
                                <TableCell align="left"><Typography color="textPrimary" title={row.otherEntityContact ?? ''}> {getUserFullName(row.otherEntityContact, riskRelatedTables.cmdbUsers)}</Typography></TableCell>
                                <TableCell align="left"><Typography style={{ fontSize: 12 }}>{row.otherEntityContactedAndPlanAgreed ? 'Yes' : 'No'} </Typography></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Grid >}
            {loggedOnUserProfile?.isERMTeamMember && risk.actionPlanSubmittedBy && <Grid item xs={12}>
                <Typography color="textSecondary" >Action Plan Submitted By</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.actionPlanSubmittedBy}> {getUserFullName(risk.actionPlanSubmittedBy, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.actionPlanSubmittedDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Date Action Plan Submitted</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.actionPlanSubmittedDate, true)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.actionPlanApprovedBy && <Grid item xs={12}>
                <Typography color="textSecondary" >Action Plan Approved By</Typography>
                <Divider />
                <Typography color="textPrimary" title={risk.actionPlanApprovedBy}> {getUserFullName(risk.actionPlanApprovedBy, riskRelatedTables.cmdbUsers)}</Typography>
            </Grid>}
            {loggedOnUserProfile?.isERMTeamMember && risk.actionPlanApprovedDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Date Action Plan Approved</Typography>
                <Divider />
                <Typography color="textPrimary"> {formatLongDateString(risk.actionPlanApprovedDate, true)}</Typography>
            </Grid>}
        </Grid >
    )
}
