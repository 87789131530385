import { MainMenuItem, ReportPage } from "./Interfaces";
import { AccountTree, AppRegistration, Assessment, AssignmentInd, BarChart, BubbleChart, Category, Class, Drafts, Groups, HowToVote, ListAlt, ManageAccounts, PieChart, PostAdd, Public, StarHalf, TrendingDown, GridGoldenratio, BusinessCenter, Gavel, Build, TravelExplore } from "@mui/icons-material";

export const iconColorsArray = ['Purple', 'SeaGreen', 'Maroon', 'DarkViolet', 'Red', 'Teal', 'DarkOrange', 'Blue', 'DarkGoldenrod', 'Magenta', 'Olive', 'Navy', 'Gold']

export enum AppPlatform {
    ActiveDirectoryDomain = "ActiveDirectoryDomain",
    Azure = "Azure"
}

export enum GlobalRole {
    AppUser = "User",
    Admin = "Admin",
    ERMTeamMember = "ERMTeamMember",
    Sponsor = "Sponsor",
    Guest = "Guest",
    RiskIdentificationSheetDisplayAll = "RiskIdentificationSheetDisplayAll",
    RiskMonitoringSheetDisplayAll = "RiskMonitoringSheetDisplayAll"
}

export enum FormEditMode {
    CREATE = 1,
    EDIT = 2
}

export enum AssessmentTypes {
    AFFILIATE = 1,
    PROJECT = 2,
    DRILL = 3,
    OTHERS = 4
}

export enum RiskStatus {
    IDENTIFIED = 1,
    SUBMITTED = 2,
    APPROVED = 3,
    CHAMPION_NOMINATED = 5,
    CHAMPION_NOMINATION_REJECTED = 6,
    ASSIGNED_TO_CHAMPION = 7,
    ACTION_PLAN_SUBMITTED = 8,
    ACTION_PLAN_REJECTED = 9,
    ACTION_PLAN_APPROVED = 10,
    ACTION_PLAN_IMPLEMENTATION_SUBMITTED = 15,
    ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR = 16,
    UNEXPOSED = 100
}

export enum RiskAssessmentStatus {
    NEW = 0,
    IDENTIFICATION = 1,
    AGGREGATION = 2,
    VOTING = 3,
    APPROVAL = 4,
    CLOSED = 5
}

export enum RiskActionStatus {
    DRAFT = 1,
    SUBMITTED = 2,
    REJECTED = 3,
    APPROVED = 4,
    IMPLEMENTATION_APPROVED = 5,
}

export enum RiskPrioritisationZones {
    DELEGATION_ZONE = 1,
    VIGILANCE_ZONE = 2,
    VERIFICATION_ZONE = 3,
    PRIORITY_REMEDIATION = 4
}

type KeyValuePairNumString = { [key: number]: string; }

export const ImpactLikelihoodColor: KeyValuePairNumString = {
    6: '#C00000',
    5: '#FF0000',
    4: '#EE7600',
    3: '#FFCC00',
    2: '#6ca72d',
    1: '#00642d',
    0: '#000000'
}

export const MitigationColor: KeyValuePairNumString = {
    6: '#C00000',
    5: '#FF0000',
    4: '#EE7600',
    3: '#FFCC00',
    2: '#6ca72d',
    1: '#00642d',
    0: '#000000'
}

export const SeverityColor: KeyValuePairNumString = {
    4: '#C00000',
    3: '#EE7600',
    2: '#FFCC00',
    1: '#00642d',
    0: '#000000'
}

export const PrioritisationColor: KeyValuePairNumString = {
    1: 'green',
    2: 'grey',
    3: 'blue',
    4: 'red'
}

export const SeverityDescription: KeyValuePairNumString = {
    4: 'Very Serious',
    3: 'Serious',
    2: 'Moderate',
    1: 'Minor',
    0: 'No Severity'
}

export enum ReportPageIds {
    RISKS_UNIVERSE = 0,
    FREQUENCY_OF_RISK_MENTIONS = 1,
    RISK_ANALYSIS = 2,
    RISK_CONSENSUS_OUTPUT = 3,
    RISK_PRIORITISATION_DETAILS = 4,
    RISK_VOTING_DISPERSION = 5,
    RISK_VOTING_BREAKDOWN = 6,
    RISK_VOTING_CONSENSUS = 7,
    RISK_REGISTER = 8,
    GLOBAL_EXPOSURE_DASHBOARD = 9,
    RISK_TREND = 10,
}

export var ReportPages: ReportPage[] = [
    { index: 0, pageId: ReportPageIds.RISKS_UNIVERSE, powerBiReportSectionId: 'ReportSection', header: 'Risks Universe', icon: <Public style={{ fontSize: 50 }} /> },
    { index: 1, pageId: ReportPageIds.FREQUENCY_OF_RISK_MENTIONS, powerBiReportSectionId: 'ReportSection74c9bf291af44e8a1539', header: 'Frequency of Risk Mention', icon: <PieChart style={{ fontSize: 50 }} /> },
    { index: 2, pageId: ReportPageIds.RISK_ANALYSIS, powerBiReportSectionId: 'ReportSectionddce0a6231cc83c6266b', header: 'Risk Analysis', icon: <BarChart style={{ fontSize: 50 }} /> },
    { index: 3, pageId: ReportPageIds.RISK_VOTING_BREAKDOWN, powerBiReportSectionId: 'ReportSection56e5df944df531b7904a', header: 'Risk Voting Breakdown', icon: <HowToVote style={{ fontSize: 50 }} /> },
    { index: 4, pageId: ReportPageIds.RISK_VOTING_DISPERSION, powerBiReportSectionId: 'ReportSection29d44bf05b42dfe7fbcf', header: 'Risk Voting Dispersion', icon: <Groups style={{ fontSize: 50 }} /> },
    { index: 5, pageId: ReportPageIds.RISK_VOTING_CONSENSUS, powerBiReportSectionId: 'ReportSectionb0c5556e6da5e3abc28c', header: 'Risk Voting Consensus', icon: <BubbleChart style={{ fontSize: 50 }} /> },
    { index: 6, pageId: ReportPageIds.RISK_CONSENSUS_OUTPUT, powerBiReportSectionId: 'ReportSection3671a35631173c8a1e1c', header: 'Risk Consensus Output', icon: <GridGoldenratio style={{ fontSize: 50 }} /> },
    { index: 7, pageId: ReportPageIds.RISK_PRIORITISATION_DETAILS, powerBiReportSectionId: 'ReportSection1480e2607d9d7c074450', header: 'Risk Prioritisation Details', icon: <Class style={{ fontSize: 50 }} /> },
    { index: 8, pageId: ReportPageIds.RISK_REGISTER, powerBiReportSectionId: 'ReportSectionfca4c493c91a27ce002e', header: 'Risk Register', icon: <AppRegistration style={{ fontSize: 50 }} /> },
    { index: 9, pageId: ReportPageIds.GLOBAL_EXPOSURE_DASHBOARD, powerBiReportSectionId: 'ReportSectione93d4c89c37a05ede71e', header: 'Global Exposure Dashboard', icon: <TravelExplore style={{ fontSize: 50 }} /> },
    { index: 10, pageId: ReportPageIds.RISK_TREND, powerBiReportSectionId: 'ReportSection1424830e549ad0aa53a4', header: 'Risk Trend', icon: <TrendingDown style={{ fontSize: 50 }} /> },
]

export enum RoutePathNames {
    LOGIN = "login",
    HOMEPAGE = "home",
    RISK_IDENTIFICATION = "risk-identification",
    RISK_ASSESSMENT = "risk-assessment",
    RISK_ASSIGNMENT = "risk-assignment",
    RISK_TREATMENT = "risk-treatment",
    RISK_MONITORING = "risk-monitoring",
    ENTITIES = "entities",
    CATEGORIES = "categories",
    BUSINESS_OBJECTIVES = "business-objectives",
    RATINGS = "ratings",
    IMPACTAREAS = "impactareas",
    EMAIL_TEMPLATES = "email-templates",
    VOTE_RISKASSESSMENT = "vote-risk-assessment",
    USERPROFILES = "userprofiles",
    REPORTS = "reports"
}

export var MainMenu: MainMenuItem[] = [
    { index: 1, isSetupMenu: false, pathName: RoutePathNames.HOMEPAGE, header: '', displayinMenu: false, displayinDrawer: false, homePageIcon: <PostAdd style={{ fontSize: 50 }}></PostAdd> },
    { index: 2, isSetupMenu: false, pathName: RoutePathNames.RISK_IDENTIFICATION, header: 'Risk Identification', displayinMenu: true, displayinDrawer: true, homePageIcon: <PostAdd style={{ fontSize: 60 }}></PostAdd>, drawerIcon: <PostAdd fontSize="large" /> },
    { index: 3, isSetupMenu: false, pathName: RoutePathNames.RISK_ASSESSMENT, header: 'Risk Assessment', displayinMenu: true, displayinDrawer: true, homePageIcon: <ListAlt style={{ fontSize: 60 }}></ListAlt>, drawerIcon: <ListAlt fontSize="large" /> },
    { index: 4, isSetupMenu: false, pathName: RoutePathNames.VOTE_RISKASSESSMENT, header: 'Risk Assessment Voting', displayinMenu: false, displayinDrawer: false, },
    { index: 5, isSetupMenu: false, pathName: RoutePathNames.RISK_ASSIGNMENT, header: 'Risk Assignment', displayinMenu: true, displayinDrawer: true, homePageIcon: <AssignmentInd style={{ fontSize: 60 }}></AssignmentInd>, drawerIcon: <AssignmentInd fontSize="large" /> },
    { index: 6, isSetupMenu: false, pathName: RoutePathNames.RISK_TREATMENT, header: 'Risk Treatment', displayinMenu: true, displayinDrawer: true, homePageIcon: <Build style={{ fontSize: 60 }}></Build>, drawerIcon: <Build fontSize="large" /> },
    { index: 7, isSetupMenu: false, pathName: RoutePathNames.RISK_MONITORING, hasDivider: true, header: 'Risk Monitoring', displayinMenu: true, displayinDrawer: true, homePageIcon: <TrendingDown style={{ fontSize: 60 }}></TrendingDown>, drawerIcon: <TrendingDown fontSize="large" /> },
    { index: 8, isSetupMenu: false, pathName: RoutePathNames.REPORTS, hasDivider: true, header: 'Reports', displayinMenu: true, displayinDrawer: true, homePageIcon: <Assessment style={{ fontSize: 60 }}></Assessment>, drawerIcon: <Assessment fontSize="large" /> },
    { index: 9, isSetupMenu: true, pathName: RoutePathNames.USERPROFILES, hasDivider: true, header: 'User Profiles Setup', displayinMenu: false, displayinDrawer: true, homePageIcon: <ManageAccounts style={{ fontSize: 60 }}></ManageAccounts>, drawerIcon: <ManageAccounts fontSize="large" /> },
    { index: 10, isSetupMenu: true, pathName: RoutePathNames.ENTITIES, header: 'Entities Setup', displayinMenu: true, displayinDrawer: true, homePageIcon: <AccountTree style={{ fontSize: 60 }}></AccountTree>, drawerIcon: <AccountTree fontSize="large" /> },
    { index: 11, isSetupMenu: true, pathName: RoutePathNames.CATEGORIES, header: 'Categories Setup', displayinMenu: true, displayinDrawer: true, homePageIcon: <Category style={{ fontSize: 60 }}></Category>, drawerIcon: <Category fontSize="large" /> },
    { index: 12, isSetupMenu: true, pathName: RoutePathNames.BUSINESS_OBJECTIVES, header: 'Objectives Setup', displayinMenu: true, displayinDrawer: true, homePageIcon: <BusinessCenter style={{ fontSize: 60 }}></BusinessCenter>, drawerIcon: <BusinessCenter fontSize="large" /> },
    { index: 13, isSetupMenu: true, pathName: RoutePathNames.IMPACTAREAS, header: 'Impact Areas Setup', displayinMenu: true, displayinDrawer: true, homePageIcon: <Gavel style={{ fontSize: 60 }}></Gavel>, drawerIcon: <Gavel fontSize="large" /> },
    { index: 14, isSetupMenu: true, pathName: RoutePathNames.RATINGS, header: 'Risk Ratings Setup', displayinMenu: true, displayinDrawer: true, homePageIcon: <StarHalf style={{ fontSize: 60 }}></StarHalf>, drawerIcon: <StarHalf fontSize="large" /> },
    { index: 15, isSetupMenu: true, pathName: RoutePathNames.EMAIL_TEMPLATES, header: 'Email Templates Setup', displayinMenu: true, displayinDrawer: true, homePageIcon: <Drafts style={{ fontSize: 60 }}></Drafts>, drawerIcon: <Drafts fontSize="large" /> },
]

export enum DisplayRefreshTrigger {
    ItemListFiltered = 1,
    ItemSelected = 2,
}

export enum RiskIdentificationDisplay {
    CREATE_RISK = 1,
    EDIT_RISK = 2,
    VIEW_RISK = 3,
    PREVIEW_RISK = 4,
}

export enum RiskAssessmentDisplay {
    CREATE_RISKASSESSMENT = 1,
    EDIT_RISKASSESSMENT = 2,
    VIEW_RISKASSESSMENT = 3,
    VIEW_RISKS_GRIDVIEW = 4,
    APPROVE_RISKASSESSMENT_VOTING = 5
}

export enum RiskTreatmentDisplay {
    VIEW_RISK = 1,
    VIEW_EDIT_ACTION_PLAN = 2,
}

export enum RiskGroupFilters {
    MY_RISKS = 1,
    ENTITY_RISKS = 2,
    GLOBAL_RISKS = 3,
    ENTITY_RISKS_NOT_SUBMITTED = 4
}

export enum RiskSortType {
    SortDateCreatedDesc = 1,
    SortDateCreatedAsc = 2,
    SortNameDesc = 3,
    SortNameAsc = 4
}

export enum RiskAssignmentDisplay {
    VIEW_RISK = 1,
    VIEW_RISK_PRIORITISATION = 2,
    ASSIGN_RISK = 3,
    ACCEPT_REJECT_CHAMPION = 4,
}

export enum RiskMonitoringDisplay {
    VIEW_RISK = 1,
    PREVIEW_RISK = 2,
    EDIT_RISK = 3
}

export enum RiskActionTimeScaleUnit {
    DAY = "Day",
    WEEK = "Week",
    MONTH = "Month",
}
