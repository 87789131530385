import { checkRiskDeleteable, checkRiskEditable, checkUserIsEntityCorrespondent } from "../../helpers/Utils";
import { RiskIdentificationDisplay, RiskStatus } from "../../helpers/Constants"
import { Button } from "@mui/material";
import { DeleteOutline, ModeEditOutline, Preview, Send, TableView } from "@mui/icons-material";
import { Risk, RiskAssessment, UserProfile } from "../../helpers/Interfaces";


interface Props {
    risk: Risk
    loggedOnUserProfile: UserProfile
    riskAssessmentForRiskIdentification: RiskAssessment | null
    riskIdentificationDisplay: RiskIdentificationDisplay
    onClickDeleteRisk: (riskId: number) => void
    onClickNotifyEntityHead: (riskId: number) => void
    onChangeRiskIdentificationDisplay: (riskAction: RiskIdentificationDisplay, risk?: Risk | null) => void
}

export function RiskIdentificationActionButtons({ risk, loggedOnUserProfile, onClickDeleteRisk, onClickNotifyEntityHead, onChangeRiskIdentificationDisplay, riskAssessmentForRiskIdentification, riskIdentificationDisplay }: Props) {

    return (
        <>
            {riskIdentificationDisplay !== RiskIdentificationDisplay.VIEW_RISK && <Button variant="contained" size="small" color="info" onClick={() => onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.VIEW_RISK, risk)} ><TableView />&nbsp;&nbsp;Risk Details </Button>}
            {riskIdentificationDisplay !== RiskIdentificationDisplay.PREVIEW_RISK && <Button variant="contained" size="small" color="info" onClick={() => onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.PREVIEW_RISK, risk)} ><Preview />&nbsp;&nbsp;Risk Card </Button>}&nbsp;&nbsp;
            <Button variant="contained" size="small" color="info" onClick={() => onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.EDIT_RISK, risk)} disabled={!checkRiskEditable(risk, riskAssessmentForRiskIdentification, loggedOnUserProfile)}><ModeEditOutline />&nbsp;&nbsp;Edit Risk </Button>&nbsp;&nbsp;
            <Button variant="contained" size="small" color="info" onClick={() => onClickDeleteRisk(risk.id)} disabled={!checkRiskDeleteable(risk, riskAssessmentForRiskIdentification, loggedOnUserProfile)}><DeleteOutline />&nbsp;&nbsp;Delete Risk </Button>&nbsp;&nbsp;
            <Button variant="contained" size="small" color="info" onClick={() => onClickNotifyEntityHead(risk.id)} disabled={!risk.isEntityRisk || risk.status !== RiskStatus.IDENTIFIED || !checkUserIsEntityCorrespondent(risk.entitiesImpacted, loggedOnUserProfile.email)}><Send />&nbsp;&nbsp;Notify Entity Head </Button>
        </>
    )
}
