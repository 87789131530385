import { useEffect, useState } from "react";
import { EmailTemplate } from "../helpers/Interfaces";
import { HorizontalSplitPane } from "../layouts/HorizontalSplitPane";
import { getAllEmailTemplates } from "../api-services/EmailTemplateApi";
import { EmailTemplateWidget } from "../components/email-templates/EmailTemplateWidget";
import { EditEmailTemplate } from "../components/email-templates/EditEmailTemplate";

export function ManageEmailTemplates() {
    // states
    const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([])
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplate | null>(null)

    useEffect(() => {
        refreshEmailTemplates()
    }, [])

    function refreshEmailTemplates() {
        getAllEmailTemplates().then(emailTemplates => {
            setEmailTemplates(emailTemplates)
            if (emailTemplates.length > 0) setSelectedEmailTemplate(emailTemplates[0])
        })
    }

    function handleEmailTemplateChange(templateType: number, emailTemplate: EmailTemplate) {
        setEmailTemplates(emailTemplates => emailTemplates.map(inst => inst.templateType === templateType ? emailTemplate : inst))
        setSelectedEmailTemplate(emailTemplate)
    }

    return (
        <HorizontalSplitPane leftWidth={299} rightWidthDynamic
            left={
                <EmailTemplateWidget emailTemplates={emailTemplates} selectedEmailTemplate={selectedEmailTemplate}
                    onChangeSelectedEmailTemplate={emailTemplate => setSelectedEmailTemplate(emailTemplate)} onClickRefreshEmailTemplates={() => refreshEmailTemplates()}
                />}
            right={selectedEmailTemplate &&
                <EditEmailTemplate emailTemplateToEdit={selectedEmailTemplate} onEmailTemplateChange={handleEmailTemplateChange} />
            }
        />
    )
}
