import { StickyNote2, Send, GridView, HowToVote, ThumbUp } from "@mui/icons-material"
import { Paper, Box, Typography, Grid, Button } from "@mui/material"
import { FormEditMode, RiskAssessmentDisplay, RiskAssessmentStatus, AssessmentTypes } from "../../helpers/Constants"
import { RiskAssessment } from "../../helpers/Interfaces"
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent"
import { useAppSelector } from "../../store/store"
import { AggregateRiskAssessment } from "./AggregateRiskAssessment"
import { ApproveRiskAssessment } from "./ApproveRiskAssessment"
import { CreateEditRiskAssessment } from "./CreateEditRiskAssessment"
import { ViewRiskAssessment } from "./ViewRiskAssessment"



interface Props {
    selectedRiskAssessment: RiskAssessment | null | undefined
    riskAssessmentDisplay: RiskAssessmentDisplay
    onChangeRiskAssessmentDisplay: (display: RiskAssessmentDisplay, riskAssessment?: RiskAssessment | null) => void
    onRiskAssessmentChange: (id: number, riskAssessment: RiskAssessment, formEditMode: FormEditMode, newRiskAssessmentAction: RiskAssessmentDisplay) => void
    onClickCommenceIdentification: (riskAssessment: RiskAssessment) => void
    onClickCommenceAggregation: (riskAssessment: RiskAssessment) => void
    onClickCommenceVoting: (riskAssessment: RiskAssessment) => void
    onClickCommenceApproval: (riskAssessment: RiskAssessment) => void
    onClickNotifyIdentifcationResponders: (riskAssessment: RiskAssessment) => void
    onClickNotifyVotingResponders: (riskAssessment: RiskAssessment) => void
    onClickLoadRisksForDrill: (riskAssessment: RiskAssessment) => void
}

export function RiskAssessmentDetails(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);


    return (
        loggedOnUserProfile &&
        <>

            {
                props.riskAssessmentDisplay === RiskAssessmentDisplay.CREATE_RISKASSESSMENT &&
                <CreateEditRiskAssessment formEditMode={FormEditMode.CREATE} onRiskAssessmentChange={props.onRiskAssessmentChange} />
            }

            {
                props.riskAssessmentDisplay === RiskAssessmentDisplay.EDIT_RISKASSESSMENT && props.selectedRiskAssessment &&
                <CreateEditRiskAssessment formEditMode={FormEditMode.EDIT} riskAssessment={props.selectedRiskAssessment} onRiskAssessmentChange={props.onRiskAssessmentChange} />
            }

            {
                props.riskAssessmentDisplay === RiskAssessmentDisplay.VIEW_RISKASSESSMENT && props.selectedRiskAssessment &&
                <Paper className="Form" style={{ width: '100%' }}>
                    <Box className="FormHeader"><Typography className="FormHeaderOrFooterDetails">Risk Assessment Details</Typography></Box>
                    <VerticalScrollableComponent viewPortHeightOffset={200}>
                        <ViewRiskAssessment riskAssessment={props.selectedRiskAssessment} />
                    </VerticalScrollableComponent>
                    <Box className="FormFooter">
                        <Grid item xs={12} className="FormHeaderOrFooterDetails">
                            {props.selectedRiskAssessment.status === RiskAssessmentStatus.NEW && props.selectedRiskAssessment.assessmentType !== AssessmentTypes.DRILL && <Button onClick={() => props.onClickCommenceIdentification(props.selectedRiskAssessment!)} variant="contained" size="small" color="secondary" title="Commence Risk Assessment Identification Process"><StickyNote2 /> &nbsp; Commence Identification </Button>}
                            {props.selectedRiskAssessment.status === RiskAssessmentStatus.NEW && props.selectedRiskAssessment.assessmentType === AssessmentTypes.DRILL && <Button onClick={() => props.onClickLoadRisksForDrill(props.selectedRiskAssessment!)} variant="contained" size="small" color="info" title="Load Risks based on Drill filters into Risk Drill Assessment"><StickyNote2 /> &nbsp; Load Risks using Drill filters </Button>}
                            {(props.selectedRiskAssessment.status === RiskAssessmentStatus.IDENTIFICATION || (props.selectedRiskAssessment.status === RiskAssessmentStatus.NEW && props.selectedRiskAssessment.assessmentType === AssessmentTypes.DRILL)) &&
                                <>
                                    {props.selectedRiskAssessment.assessmentType !== AssessmentTypes.DRILL && props.selectedRiskAssessment.notifyIdentificationRespondersByEmail && <Button onClick={() => props.onClickNotifyIdentifcationResponders(props.selectedRiskAssessment!)} variant="contained" size="small" color="info"  ><Send /> &nbsp; Re-Send Identification Notification </Button>}
                                    &nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={() => props.onClickCommenceAggregation(props.selectedRiskAssessment!)} variant="contained" size="small" color="secondary" title="Commence Risk Assessment Aggregation Process" ><GridView /> &nbsp; Commence Aggregation </Button>
                                </>
                            }
                            {props.selectedRiskAssessment.status === RiskAssessmentStatus.AGGREGATION && <Button onClick={() => props.onClickCommenceVoting(props.selectedRiskAssessment!)} variant="contained" size="small" color="secondary" title="Commence Risk Assessment Voting Process" >< HowToVote />  &nbsp; Commence Voting </Button>}
                            {props.selectedRiskAssessment.status === RiskAssessmentStatus.VOTING &&
                                <>
                                    {props.selectedRiskAssessment.notifyVotingRespondersByEmail && <Button onClick={() => props.onClickNotifyVotingResponders(props.selectedRiskAssessment!)} variant="contained" size="small" color="info"  ><Send /> &nbsp; Re-Send Voting Notification </Button>}
                                    &nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={() => props.onClickCommenceApproval(props.selectedRiskAssessment!)} variant="contained" size="small" color="secondary" title="Commence Risk Assessment Approval Process" ><ThumbUp /> &nbsp; Commence Approval </Button>
                                </>
                            }
                        </Grid>
                    </Box>
                </Paper >
            }

            {
                props.riskAssessmentDisplay === RiskAssessmentDisplay.VIEW_RISKS_GRIDVIEW && props.selectedRiskAssessment &&
                <AggregateRiskAssessment riskAssessment={props.selectedRiskAssessment} onRiskAssessmentChange={props.onRiskAssessmentChange} />
            }

            {
                props.riskAssessmentDisplay === RiskAssessmentDisplay.APPROVE_RISKASSESSMENT_VOTING && props.selectedRiskAssessment &&
                <ApproveRiskAssessment riskAssessment={props.selectedRiskAssessment} onRiskAssessmentChange={props.onRiskAssessmentChange} />
            }
        </>
    )
}