import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, BusinessObjective } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllBusinessObjectives = async () => {
    return await new Promise<BusinessObjective[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Business Objectives"));
        callServerAPI<ServerAPIResponse<BusinessObjective[]>>('GET', '/api/BusinessObjective/GetAllBusinessObjectives', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function deleteBusinessObjective(id: number) {
    return await new Promise<boolean>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Delete Business Objective"));
        await callServerAPI<ServerAPIResponse<boolean>>('DELETE', '/api/BusinessObjective/DeleteBusinessObjective?id=' + id, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response.data.data ? "Business Objective Disabled" : "Business Objective Deleted", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function createBusinessObjective(businessObjective: BusinessObjective) {
    return await new Promise<BusinessObjective>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new Business Objective"));
        callServerAPI<ServerAPIResponse<BusinessObjective>>('POST', '/api/BusinessObjective/CreateBusinessObjective', businessObjective, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Business Objective Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function updateBusinessObjective(businessObjective: BusinessObjective) {
    return await new Promise<BusinessObjective>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Business Objective"));
        callServerAPI<ServerAPIResponse<BusinessObjective>>('PATCH', '/api/BusinessObjective/UpdateBusinessObjective?id=' + businessObjective.id, businessObjective, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Business Objective Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}
