import React, { useState } from "react";
import { RoutePathNames } from "../helpers/Constants";
import { Typography, Toolbar, AppBar, IconButton, Menu, MenuItem, Divider, ListItemIcon, } from "@mui/material";
import { AccountCircle, Logout, Menu as MenuIcon, Home, Quiz } from "@mui/icons-material";
import '../App.css'
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/store";
import { callServerTepngUserLogout } from "../helpers/Utils";

interface AppBarHeaderProps {
    onToggleDrawer: () => void
    header: string
    drawerHidden: boolean
    appBarHeight: number
}

export function AppBarHeader({ header, appBarHeight, drawerHidden, onToggleDrawer }: AppBarHeaderProps) {
    const navigate = useNavigate()
    const userLoggedOn = useAppSelector(state => state.loggedOnUserProfile.value);
    const appConfig = useAppSelector(state => state.appConfig.value);
    const appBar: React.CSSProperties = {
        height: appBarHeight,
        zIndex: 1300,
        top: 0,
        backgroundColor: "#FFFFFF",
        border: 1,
        borderColor: "#000000"
    }


    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<Element | null>(null);
    const [showUserGuideMenu, setShowUserGuideMenu] = useState(false);
    const [userGuideMenuAnchorEl, setUserGuideMenuAnchorEl] = useState<Element | null>(null);


    const logout = async () => {
        setShowProfileMenu(false)
        await callServerTepngUserLogout()
    }

    const displayUserProfile = () => {
        setShowProfileMenu(false)
    }

    return (
        <AppBar style={appBar}>
            <Toolbar classes={{ regular: "ToolbarRegular" }}>
                {drawerHidden !== true &&
                    <>
                        <IconButton color="secondary" onClick={() => onToggleDrawer()} edge="start" > <MenuIcon /></IconButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IconButton color="secondary" onClick={() => navigate("/" + RoutePathNames.HOMEPAGE)} edge="start" > <Home /></IconButton>&nbsp;&nbsp;
                    </>
                }
                <img style={{ height: '30px' }} src='assets/images/logo.jpeg' alt="logo.jpeg" />
                <div style={{ flexGrow: 1 }} />
                <Typography variant="h6" color="secondary" fontWeight="bold" > {header}   </Typography>
                <div style={{ flexGrow: 1 }} />
                {/* <Badge badgeContent={0} color="secondary" max={9}  >
                        <NotificationsOutlined color="secondary" />
                    </Badge>&nbsp;&nbsp;&nbsp;&nbsp; */}

                <Typography variant="subtitle2" color="textPrimary"> {userLoggedOn == null ? 'No User logged in' : `Welcome ${userLoggedOn.fullName}`}</Typography>
                {userLoggedOn &&
                    <>
                        <IconButton color="secondary" edge="end" title={userLoggedOn.email} onClick={(event) => { setProfileMenuAnchorEl(event.currentTarget); setShowProfileMenu(previous => !previous) }}> <AccountCircle fontSize="large" /></IconButton> & nbsp;
                        <Menu style={{ zIndex: 1400 }} anchorEl={profileMenuAnchorEl} open={showProfileMenu} onClose={() => { setShowProfileMenu(false) }}>
                            <MenuItem onClick={displayUserProfile}><ListItemIcon><AccountCircle /></ListItemIcon>Profile</MenuItem>
                            <Divider />
                            <MenuItem onClick={() => logout()}><ListItemIcon><Logout /></ListItemIcon>Logout</MenuItem>
                        </Menu>
                    </>
                }
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Typography variant="subtitle2" color="textPrimary">User Guides</Typography>
                <IconButton size="small" color="secondary" edge="start" title="User Guides" onClick={(event) => { setUserGuideMenuAnchorEl(event.currentTarget); setShowUserGuideMenu(previous => !previous) }}> <Quiz fontSize="large" /></IconButton>&nbsp;
                <Menu anchorEl={userGuideMenuAnchorEl} open={showUserGuideMenu} onClose={() => setShowUserGuideMenu(false)}>
                    <MenuItem onClick={() => { window.open(appConfig.USERGUIDE_RISKIDENTIFICATION + '?chromeless=true', '_blank')?.focus(); }}><ListItemIcon><Quiz /></ListItemIcon>Guide to Risk Identification</MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { window.open(appConfig.USERGUIDE_RISKIDENTIFICATIONASSESSMENT + '?chromeless=true', '_blank')?.focus(); }}><ListItemIcon><Quiz /></ListItemIcon>Guide to Risk Identification in a Risk Assessment</MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { window.open(appConfig.USERGUIDE_ENTITYRISKIDENTIFICATION + '?chromeless=true', '_blank')?.focus(); }}><ListItemIcon><Quiz /></ListItemIcon>Guide to Entity Risk Identification</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}
