import { Button, Grid, Typography } from "@mui/material"
import { RiskAssessment } from "../../helpers/Interfaces"
import { useAppSelector } from "../../store/store";
import { formatLongDateString, formatTimeString } from "../../helpers/Utils";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";

interface Props {
    riskAssessment: RiskAssessment
    onClickBeginVoting: () => void
}

export function VotingInstructionView({ riskAssessment, onClickBeginVoting }: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);

    return (
        <>
            {loggedOnUserProfile && riskAssessment.votingEndDate &&
                <>
                    <VerticalScrollableComponent viewPortHeightOffset={181}>
                        <Grid id="VoteRiskContainer" container spacing={2} className="FormDetails" >
                            <Typography paddingTop={5} paddingBottom={5} paddingLeft={5} align="left" width="100%" fontSize={16}>
                                Dear {loggedOnUserProfile.fullName},
                            </Typography>
                            <Typography paddingBottom={3} paddingLeft={5} align="left" width="100%" fontSize={16}>
                                Thank you very much for your active participation and valuable contributions during the Risk Identification phase for the {riskAssessment.assessmentName} Exercise.
                            </Typography>
                            <Typography paddingBottom={3} paddingLeft={5} align="left" width="100%" fontSize={16}>
                                Following the receipt of {riskAssessment.numberOfRisksIdentified} individual risks identified in the 1st phase, the Enterprise Risk Management team have carefully evaluated and assessed these risks, and have aggregated them based on their characteristics, nature and similarities of the risks identified to {riskAssessment.risks.length} distinct risks.
                            </Typography>
                            <Typography paddingBottom={3} paddingLeft={5} align="left" width="100%" fontSize={16}>
                                In this next phase, you are required to review the details of the {riskAssessment.risks.length} distinct risks (Impact, Likelihood and existing mitigations) including the ratings for each of these parameters provided by the identifier(s).
                            </Typography>
                            <Typography paddingBottom={3} paddingLeft={5} align="left" width="100%" fontSize={16}>
                                You are then required to vote/rate each of these risks based on these parameters, either agreeing with the ratings provided by the risk identifier(s), or providing your own rating for each of the distinct risks based on your perception and knowledge of this risk.
                            </Typography>
                            <Typography paddingBottom={5} paddingLeft={5} align="center" color="red" width="100%" fontSize={16}>
                                This Risk Rating/Voting exercise will close on {formatLongDateString(riskAssessment.votingEndDate)} at {formatTimeString(riskAssessment.votingEndDate)}
                            </Typography>
                            <Typography paddingBottom={5} paddingLeft={5} align="center" color="textSecondary" width="100%" fontSize={16}>
                                <Button style={{ fontSize: 16 }} variant="text" color="info" size="large" onClick={() => onClickBeginVoting()}> Click here to begin voting</Button>
                            </Typography>
                        </Grid>

                    </VerticalScrollableComponent>
                    <br />
                </>
            }
        </>
    )
}