import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { Stack, Divider, Fab } from "@mui/material"
import { useEffect, useState } from "react"

interface Props {
    left: React.ReactNode
    right: React.ReactNode
    leftWidth?: number | undefined
    rightWidth?: number | undefined
    rightWidthDynamic?: boolean
    leftWidthDynamic?: boolean
    hideLeft?: boolean
    hideRight?: boolean
}

export function HorizontalSplitPane({ left, right, leftWidth, rightWidth, leftWidthDynamic = false, rightWidthDynamic = false, hideLeft = false, hideRight = false }: Props) {
    const [showLeft, setShowLeft] = useState(!hideLeft)
    const [showRight, setShowRight] = useState(!hideRight)
    const [fabOpacity, setFabOpacity] = useState(35)

    // #region effects

    useEffect(() => {
        setShowLeft(!hideLeft);
        setShowRight(!hideRight);
    }, [hideLeft, hideRight])

    // #endregion effects

    return (
        <>
            <Stack direction="row" spacing={0} style={{ width: '100%', }} divider={
                <Divider orientation="vertical" flexItem classes={{ wrapper: "PaneDividerWrapper" }} style={{ paddingLeft: 5, paddingRight: 5 }}>
                    <Stack direction="column" spacing={0}>
                        {showLeft && showRight && !leftWidthDynamic &&
                            <Fab onMouseOver={() => setFabOpacity(100)} onMouseLeave={() => setFabOpacity(35)} size="small" color="default" style={{ opacity: fabOpacity + '%', position: 'absolute', marginLeft: -16, marginTop: -24, height: 20, width: 35 }} onClick={() => setShowLeft(false)} > <KeyboardArrowLeft /> </Fab>}
                        {showLeft && showRight && !rightWidthDynamic &&
                            <Fab onMouseOver={() => setFabOpacity(100)} onMouseLeave={() => setFabOpacity(35)} size="small" color="default" style={{ opacity: fabOpacity + '%', position: 'absolute', marginLeft: -16, marginTop: 24, height: 20, width: 35 }} onClick={() => setShowRight(false)} > <KeyboardArrowRight /> </Fab>}
                        {showLeft && !showRight &&
                            <Fab onMouseOver={() => setFabOpacity(100)} onMouseLeave={() => setFabOpacity(35)} size="small" color="default" style={{ opacity: fabOpacity + '%', position: 'absolute', marginLeft: -24, height: 20, width: 35 }} onClick={() => setShowRight(true)} > <KeyboardArrowLeft /></Fab>}
                        {!showLeft && showRight &&
                            <Fab onMouseOver={() => setFabOpacity(100)} onMouseLeave={() => setFabOpacity(35)} size="small" color="default" style={{ opacity: fabOpacity + '%', position: 'absolute', marginLeft: -16, height: 20, width: 35 }} onClick={() => setShowLeft(true)} > <KeyboardArrowRight /></Fab>}
                    </Stack>
                </Divider>}
            >
                <div style={{ overflowX: 'hidden', padding: 0, margin: 0, width: showRight ? (leftWidth ?? `calc(100% - ${rightWidth}px)`) : '100%' }} hidden={!showLeft}>{left}</div>
                <div style={{ overflowX: 'hidden', padding: 0, margin: 0, width: showLeft ? (rightWidth ?? `calc(100% - ${leftWidth}px)`) : '100%' }} hidden={!showRight}>{right}</div>
            </Stack>
        </>
    )
}