import { Refresh, ArrowRight } from "@mui/icons-material";
import { Paper, Table, TableBody, TableRow, TableCell, ButtonGroup, Button, List, ListItem, ListItemText } from "@mui/material";
import { truncateTextToWhiteSpace } from "../../helpers/Utils";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { EmailTemplate } from "../../helpers/Interfaces";


interface Props {
    emailTemplates: EmailTemplate[]
    selectedEmailTemplate: EmailTemplate | null | undefined
    onClickRefreshEmailTemplates: () => void
    onChangeSelectedEmailTemplate: (emailTemplate: EmailTemplate) => void
}

export function EmailTemplateWidget(props: Props) {
    return (
        <Paper className="Widget">
            <Table style={{ width: '288px' }}>
                <TableBody>
                    <TableRow >
                        <TableCell align='center' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 10 }}>
                            <ButtonGroup size="small" variant="text" color="primary"  >
                                <Button onClick={props.onClickRefreshEmailTemplates} title="Refresh Risks"><Refresh /> </Button>
                            </ButtonGroup>
                        </TableCell >
                    </TableRow>
                    <TableRow >
                        <TableCell style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
                            <VerticalScrollableComponent viewPortHeightOffset={201}>
                                <List style={{ padding: 0 }}>
                                    {props.emailTemplates.map(emailTemplate =>
                                        <ListItem divider={false} button key={emailTemplate.templateType} classes={{ selected: "SelectedMenu" }} selected={props.selectedEmailTemplate?.templateType === emailTemplate.templateType} >
                                            <ArrowRight fontSize="medium" color={props.selectedEmailTemplate?.templateType === emailTemplate.templateType ? "secondary" : "primary"} />
                                            <ListItemText style={{ width: '100%' }} onClick={() => props.onChangeSelectedEmailTemplate(emailTemplate)} primary={truncateTextToWhiteSpace(emailTemplate.name, 50)} title={emailTemplate.name} />
                                        </ListItem>
                                    )}
                                </List>
                            </VerticalScrollableComponent>
                        </TableCell >
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    )
}
