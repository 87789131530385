import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, Entity } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllEntities = async () => {
    return await new Promise<Entity[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Entities"));
        callServerAPI<ServerAPIResponse<Entity[]>>('GET', '/api/Entity/GetAllEntities', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function deleteEntity(id: number) {
    return await new Promise<boolean>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Delete Entity"));
        await callServerAPI<ServerAPIResponse<boolean>>('DELETE', '/api/Entity/DeleteEntity?id=' + id, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response.data.data ? "Entity Disabled" : "Entity Deleted", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function createEntity(entity: Entity) {
    return await new Promise<Entity>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new Entity"));
        callServerAPI<ServerAPIResponse<Entity>>('POST', '/api/Entity/CreateEntity', entity, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Entity Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function updateEntity(entity: Entity) {
    return await new Promise<Entity>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Entity"));
        callServerAPI<ServerAPIResponse<Entity>>('PATCH', '/api/Entity/UpdateEntity?id=' + entity.id, entity, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Entity Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}
