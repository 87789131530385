import { Save } from "@mui/icons-material";
import { Paper, Box, Typography, Grid, Divider, TextField, Button } from "@mui/material";
import { formatDateyyyymmdd, getRiskActionStatusString, getUserFullName } from "../../helpers/Utils";
import { useAppSelector } from "../../store/store";
import { Risk, RiskAction } from "../../helpers/Interfaces";
import { useState, useEffect } from "react";
import { updateRiskActionImplementation } from "../../api-services/RiskApi";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";


interface Props {
    risk: Risk
    riskActionToEdit: RiskAction
    onRiskActionChange: (id: number, risk: Risk, actionId: number) => void
}


export function UpdateRiskActionPlanImplementation(props: Props) {
    // state independent variables
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    //states
    const [riskAction, setRiskAction] = useState<RiskAction>(props.riskActionToEdit)


    // #region effects

    useEffect(() => {
        setRiskAction(props.riskActionToEdit);
    }, [props.riskActionToEdit])

    // #endregion effects

    function handleUpdateActionImplementation() {
        const actionId = riskAction.id
        updateRiskActionImplementation(props.risk, riskAction).then(result => {
            props.onRiskActionChange(result.id, result, actionId);
        })
    }

    return (
        <Paper className="Form">
            <Box className="FormHeader"><Typography className="FormHeaderOrFooterDetails">  {'Update Implementation Details for Action: ' + riskAction.name} </Typography></Box>
            <VerticalScrollableComponent viewPortHeightOffset={231}>
                <Grid id="RiskActionGridViewContainer" spacing={3} container style={{ padding: 10 }} >
                    <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" >Action Name </Typography>
                        <Divider />
                        <Typography color="textPrimary" >{riskAction.name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <Typography color="textSecondary" >Timescale</Typography>
                        <Divider />
                        <Typography color="textPrimary" > {riskAction.timeScale} &nbsp;{riskAction.timeScale > 1 ? riskAction.timeScaleUnit + 's' : riskAction.timeScaleUnit}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <Typography color="textSecondary" >Status</Typography>
                        <Divider />
                        <Typography color="textPrimary" >{getRiskActionStatusString(riskAction.status, props.risk)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Details of Mitigation/Control Implemented</Typography>
                        <TextField fullWidth required name="mitigationImplemented" type="text" variant="outlined" multiline size="small" minRows={3}
                            value={riskAction.mitigationImplemented ?? ''} onChange={(event) => setRiskAction(riskAction => ({ ...riskAction, mitigationImplemented: event.target.value }))} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Date Implemented</Typography>
                        <Divider />
                        <TextField fullWidth required type="datetime-local" variant="outlined" size="small"
                            value={formatDateyyyymmdd(riskAction.implementationDate) ?? ''} onChange={(event) => setRiskAction(riskAction => ({ ...riskAction, implementationDate: event.target.value === '' ? null : new Date(event.target.value) }))} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" >Action Description</Typography>
                        <Divider />
                        <Typography color="textPrimary" >{riskAction.description}</Typography>
                    </Grid>
                    {riskAction.otherEntityResponsible &&
                        <Grid item xs={12} sm={6}>
                            <Typography color="textSecondary" > Entity Responsible </Typography>
                            <Divider />
                            <Typography color="textPrimary" >{riskAction.otherEntityResponsible?.name}</Typography>
                        </Grid>}
                    {riskAction.otherEntityContact &&
                        <Grid item xs={12} sm={6}>
                            <Typography color="textSecondary" > Entity Contact </Typography>
                            <Divider />
                            <Typography color="textPrimary" title={riskAction.otherEntityContact}>{getUserFullName(riskAction.otherEntityContact, riskRelatedTables.cmdbUsers)}</Typography>
                        </Grid>}
                    {riskAction.otherEntityContactedAndPlanAgreed &&
                        <Grid item xs={12}>
                            <Typography color="textSecondary" > Action Responsibility Declaration</Typography>
                            <Divider />
                            <Typography color="textPrimary" > Risk Champion confirms that the Entity has been contacted and has accepted responsibility for the action</Typography>
                        </Grid>}
                    {props.risk.existingMitigations &&
                        <Grid item xs={12}>
                            <Typography color="textSecondary" > Existing Mitigations</Typography>
                            <Divider />
                            <Typography color="textPrimary" > {props.risk.existingMitigations}</Typography>
                        </Grid>}
                    {props.risk.suggestedMitigations &&
                        <Grid item xs={12}>
                            <Typography color="textSecondary" > Suggested Mitigations</Typography>
                            <Divider />
                            <Typography color="textPrimary" > {props.risk.suggestedMitigations}</Typography>
                        </Grid>}
                </Grid>
            </VerticalScrollableComponent>
            <Box className="FormFooter">
                <Grid item xs={12} className="FormHeaderOrFooterDetails">
                    <Button disabled={!(riskAction.mitigationImplemented && riskAction.implementationDate)} variant="contained" size="small" color="secondary" onClick={() => handleUpdateActionImplementation()} ><Save /> &nbsp; Update Action Implementation </Button>
                </Grid>
            </Box>
        </Paper>
    )
}