import { getRatingDescription, checkSubmitRiskValidation, checkUserIsEntityCorrespondent, getCorrespondentEntitiesForLoggedOnUser, checkUserIsEntityHead, getEntityHeadEntitiesForLoggedOnUser, showConfirmBox, showAlertError } from "../../helpers/Utils";
import { Button, Grid, Paper, TextField, Typography, Autocomplete, Box, RadioGroup, Radio, FormControlLabel, Divider, Stack, IconButton, Checkbox } from "@mui/material";
import { AddCircleOutline, Save, Send, SettingsBackupRestoreOutlined } from "@mui/icons-material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { RiskAssessmentStatus, FormEditMode, ImpactLikelihoodColor, RiskStatus, RiskIdentificationDisplay, SeverityColor } from "../../helpers/Constants";
import { ImpactArea, Risk, RiskAssessment, RiskImpactAreaRating } from "../../helpers/Interfaces";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../store/store";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { NoOngoingRiskIdentification } from "./NoOngoingRiskIdentification";
import { NotAuthorisedForRiskIdentification } from "./NotAuthorisedForRiskIdentification";
import { RiskAssessmentWelcomeMessage } from "./RiskAssessmentWelcomeMessage";
import { createAndSubmitRisk, createRisk, submitRisk, updateRisk } from "../../api-services/RiskApi";


interface Props {
    formEditMode: FormEditMode
    assessmentIdForRiskIdentification: number | null
    riskAssessmentForRiskIdentification: RiskAssessment | null
    displayRiskAssessmentWelcomeMessage: boolean
    riskToEdit: Risk | null
    existingRiskNamesForSuggestion: string[]
    onRiskChange: (id: number, risk: Risk, formEditMode: FormEditMode, display: RiskIdentificationDisplay) => void
    onClickBeginRiskAssessmentIdentification: () => void
}

enum RiskPostUpdateAction {
    Add = 1,
    View = 2,
}


export function CreateEditRisk(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const header = props.assessmentIdForRiskIdentification != null ? "Identifying Risk for " + (props.riskAssessmentForRiskIdentification?.assessmentName ?? "Non Existent Risk Assessemnt") : (props.formEditMode === FormEditMode.CREATE ? "Identifying New/Emerging Risk" : "Edit New/Emerging Risk Details")
    const emptyRisk: Risk = useMemo(() => ({
        id: 0, name: "", riskImpactAreaRatings: [], status: RiskStatus.IDENTIFIED, sourceAssessment: props.riskAssessmentForRiskIdentification, description: "", impact: 0, likelihood: 0, mitigation: 0, existingMitigations: "", suggestedMitigations: "", createdBy: loggedOnUserProfile?.email ?? "", isEntityRisk: false, createdDate: new Date(), submittedBy: null, submittedDate: null, aggregatedDate: null, aggregatedBy: null,
        category: null, businessObjectiveImpacted: null, entitiesImpacted: [], entityObjectivesImpacted: "", frequencyOfMention: 0, severity: 0, impactAverageVote: 0, likelihoodAverageVote: 0, mitigationAverageVote: 0, impactStandardDeviationVote: 0, likelihoodStandardDeviationVote: 0,
        mitigationStandardDeviationVote: 0, impactMinVote: 0, likelihoodMinVote: 0, mitigationMinVote: 0, impactMaxVote: 0, likelihoodMaxVote: 0, mitigationMaxVote: 0,
        prioritisation: 0, championEntity: null, championNominationRejectionComments: null, champion: null, alternateChampion: null, sponsor: null, targetMitigation: null, responseDate: null, approvedDate: null,
        approvedBy: null, prioritisedDate: null, prioritisedBy: null, assignedDate: null, assignedBy: null, championNominationAcceptedDate: null, championNominationAcceptedBy: null,
        championNominationRejectedDate: null, championNominationRejectedBy: null, actionPlanSubmittedDate: null, actionPlanSubmittedBy: null, actionPlanApprovedDate: null,
        actionPlanApprovedBy: null, actionPlanRejectedDate: null, actionPlanRejectedBy: null, riskActions: [],
        actionPlanImplementationSubmittedDate: null, actionPlanImplementationSubmittedBy: null, actionPlanImplementationApprovedDate: null, actionPlanImplementationApprovedBy: null,
        actionPlanImplementationRejectedDate: null, actionPlanImplementationRejectedBy: null,
        actionPlanImplementationRejectionComments: null, actionPlanImplementationTargetPrioritisation: null,
        validatedRiskResponseAction: null, actionPlanPercentCompletion: null, riskChanges: [], riskRatingChanges: [], riskVotes: [], approvalComments: "",
        flaggedAsExposedBy: null, flaggedAsExposedDate: null, flaggedAsUnexposedBy: null, flaggedAsUnexposedDate: null
    }), [props.riskAssessmentForRiskIdentification, loggedOnUserProfile]);
    //states
    const [risk, setRisk] = useState<Risk>(emptyRisk)
    //state dependent variables
    const isAnyEntityCorrespondent = loggedOnUserProfile == null ? false : checkUserIsEntityCorrespondent(riskRelatedTables.entities, loggedOnUserProfile.email)
    const isAnyEntityHead = loggedOnUserProfile == null ? false : checkUserIsEntityHead(riskRelatedTables.entities, loggedOnUserProfile.email)

    // #region effects

    useEffect(() => {
        if (props.formEditMode !== FormEditMode.CREATE && props.riskToEdit != null)
            setRisk(props.riskToEdit);
        else
            setRisk(emptyRisk)
    }, [props.riskToEdit, props.formEditMode, emptyRisk])


    useEffect(() => {//update Impact rating when any of the impact areas rating changes
        setRisk(risk => ({ ...risk, impact: Math.max(0, ...risk.riskImpactAreaRatings.map(e => e.rating)) }))
    }, [risk.riskImpactAreaRatings])


    useEffect(() => {//update entitiesImpacted isEntityRisk changes
        const activeEntities = riskRelatedTables.entities.filter(e => !e.disabled)
        if (risk.isEntityRisk) {
            let entities = getCorrespondentEntitiesForLoggedOnUser(activeEntities, loggedOnUserProfile!.email)
            if (entities.length === 0) entities = getEntityHeadEntitiesForLoggedOnUser(activeEntities, loggedOnUserProfile!.email)
            if (entities.length === 0)
                showAlertError("User is not an Entity Correspondent or Entity Head")
            else
                setRisk(risk => ({ ...risk, entitiesImpacted: [entities[0]] }))
        }
    }, [risk.isEntityRisk, loggedOnUserProfile, riskRelatedTables.entities])

    // #endregion effects

    function handleCreateRisk(dataPostUpdateAction: RiskPostUpdateAction) {
        createRisk(risk).then(result => {
            if (dataPostUpdateAction === RiskPostUpdateAction.Add)
                setRisk(emptyRisk)
            else
                setRisk(result);
            props.onRiskChange(result.id, result, FormEditMode.CREATE, dataPostUpdateAction === RiskPostUpdateAction.Add ? RiskIdentificationDisplay.CREATE_RISK : RiskIdentificationDisplay.VIEW_RISK);
            if (document.getElementById("CreateEditScrollPane") != null) document.getElementById("CreateEditScrollPane")?.scrollTo(0, 0);
        })
    }

    function handleUpdateRisk(dataPostUpdateAction: RiskPostUpdateAction) {
        updateRisk(risk).then(result => {
            if (dataPostUpdateAction === RiskPostUpdateAction.Add)
                setRisk(emptyRisk)
            else
                setRisk(result);
            props.onRiskChange(result.id, result, FormEditMode.EDIT, dataPostUpdateAction === RiskPostUpdateAction.Add ? RiskIdentificationDisplay.CREATE_RISK : RiskIdentificationDisplay.VIEW_RISK);
            if (document.getElementById("CreateEditScrollPane") != null) document.getElementById("CreateEditScrollPane")?.scrollTo(0, 0);
        })
    }

    function handleConfirmSubmitRisk() {
        showConfirmBox("You will not be able to make changes after submitting. Are you sure you want to Submit this Risk?", "Confirm Submit")
            .then((result) => {
                if (result.isConfirmed) {
                    if (props.formEditMode === FormEditMode.CREATE)
                        handleCreateAndSubmitRisk()
                    else
                        handleSubmitRisk()
                }
            })
    }

    function handleCreateAndSubmitRisk() {
        createAndSubmitRisk(risk).then(result => {
            setRisk(result);
            props.onRiskChange(result.id, result, FormEditMode.CREATE, RiskIdentificationDisplay.VIEW_RISK);
        })
    }

    function handleSubmitRisk() {
        updateRisk(risk).then(result => {
            submitRisk(risk).then(result => {
                setRisk(result);
                props.onRiskChange(result.id, result, FormEditMode.EDIT, RiskIdentificationDisplay.VIEW_RISK);
            })
        })
    }

    function handleImpactAreaRatingChange(impactArea: ImpactArea, rating: number) {
        const newRiskImpactArea: RiskImpactAreaRating = { id: 0, impactArea: impactArea, rating: Number(rating) }
        setRisk(risk => ({ ...risk, riskImpactAreaRatings: risk.riskImpactAreaRatings.filter(i => i.impactArea.id !== Number(impactArea.id)).concat(newRiskImpactArea) }))
    }

    function getViewToLoad() {
        if (props.assessmentIdForRiskIdentification != null && props.riskAssessmentForRiskIdentification == null)
            return <></>
        if (props.riskAssessmentForRiskIdentification != null && (props.riskAssessmentForRiskIdentification.status !== RiskAssessmentStatus.IDENTIFICATION || new Date(props.riskAssessmentForRiskIdentification.identificationEndDate!) < new Date()))
            return <NoOngoingRiskIdentification riskAssessmentForRiskIdentification={props.riskAssessmentForRiskIdentification} />
        if (props.riskAssessmentForRiskIdentification != null && props.riskAssessmentForRiskIdentification.identificationRespondersList.find(r => r.toUpperCase() === loggedOnUserProfile!.email.toUpperCase()) == null)
            return <NotAuthorisedForRiskIdentification riskAssessmentForRiskIdentification={props.riskAssessmentForRiskIdentification} />
        if (props.riskAssessmentForRiskIdentification != null && props.formEditMode === FormEditMode.CREATE && props.displayRiskAssessmentWelcomeMessage)
            return <RiskAssessmentWelcomeMessage riskAssessmentForRiskIdentification={props.riskAssessmentForRiskIdentification} loggedOnUserProfile={loggedOnUserProfile!} onClickBeginRiskAssessmentIdentification={props.onClickBeginRiskAssessmentIdentification} />
        return getCreateEditView()
    }

    function getCreateEditView() {
        return (
            <>
                <VerticalScrollableComponent viewPortHeightOffset={props.assessmentIdForRiskIdentification == null ? 231 : 231 - 31}>
                    <Grid id="CreateEditRiskFormDetails" container spacing={3} className="FormDetails">
                        {(isAnyEntityHead || isAnyEntityCorrespondent) && props.riskAssessmentForRiskIdentification == null &&
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox classes={{ checked: "CheckBoxChecked" }} name="isEntityRisk"
                                    checked={risk.isEntityRisk} onChange={(event, checked) => setRisk(risk => ({ ...risk, isEntityRisk: checked }))} />} label="I am identifying this Risk on behalf of my Entity" />
                            </Grid>}
                        <Grid item xs={12}>
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Risk Name </Typography>
                            <Autocomplete autoComplete fullWidth id="name" autoSelect freeSolo size="small" options={props.existingRiskNamesForSuggestion}
                                value={risk.name ?? ''} onChange={(event, value) => setRisk(risk => ({ ...risk, name: value ?? "" }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Description </Typography>
                            <TextField fullWidth required name="description" type="text" variant="outlined" multiline size="small" minRows={3}
                                value={risk.description ?? ''} onChange={(event) => setRisk(risk => ({ ...risk, description: event.target.value }))} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Strategic Objective Impacted </Typography>
                            <Divider />
                            <RadioGroup name="businessObjectiveImpacted" value={risk.businessObjectiveImpacted?.id ?? ''} onChange={(event, value) => setRisk(risk => ({ ...risk, businessObjectiveImpacted: riskRelatedTables.businessObjectives.find(e => e.id === Number(value)) }))}>
                                {riskRelatedTables.businessObjectives.filter(e => !e.disabled).map(row => <FormControlLabel style={{ color: "#000000" }} key={row.id} value={row.id} control={<Radio color="secondary" />} label={row.name} />)}
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Category (select one)</Typography>
                            <Divider />
                            <RadioGroup name="category" value={risk.category?.id ?? ''} onChange={(event, value) => setRisk(risk => ({ ...risk, category: riskRelatedTables.categories.find(e => e.id === Number(value)) }))}>
                                {riskRelatedTables.categories.filter(e => !e.disabled).map(row => <FormControlLabel style={{ padding: 5, color: "#000000" }} key={row.id} value={row.id} control={<Radio color="secondary" />} label={row.name + ' - ' + row.description} />)}
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Entities Impacted or Responsible </Typography>
                            <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape multiple={true} options={riskRelatedTables.entities.filter(e => !e.disabled)} getOptionLabel={(row) => row.name}
                                value={risk.entitiesImpacted} disabled={risk.isEntityRisk} isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(event, value) => setRisk(risk => ({ ...risk, entitiesImpacted: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" />)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" >Entity Objectives Impacted</Typography>
                            <Autocomplete fullWidth id="entityObjectiveImpacted" autoSelect freeSolo size="small" options={risk.entitiesImpacted.map(row => row.objectives ? row.objectives.split('\n') : []).flat()}
                                value={risk.entityObjectivesImpacted ?? ''} onChange={(event, value) => setRisk(risk => ({ ...risk, entityObjectiveImpacted: value ?? "" }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> {'Impact - Overall Impact rating: ' + risk.impact} </Typography>
                            <Divider />
                            <div style={{ overflowX: 'auto' }}>
                                <Table size="small" style={{ border: "solid 1px #EEEEEE" }} >
                                    <TableHead color="primary">
                                        <TableRow>
                                            <TableCell align="left" style={{ width: 100, backgroundColor: '#EEEEEE' }}>Impact Area</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>Disastrous</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>Catastrophic</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>Very Serious</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>Serious</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>Moderate</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>Minor</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>N/A</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {riskRelatedTables.impactAreas.filter(e => !e.disabled).map(row =>
                                            <TableRow key={row.id}>
                                                <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}><Typography>{row.name}</Typography></TableCell>
                                                <TableCell style={{ textAlign: 'left', verticalAlign: 'top' }}>{row.rating6Description && <Radio key={6} style={{ padding: 0, color: ImpactLikelihoodColor[6] }} name={row.id + ""} value='6' checked={risk.riskImpactAreaRatings.find(i => i.impactArea.id === row.id && i.rating === 6) !== undefined} onChange={() => handleImpactAreaRatingChange(row, 6)} />}  {row.rating6Description}  </TableCell>
                                                <TableCell style={{ textAlign: 'left', verticalAlign: 'top' }}>{row.rating5Description && <Radio key={5} style={{ padding: 0, color: ImpactLikelihoodColor[5] }} name={row.id + ""} value='5' checked={risk.riskImpactAreaRatings.find(i => i.impactArea.id === row.id && i.rating === 5) !== undefined} onChange={() => handleImpactAreaRatingChange(row, 5)} />}  {row.rating5Description}  </TableCell>
                                                <TableCell style={{ textAlign: 'left', verticalAlign: 'top' }}>{row.rating4Description && <Radio key={4} style={{ padding: 0, color: ImpactLikelihoodColor[4] }} name={row.id + ""} value='4' checked={risk.riskImpactAreaRatings.find(i => i.impactArea.id === row.id && i.rating === 4) !== undefined} onChange={() => handleImpactAreaRatingChange(row, 4)} />}  {row.rating4Description}  </TableCell>
                                                <TableCell style={{ textAlign: 'left', verticalAlign: 'top' }}>{row.rating3Description && <Radio key={3} style={{ padding: 0, color: ImpactLikelihoodColor[3] }} name={row.id + ""} value='3' checked={risk.riskImpactAreaRatings.find(i => i.impactArea.id === row.id && i.rating === 3) !== undefined} onChange={() => handleImpactAreaRatingChange(row, 3)} />}  {row.rating3Description}  </TableCell>
                                                <TableCell style={{ textAlign: 'left', verticalAlign: 'top' }}>{row.rating2Description && <Radio key={2} style={{ padding: 0, color: ImpactLikelihoodColor[2] }} name={row.id + ""} value='2' checked={risk.riskImpactAreaRatings.find(i => i.impactArea.id === row.id && i.rating === 2) !== undefined} onChange={() => handleImpactAreaRatingChange(row, 2)} />}  {row.rating2Description}  </TableCell>
                                                <TableCell style={{ textAlign: 'left', verticalAlign: 'top' }}>{row.rating1Description && <Radio key={1} style={{ padding: 0, color: ImpactLikelihoodColor[1] }} name={row.id + ""} value='1' checked={risk.riskImpactAreaRatings.find(i => i.impactArea.id === row.id && i.rating === 1) !== undefined} onChange={() => handleImpactAreaRatingChange(row, 1)} />}  {row.rating1Description}  </TableCell>
                                                <TableCell style={{ textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ padding: 0 }} color="info" name={row.id + ""} value='0' checked={risk.riskImpactAreaRatings.find(i => i.impactArea.id === row.id && i.rating > 0) === undefined} onChange={() => handleImpactAreaRatingChange(row, 0)} /></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </Grid >
                        <Grid item xs={12}>
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Likelihood </Typography>
                            <Divider />
                            <RadioGroup name="likelihood" value={risk.likelihood ?? ''} onChange={(event, value) => setRisk(risk => ({ ...risk, likelihood: Number(value) }))}>
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={6} value={6} control={<Radio color="secondary" />} label={"6 - " + getRatingDescription(6, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={5} value={5} control={<Radio color="secondary" />} label={"5 - " + getRatingDescription(5, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={4} value={4} control={<Radio color="secondary" />} label={"4 - " + getRatingDescription(4, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={3} value={3} control={<Radio color="secondary" />} label={"3 - " + getRatingDescription(3, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={2} value={2} control={<Radio color="secondary" />} label={"2 - " + getRatingDescription(2, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={1} value={1} control={<Radio color="secondary" />} label={"1 - " + getRatingDescription(1, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Mitigation </Typography>
                            <Divider />
                            <RadioGroup name="mitigation" value={risk.mitigation} onChange={(event, value) => setRisk(risk => ({ ...risk, mitigation: Number(value) }))}>
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={6} value={6} control={<Radio color="secondary" />} label={"6 - " + getRatingDescription(6, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={5} value={5} control={<Radio color="secondary" />} label={"5 - " + getRatingDescription(5, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={4} value={4} control={<Radio color="secondary" />} label={"4 - " + getRatingDescription(4, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={3} value={3} control={<Radio color="secondary" />} label={"3 - " + getRatingDescription(3, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={2} value={2} control={<Radio color="secondary" />} label={"2 - " + getRatingDescription(2, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                                <FormControlLabel style={{ padding: 3, color: "#000000" }} key={1} value={1} control={<Radio color="secondary" />} label={"1 - " + getRatingDescription(1, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            </RadioGroup>
                        </Grid>
                        {risk.severity > 0 && <Grid item xs={12}>
                            <Stack direction="column">
                                <Typography color="textSecondary" >Severity</Typography>
                                <Typography align="center" fontSize={16} style={{ padding: 5, width: '30px', color: '#FFFFFF', backgroundColor: SeverityColor[risk.severity] }}>{risk.severity}</Typography>
                            </Stack>
                        </Grid>}
                        <Grid item xs={12}>
                            <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>{risk.mitigation > 1 ? '*' : ''}</span> Existing Mitigations </Typography>
                            <TextField fullWidth required name="existingMitigations" type="text" variant="outlined" multiline size="small" minRows={3}
                                value={risk.existingMitigations ?? ''} onChange={(event) => setRisk(risk => ({ ...risk, existingMitigations: event.target.value }))} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" >Suggested Mitigations</Typography>
                            <TextField fullWidth required name="suggestedMitigations" type="text" variant="outlined" multiline size="small" minRows={3}
                                value={risk.suggestedMitigations ?? ''} onChange={(event) => setRisk(risk => ({ ...risk, suggestedMitigations: event.target.value }))} />
                        </Grid>
                    </Grid >
                </VerticalScrollableComponent>
                <Box className="FormFooter" >
                    <Grid item xs={4} className="FormHeaderOrFooterDetails">
                        <Button disabled={!risk.name || risk.entitiesImpacted.length === 0} variant="contained" size="small" color="info" onClick={() => props.formEditMode === FormEditMode.CREATE ? handleCreateRisk(RiskPostUpdateAction.Add) : handleUpdateRisk(RiskPostUpdateAction.Add)} ><Save /> &nbsp; <AddCircleOutline />&nbsp;Save &amp; Add New Risk </Button>
                    </Grid>
                    <Grid item xs={4} className="FormHeaderOrFooterDetails">
                        <Button disabled={!risk.name || risk.entitiesImpacted.length === 0} variant="contained" size="small" color="info" onClick={() => props.formEditMode === FormEditMode.CREATE ? handleCreateRisk(RiskPostUpdateAction.View) : handleUpdateRisk(RiskPostUpdateAction.View)} ><Save /> &nbsp; Save for Review </Button>
                    </Grid>
                    <Grid item xs={4} className="FormHeaderOrFooterDetails">
                        <Button disabled={!checkSubmitRiskValidation(risk, loggedOnUserProfile!.email)} variant="contained" size="small" color="secondary" onClick={() => handleConfirmSubmitRisk()} ><Send /> &nbsp; Submit </Button>
                    </Grid>
                </Box>
            </>
        )
    }

    return (
        loggedOnUserProfile &&
        <Paper className="Form" >
            <Box className={props.assessmentIdForRiskIdentification == null ? "FormHeader" : "BoldFormHeader"}>
                <Typography className={props.assessmentIdForRiskIdentification == null ? "FormHeaderOrFooterDetails" : "BoldFormHeaderDetails"}> {header}</Typography>
                <div style={{ flexGrow: 1 }} />
                <IconButton size="large" onClick={() => setRisk(emptyRisk)} style={{ color: props.assessmentIdForRiskIdentification == null ? '#cc0000' : 'white' }} title="Reset Form"> <SettingsBackupRestoreOutlined />  </IconButton>
            </Box>
            {getViewToLoad()}
        </Paper >
    )
}
