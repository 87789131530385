import { Paper, Grid, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { ReportPages, iconColorsArray } from "../helpers/Constants";
import { checkReportPageEnabled } from "../helpers/Utils";
import { useAppSelector } from "../store/store";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";


export function ViewReports() {

    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const appConfig = useAppSelector(state => state.appConfig.value);

    return (
        loggedOnUserProfile &&
        <Paper className="Form" >
            <br />
            <VerticalScrollableComponent viewPortHeightOffset={162}>
                <Grid container spacing={2} style={{ padding: 15 }}>
                    {ReportPages.sort((a, b) => a.index < b.index ? -1 : 1).map((a, index) => {
                        var enabled = checkReportPageEnabled(a, riskRelatedTables.entities, loggedOnUserProfile);
                        return (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3}   >
                                <Card style={{ cursor: enabled ? "pointer" : "not-allowed", borderRadius: 0 }} elevation={0} >
                                    <CardActionArea disabled={!enabled} className="HomePageCard" onClick={(event) => { window.open(appConfig.REPORTS_BASE_URL + "/" + a.powerBiReportSectionId + '?chromeless=true', '_blank')?.focus(); }} >
                                        <CardContent>
                                            <Typography align="center" style={{ color: enabled ? iconColorsArray[index] : 'grey' }}> {a.icon} </Typography>
                                            <Typography align="center" sx={{ fontSize: 16 }} color="textPrimary" > {a.header} </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </VerticalScrollableComponent>
            <br />
        </Paper >
    )
}
