import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile } from '../helpers/Interfaces';

interface UserProfileState {
  value: UserProfile | null
}

export const loggedOnUserProfile = createSlice({
  name: 'loggedOnUserProfile',
  initialState: {
    value: null,
  } as UserProfileState,
  reducers: {
    setLoggedOnUserProfile: (state, action: PayloadAction<UserProfile | null>) => {
      state.value = action.payload
    },
  },
})

export const { setLoggedOnUserProfile } = loggedOnUserProfile.actions;
export default loggedOnUserProfile.reducer;