import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { callServerAPI, callServerRefreshToken, loadAppConfigFromSettingsFile } from './helpers/Utils';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { RiskManRoutes } from './routes/RiskManRoutes';
import { useAppDispatch } from './store/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppTheme } from './layouts/theme';
import { setServerGlobalSettings } from './store/serverGlobalSettings';
import { ServerAPIResponse, ServerGlobalSettings } from './helpers/Interfaces';

const MySwal = withReactContent(Swal);

function App() {
  const theme = useMemo(() => AppTheme(), [])
  const dispatch = useAppDispatch();
  const [pageMounted, setPageMounted] = useState(false);
  const [appConfigLoaded, setAppConfigLoaded] = useState(false);


  const tryRefreshToken = useCallback(async () => {
    await callServerRefreshToken(false)
      .then(function (result) {
        MySwal.close();
        setPageMounted(true)
      })
  }, [])

  useEffect(() => {
    const getServerGlobalSettings = async () => {
      await callServerAPI<ServerAPIResponse<ServerGlobalSettings>>("GET", '/api/AppConfig', null, false)
        .then(response => {
          dispatch(setServerGlobalSettings(response.data?.data));
          MySwal.close();
          tryRefreshToken()
        })
    }
    if (appConfigLoaded) getServerGlobalSettings()
  }, [tryRefreshToken, dispatch, appConfigLoaded])

  useEffect(() => {
    const launchLoadAppConfig = async () => {
      await loadAppConfigFromSettingsFile()
      setAppConfigLoaded(true)
    }
    launchLoadAppConfig()
  }, [])



  return (
    <>
      {pageMounted &&
        <div className="app">
          <Router>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider>
                <RiskManRoutes />
              </SnackbarProvider>
            </ThemeProvider>
          </Router>
        </div>
      }
    </>
  );
}

export default App