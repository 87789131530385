import { Grid, Typography } from "@mui/material"
import { useAppSelector } from "../../store/store";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";


export function SubmittedVoteView() {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);

    return (
        loggedOnUserProfile &&
        <>
            <VerticalScrollableComponent viewPortHeightOffset={181}>
                <Grid id="VoteRiskContainer" container spacing={2} className="FormDetails" >
                    <Typography paddingTop={5} paddingBottom={5} align="center" width="100%" fontSize={16}> {loggedOnUserProfile.fullName + ', your votes have been submitted sucessfully'}</Typography>
                </Grid>
            </VerticalScrollableComponent>
            <br />
        </>
    )
}