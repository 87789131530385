import { showConfirmBox } from "../../helpers/Utils";
import { Button, Grid, Paper, Typography, Box, } from "@mui/material";
import { Assessment, Upload } from "@mui/icons-material";
import MaterialTable from '@material-table/core'
import { Risk, RiskAssessment } from "../../helpers/Interfaces";
import { FormEditMode, ReportPageIds, ReportPages, RiskAssessmentDisplay } from "../../helpers/Constants";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { approveRisks, submitConsensusRiskRatings } from "../../api-services/RiskAssessmentApi";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/store";


interface Props {
    riskAssessment: RiskAssessment
    onRiskAssessmentChange: (id: number, riskAssessment: RiskAssessment, formEditMode: FormEditMode, newRiskAssessmentAction: RiskAssessmentDisplay) => void
}

export function ApproveRiskAssessment({ riskAssessment, onRiskAssessmentChange }: Props) {
    const [risks, setRisks] = useState<Risk[]>([])
    const [pendingEdits, setPendingEdits] = useState(false)
    const appConfig = useAppSelector(state => state.appConfig.value);

    useEffect(() => {
        setRisks(riskAssessment.risks.map(risk => ({
            ...risk,
            impact: Math.round(risk.impactAverageVote ?? risk.impact),
            likelihood: Math.round(risk.likelihoodAverageVote ?? risk.likelihood),
            mitigation: Math.round(risk.mitigationAverageVote ?? risk.mitigation),
        })))
    }, [riskAssessment])


    function updateRecord(newData: Risk, oldData: Risk | undefined) {
        return new Promise<void>((resolve, reject) => {
            setRisks(risks => risks.map(inst => inst.id === newData.id ? newData : inst))
            setPendingEdits(true)
            resolve()
        })
    }

    function handleConfirmApproveRisks() {
        showConfirmBox("This will approve all the risks in this Risk Assessment. You would not be able to make any further changes to approved Risks.\n\nAre you sure you this is what you want to do?", "Confirm Approve Risk Assessment")
            .then((confirmResult) => {
                if (confirmResult.isConfirmed)
                    approveRisks(riskAssessment, risks.map(risk => ({ riskId: risk.id, impact: risk.impact, likelihood: risk.likelihood, mitigation: risk.mitigation, comments: risk.approvalComments }))).then(result => onRiskAssessmentChange(result.id, result, FormEditMode.EDIT, RiskAssessmentDisplay.APPROVE_RISKASSESSMENT_VOTING))
            })
    }

    function handleSubmitConsensusRiskRatings() {
        submitConsensusRiskRatings(riskAssessment, risks.map(risk => ({ riskId: risk.id, impact: risk.impact, likelihood: risk.likelihood, mitigation: risk.mitigation, comments: risk.approvalComments })))
        .then(result => {
            onRiskAssessmentChange(result.id, result, FormEditMode.EDIT, RiskAssessmentDisplay.APPROVE_RISKASSESSMENT_VOTING)
            setPendingEdits(false)
        })
    }

    return (
        <Box className='HorizontalOverFlowContainer'>
            <Paper className="Form">
                <Box className="FormHeader"><Typography className="FormHeaderOrFooterDetails"> Approving Risks for {riskAssessment.assessmentName}</Typography></Box>
                <VerticalScrollableComponent viewPortHeightOffset={200}>
                    <Grid item xs={12}>
                        <MaterialTable components={{ Container: props => <div {...props} style={{ width: '100%' }} elevation={0} />, }}
                            columns={[
                                { title: 'Id', field: 'id', type: 'numeric', align: 'left', editable: 'never', width: 50 },
                                { title: 'Name', field: 'name', editable: 'never', width: 200 },
                                { title: 'Impact Average', field: 'impactAverageVote', editable: 'never', type: 'numeric', align: 'left', width: 50, render: data => data.impactAverageVote?.toFixed(2) },
                                { title: 'Likelihood Average', field: 'likelihoodAverageVote', editable: 'never', type: 'numeric', align: 'left', width: 50, render: data => data.likelihoodAverageVote?.toFixed(2) },
                                { title: 'Mitigation Average', field: 'mitigationAverageVote', editable: 'never', type: 'numeric', align: 'left', width: 50, render: data => data.mitigationAverageVote?.toFixed(2) },
                                { title: 'Consensus Impact', field: 'impact', initialEditValue: 0, type: 'numeric', align: 'left', validate: rowData => rowData.impact < 1 || rowData.impact > 6 ? 'Invalid Impact Value' : true, width: 50 },
                                { title: 'Consensus Likelihood', field: 'likelihood', initialEditValue: 0, type: 'numeric', align: 'left', validate: rowData => rowData.likelihood < 1 || rowData.likelihood > 6 ? 'Invalid Likelihood Value' : true, width: 50 },
                                { title: 'Consensus Mitigation', field: 'mitigation', initialEditValue: 0, type: 'numeric', align: 'left', validate: rowData => rowData.mitigation < 1 || rowData.mitigation > 6 ? 'Invalid Mitigation Value' : true, width: 50 },
                                { title: 'Comments', field: 'approvalComments', type: 'string', align: 'left' },
                            ]}
                            data={risks}
                            editable={{
                                onRowAdd: undefined,
                                onRowUpdate: (newData, oldData) => updateRecord(newData, oldData),
                                onRowDelete: undefined,
                            }}

                            localization={{ header: { actions: '' } }}

                            options={{
                                pageSize: 30, pageSizeOptions: [5, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false,
                                rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" },
                                editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" },
                                actionsCellStyle: { color: '#0288D1' }, headerStyle: { color: '#0288D1' },
                            }}
                        />
                    </Grid>
                </VerticalScrollableComponent>
                <Box className="FormFooter">
                    <Grid item xs={4} className="FormHeaderOrFooterDetails">
                        <Button variant="contained" size="small" color="info" disabled={!pendingEdits} component="label" onClick={() => handleSubmitConsensusRiskRatings()}><Upload /> &nbsp;Submit Consensus Ratings </Button>
                    </Grid>
                    <Grid item xs={4} className="FormHeaderOrFooterDetails">
                        <Button variant="contained" size="small" color="info" component="label" onClick={(event) => { window.open(appConfig.REPORTS_BASE_URL + "/" + ReportPages.find(r => r.pageId === ReportPageIds.RISK_VOTING_BREAKDOWN)?.powerBiReportSectionId + '?chromeless=true', '_blank')?.focus(); }}><Assessment /> &nbsp;View Voting Breakdown </Button>
                    </Grid>
                    <Grid item xs={4} className="FormHeaderOrFooterDetails">
                        <Button variant="contained" size="small" color="secondary" disabled={pendingEdits}component="label" onClick={() => handleConfirmApproveRisks()}><Upload /> &nbsp;Approve All </Button>
                    </Grid>
                </Box>
            </Paper>
        </Box >
    )
}
