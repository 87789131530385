import { Grid, Typography } from "@mui/material"
import { RiskAssessment } from "../../helpers/Interfaces"
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent"

interface Props {
    riskAssessment: RiskAssessment
}

export function NoOngoingVotingView({ riskAssessment }: Props) {
    return (
        <>
            <VerticalScrollableComponent viewPortHeightOffset={181}>
                <Grid id="VoteRiskContainer" container spacing={2} className="FormDetails" >
                    <Typography color="secondary" paddingTop={5} paddingBottom={5} align="center" width="100%" fontSize={16}> No Risk Voting Exercise is currently ongoing for the {riskAssessment.assessmentName}</Typography>
                </Grid>
            </VerticalScrollableComponent>
            <br />
        </>
    )
}