import { Button, Paper, Stack, Typography } from "@mui/material";
import { AppPlatform, RoutePathNames } from "../helpers/Constants";
import { callServerTepngUserLogin, fireAxiosErrorMySwal, showBackDrop } from "../helpers/Utils";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Login } from "@mui/icons-material";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";
import { useCallback, useEffect } from "react";
import { useAppSelector } from "../store/store";


const MySwal = withReactContent(Swal);

export function LoginTepngUser() {
    const navigate = useNavigate()
    const appConfig = useAppSelector(state => state.appConfig.value);
    let queryParams = new URLSearchParams(window.location.search);
    const azureAuthRedirect = queryParams.get("azureauthredirect") === '1'
    // microsoft login redirect - the frond end url with the /login-tepnguser needs to be added to Azure App Service authentication Allowed external redirect URLs to allow for login redirect

    const login = useCallback(async () => {
        MySwal.fire(showBackDrop("User Sign in ongoing"));
        callServerTepngUserLogin()
            .then(response => {
                MySwal.close();
                const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
                localStorage.removeItem('loginRedirectedFromUrl');
                navigate(loginRedirectedFromUrl ?? "/" + RoutePathNames.HOMEPAGE)
            })
            .catch(function (error) {
                //if this is not a redirect from azure auth then we redirect to azure auth to perform azure AD authentication
                if (appConfig.APP_PLATFORM === AppPlatform.Azure && !azureAuthRedirect && error?.response?.status === 403) {
                    let url = new URL(window.location.origin + '/' + RoutePathNames.LOGIN)
                    url.searchParams.append('azureauthredirect', '1') // microsoft login redirect - the frond end url with the /login-tepnguser needs to be added to Azure App Service authentication Allowed external redirect URLs to allow for login redirect
                    window.location.replace(appConfig.SERVER_URL + '/.auth/login/aad?post_login_redirect_uri=' + window.encodeURIComponent(url.href));
                }
                else
                    fireAxiosErrorMySwal(error)
            })
    }, [navigate, azureAuthRedirect, appConfig.APP_PLATFORM, appConfig.SERVER_URL])

    useEffect(() => {
        // attempt login again if azure has made a redirection
        if (appConfig.APP_PLATFORM === AppPlatform.Azure && azureAuthRedirect)
            login()
    }, [azureAuthRedirect, login, appConfig.APP_PLATFORM]);

    return (
        <Paper className="Form"  >
            <br />
            <VerticalScrollableComponent viewPortHeightOffset={161}>
                <Stack direction="column" spacing={2} style={{ width: '100%', height: '100%', justifyContent: "center" }}  >
                    <div key={1} style={{ display: "flex", padding: 5, alignItems: "center", justifyContent: "center" }}>
                        <img style={{ width: 400 }} src='../../assets/images/logo_large_center.png' alt="logo_large_center.png" />
                    </div>

                    <div key={2} style={{ display: "flex", padding: 5, alignItems: "center", justifyContent: "center" }}>
                        <Typography align="center" sx={{ fontSize: 14 }} color="textPrimary" > Click here to logon {appConfig.APP_PLATFORM === AppPlatform.Azure ? " via Digital Pass" : ""} </Typography>
                    </div>
                    <div key={3} style={{ display: "flex", padding: 5, alignItems: "center", justifyContent: "center" }}>
                        <Button onClick={() => login()} variant="contained" size="medium" color="secondary"><Login /> &nbsp; Login </Button>
                    </div>

                </Stack>
            </VerticalScrollableComponent>
            <br />
        </Paper>
    );
}