import { Save } from "@mui/icons-material";
import { Paper, Box, Typography, Grid, TextField, Button, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { EmailTemplate } from "../../helpers/Interfaces";
import { useAppSelector } from "../../store/store";
import { updateEmailTemplate } from "../../api-services/EmailTemplateApi";
import { createToolBarButtonFromLinkPlaceHolder, createToolBarButtonFromTextPlaceHolder } from "./EmailTemplatePlaceHolderButtonCreator";
import { RichTextEditor } from "../../layouts/RichTextEditor";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { infoColor } from "../../layouts/theme";


interface Props {
    emailTemplateToEdit: EmailTemplate
    onEmailTemplateChange: (templateType: number, emailTemplate: EmailTemplate) => void
}

export function EditEmailTemplate(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const subjectTextBoxRef = useRef<HTMLInputElement>(null)
    //states
    const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>(props.emailTemplateToEdit)

    // #region effects

    useEffect(() => {
        setEmailTemplate(props.emailTemplateToEdit)
    }, [props.emailTemplateToEdit])

    // #endregion effects


    function handleUpdateEmailTemplate() {
        updateEmailTemplate(emailTemplate).then(result => {
            props.onEmailTemplateChange(result.templateType, result);
        })
    }

    function insertSubjectPlaceholder(placeholder: string) {
        if (subjectTextBoxRef.current == null) return
        var before = subjectTextBoxRef.current.value.slice(0, subjectTextBoxRef.current.selectionStart ?? undefined);
        var after = subjectTextBoxRef.current.value.slice(subjectTextBoxRef.current.selectionEnd ?? undefined);
        var text = before + placeholder + after;
        setEmailTemplate(emailTemplate => ({ ...emailTemplate, subject: text }))
    }

    return (
        emailTemplate && loggedOnUserProfile &&
        <Paper className="Form">
            <Box className="FormHeader">
                <Typography className="FormHeaderOrFooterDetails"> Edit Email Template</Typography>
            </Box>
            <VerticalScrollableComponent viewPortHeightOffset={200} >
                <Grid id="CreateEditEmailTemplateEditorContainer" container spacing={4} className="FormDetails">
                    <Grid item xs={12} alignItems='center' >
                        <Typography fontSize={16} align="center" color={infoColor}>{emailTemplate.name} </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} >
                        <Typography color="textSecondary" >Subject:</Typography>
                        <TextField inputRef={subjectTextBoxRef} fullWidth name="subject" type="text" variant="outlined" size="small" value={emailTemplate.subject ?? ''} onChange={event => setEmailTemplate(emailTemplate => ({ ...emailTemplate, subject: event.target.value }))} />
                        {emailTemplate.textPlaceholders.map(placeholder =>
                            <Button key={placeholder} style={{ textTransform: 'none' }} variant="text" title={"insert placeholder for " + placeholder} onClick={() => insertSubjectPlaceholder('#' + placeholder)}><i id={placeholder} style={{ fontSize: 13 }} className="fa fa-hashtag">{placeholder}</i></Button>
                        )}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                        <Typography color="textSecondary" >Body:</Typography>
                        <RichTextEditor key={emailTemplate.templateType} editorHtmlString={emailTemplate.body} //the key property is important as we have many different instances of this component
                            onChange={(editorHtmlString) => setEmailTemplate(emailTemplate => ({ ...emailTemplate, body: editorHtmlString ?? '' }))}
                            toolbarCustomButtons={[...emailTemplate.textPlaceholders.map(e => createToolBarButtonFromTextPlaceHolder(e)), createToolBarButtonFromLinkPlaceHolder(emailTemplate.linkPlaceholder)]}
                        />
                    </Grid>
                </Grid>
            </VerticalScrollableComponent>
            <Box className="FormFooter" >
                <Grid item xs={12} className="FormHeaderOrFooterDetails">
                    <Button disabled={!emailTemplate.name} variant="contained" size="small" color="info" onClick={() => handleUpdateEmailTemplate()} ><Save /> &nbsp;Update Email Template </Button>
                </Grid>
            </Box>
        </Paper>
    )
}
