import { Typography } from "@mui/material";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { RiskAssessment } from "../../helpers/Interfaces";

interface Props {
    riskAssessmentForRiskIdentification: RiskAssessment
}

export function NotAuthorisedForRiskIdentification({ riskAssessmentForRiskIdentification }: Props) {
    return (
        <VerticalScrollableComponent viewPortHeightOffset={160}>
            <Typography color="secondary" paddingTop={5} paddingBottom={5} align="center" width="100%" fontSize={16}>
                You are not authorised to identify Risks in the {riskAssessmentForRiskIdentification.assessmentName}
            </Typography>
        </VerticalScrollableComponent>
    )
}