import { RiskActionStatus, RiskStatus } from "./Constants";
import { Risk, RiskAction } from "./Interfaces";
import { isNullOrWhiteSpace } from "./Utils";

class BusinessRule {
    errors: string[] = []

    constructor(errors: string[]) {
        this.errors = errors
    }

    validate() {
        return this.errors.length === 0
    }
}

export function SubmitActionPlanRequiresOneOrMoreDraftRiskActions(risk: Risk) {
    var errors = []
    if (risk.riskActions.find(a => a.status === RiskActionStatus.DRAFT) == null)
        errors.push("There should be at least one draft action to submit action plan");
    return new BusinessRule(errors);
}

export function SubmitActionPlanRequiresZeroRejectedRiskAction(risk: Risk) {
    var errors = []
    if (risk.riskActions.find(a => a.status === RiskActionStatus.REJECTED) != null)
        errors.push("All rejected risk actions must be updated to submit action plan");
    return new BusinessRule(errors);
}

export function NoPendingActionValidationToSubmitActionPlanValidation(risk: Risk) {
    var errors = []
    if (risk.riskActions.find(e => e.status === RiskActionStatus.SUBMITTED) != null)
        errors.push("All submitted actions should be approved or rejected to submit the Action Plan Validation");
    return new BusinessRule(errors);
}

export function CannotSubmitIncompleteActionPlanImplementation(risk: Risk) {
    var errors = []
    if (risk.riskActions.find(a => a.status === RiskActionStatus.APPROVED && (isNullOrWhiteSpace(a.mitigationImplemented) || a.implementationDate == null)) != null)
        errors.push("All actions should have their implementation updated before submitting the implementation");
    return new BusinessRule(errors);
}

export function CanOnlyFlagOrSubmitRiskChangeForApprovedRisks(risk: Risk) {
    var errors = []
    if (!(risk.status >= RiskStatus.APPROVED))
        errors.push(`Risk: ${risk.name} must have been Approved to submit changes or flag as unexposed`);
    return new BusinessRule(errors);
}

export function CanOnlyDeleteDraftOrRejectedRiskAction(riskAction: RiskAction) {
    var errors = []
    if (!(riskAction.status === RiskActionStatus.DRAFT || riskAction.status === RiskActionStatus.REJECTED))
        errors.push(`Risk Action: ${riskAction.name} must be Draft or Rejected to delete`);
    return new BusinessRule(errors);
}
