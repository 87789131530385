import { EditorState, Modifier } from "draft-js";
import '../../layouts/font-awesome-4.7.0/css/font-awesome.min.css'
import { ToolbarCustomButton } from "../../layouts/RichTextEditor";


function addPlaceHolder(placeholder: string, editorState: EditorState) {
    let contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        '#' + placeholder,
        editorState.getCurrentInlineStyle(),
    );
    let newState = EditorState.push(editorState, contentState, 'insert-characters')
    return newState
}

function addLink(linkText: string, editorState: EditorState) {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: '#' + linkText });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, editorState.getSelection(), entityKey);
    const styledContentStateWithLink = Modifier.applyInlineStyle(contentStateWithLink, editorState.getSelection(), 'HYPERLINK');
    const newState = EditorState.set(editorState, { currentContent: styledContentStateWithLink });
    return newState
}

export function createToolBarButtonFromTextPlaceHolder(placeHolder: string) {
    const button: ToolbarCustomButton = {
        key: placeHolder,
        buttonRender: <div className="rdw-option-wrapper" title={"insert placeholder for " + placeHolder} ><i id={placeHolder} style={{ fontSize: 13 }} className="fa fa-hashtag">{placeHolder}</i></div>,
        onClick: addPlaceHolder
    }
    return button
}

export function createToolBarButtonFromLinkPlaceHolder(linkPlaceholder: string) {
    const button: ToolbarCustomButton = {
        key: linkPlaceholder,
        buttonRender: <div className="rdw-option-wrapper" title={"insert link placeholder for " + linkPlaceholder} ><i id={linkPlaceholder} style={{ fontSize: 13 }} className="fa fa-link">{linkPlaceholder}</i></div>,
        onClick: addLink
    }
    return button
}
