import { Button, Grid, Typography } from "@mui/material";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { RiskAssessment, UserProfile } from "../../helpers/Interfaces";

interface Props {
    riskAssessmentForRiskIdentification: RiskAssessment
    loggedOnUserProfile: UserProfile
    onClickBeginRiskAssessmentIdentification: () => void
}

export function RiskAssessmentWelcomeMessage({ riskAssessmentForRiskIdentification, loggedOnUserProfile, onClickBeginRiskAssessmentIdentification }: Props) {
    return (
        <VerticalScrollableComponent viewPortHeightOffset={160}>
            <Grid id="RiskAssessmentWelcomeMessageContainer" container spacing={0} className="FormDetails" >
                <div style={{ marginTop: 10, marginBottom: 10, marginLeft: 15, textAlign: 'left', width: '100%', }}>
                    <Typography color="textSecondary" fontSize={16}>
                        Dear {loggedOnUserProfile.fullName},
                    </Typography>
                </div>
                <div style={{ marginBottom: 5, marginLeft: 15, textAlign: 'left', width: '100%', fontSize: 16 }} dangerouslySetInnerHTML={{ __html: riskAssessmentForRiskIdentification.identificationWelcomeMessage ?? '' }}></div>
                <Typography paddingBottom={2} paddingLeft={5} align="center" color="textSecondary" width="100%" fontSize={16}>
                    <Button style={{ fontSize: 16 }} variant="text" color="info" size="large" onClick={() => onClickBeginRiskAssessmentIdentification()}> Click here to begin Risk Identification</Button>
                </Typography>
            </Grid>
        </VerticalScrollableComponent>
    )
}