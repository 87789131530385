import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { RiskAction, Risk } from "../../helpers/Interfaces";
import { FormEditMode } from "../../helpers/Constants";
import { ViewRiskAction } from "./ViewRiskAction";
import { CreateEditRiskAction } from "./CreateEditRiskAction";
import { UpdateRiskActionPlanImplementation } from "./UpdateRiskActionPlanImplementation";
import { HorizontalSplitPane } from "../../layouts/HorizontalSplitPane";
import { RiskActionsWidget } from "./RiskActionsWidget";


interface Props {
    riskToTreat: Risk
    onRiskChange: (id: number, risk: Risk) => void
}


export function ManageRiskActionPlan(props: Props) {
    const location = useLocation();
    let queryParams = new URLSearchParams(location.search);
    const defaultRiskActionId: number | null = queryParams.get("defaultRiskActionId") == null ? null : Number(queryParams.get("defaultRiskActionId"))
    //states
    const [risk, setRisk] = useState<Risk>(props.riskToTreat)
    const [selectedRiskAction, setSelectedRiskAction] = useState<RiskAction | null | undefined>(null)
    const [formEditMode, setFormEditMode] = useState<FormEditMode | null>(null)
    const [implementationEditmode, setImplementationEditmode] = useState(false)

    // #region effects

    useEffect(() => {
        setRisk(props.riskToTreat);
        if (props.riskToTreat.riskActions.length > 0)
            setSelectedRiskAction(defaultRiskActionId != null ? (props.riskToTreat.riskActions.find(a => a.id === defaultRiskActionId)) : props.riskToTreat.riskActions[0])
        setFormEditMode(props.riskToTreat.riskActions.length > 0 ? null : FormEditMode.CREATE); // null translates to view mode
    }, [props.riskToTreat, defaultRiskActionId])

    // #endregion effects

    function handleRiskActionChange(id: number, risk: Risk, actionId: number) {
        props.onRiskChange(id, risk);
        handleChangeRiskActionEditDisplay(risk.riskActions.find(a => a.id === actionId)!, false, null) //Risk action View
    }

    function handleChangeRiskActionEditDisplay(action: RiskAction | null, implementationEditmode: boolean, formEditMode: FormEditMode | null) {
        setSelectedRiskAction(action)
        setImplementationEditmode(implementationEditmode)
        setFormEditMode(formEditMode)
    }


    function RiskActionDisplay() {
        if (implementationEditmode === false && formEditMode === FormEditMode.CREATE)
            return <CreateEditRiskAction risk={risk} onRiskActionChange={handleRiskActionChange} formEditMode={FormEditMode.CREATE} />
        if (selectedRiskAction != null && implementationEditmode === false && formEditMode === FormEditMode.EDIT)
            return <CreateEditRiskAction risk={risk} riskActionToEdit={selectedRiskAction} onRiskActionChange={handleRiskActionChange} formEditMode={FormEditMode.EDIT} />
        if (selectedRiskAction != null && implementationEditmode === true && formEditMode === FormEditMode.EDIT)
            return <UpdateRiskActionPlanImplementation risk={risk} riskActionToEdit={selectedRiskAction} onRiskActionChange={handleRiskActionChange} />
        if (selectedRiskAction != null && formEditMode == null)
            return <ViewRiskAction risk={risk} riskAction={selectedRiskAction} onRiskActionChange={handleRiskActionChange} />
        return <></>
    }


    return (
        <Box className='HorizontalOverFlowContainer'>
            <HorizontalSplitPane leftWidth={363} rightWidthDynamic
                left={
                    <RiskActionsWidget risk={props.riskToTreat} selectedRiskAction={selectedRiskAction} onRiskChange={props.onRiskChange}
                        onRiskActionChange={handleRiskActionChange} onChangeRiskActionDisplay={handleChangeRiskActionEditDisplay}
                    />}
                right={<RiskActionDisplay />}
            />
        </Box>
    )
}
