import { Class, Send, ThumbDown, ThumbUp } from "@mui/icons-material";
import { Paper, Box, Typography, Grid, Button } from "@mui/material";
import { RiskAssignmentDisplay, RiskStatus, RiskTreatmentDisplay, RoutePathNames } from "../../helpers/Constants";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { useAppSelector } from "../../store/store";
import { Risk } from "../../helpers/Interfaces";
import { PrioritiseRisk } from "./PrioritiseRisk";
import { AssignRisk } from "./AssignRisk";
import { checkUserIsRiskChampion, showInputBox } from "../../helpers/Utils";
import { ViewRisk } from "../shared/ViewRisk";
import { useNavigate } from "react-router-dom";

interface Props {
    display: RiskAssignmentDisplay
    risks: Risk[]
    selectedRisk: Risk | null | undefined
    onClickNotifyNominatedChampion: (risk: Risk) => void
    onClickAcceptChampionNomination: (risk: Risk) => void
    onClickRejectChampionNomination: (risk: Risk, rejectionComments: string) => void
    onChangeRiskAssignmentDisplay: (display: RiskAssignmentDisplay, risk?: Risk | null) => void
    onRiskChange: (id: number, risk: Risk) => void
}

export function RiskAssignmentDetails(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value)
    const navigate = useNavigate()

    function handleConfirmRejectChampionNomination() {
        showInputBox("Comments are required to reject Risk Champion nomination", "Rejection Comments", "please enter comments")
            .then((result) => {
                var championRejectionComments: string = result.value
                if (championRejectionComments)
                    props.onClickRejectChampionNomination(props.selectedRisk!, championRejectionComments)
            })
    }

    return (
        loggedOnUserProfile &&
        <>
            {props.display === RiskAssignmentDisplay.VIEW_RISK_PRIORITISATION && props.selectedRisk &&
                <PrioritiseRisk riskToPrioritise={props.selectedRisk} />
            }

            {props.display === RiskAssignmentDisplay.ASSIGN_RISK && props.selectedRisk && (props.selectedRisk.status === RiskStatus.APPROVED || props.selectedRisk.status === RiskStatus.CHAMPION_NOMINATED || props.selectedRisk.status === RiskStatus.CHAMPION_NOMINATION_REJECTED) &&
                <AssignRisk riskToAssign={props.selectedRisk} onRiskChange={props.onRiskChange} />
            }
            {props.display === RiskAssignmentDisplay.VIEW_RISK && props.selectedRisk &&
                <Paper className="Form" >
                    <Box className="FormHeader">
                        <Typography className="FormHeaderOrFooterDetails">Risk Details</Typography>
                    </Box>
                    <VerticalScrollableComponent viewPortHeightOffset={231}>
                        <ViewRisk risk={props.selectedRisk} />
                    </VerticalScrollableComponent>
                    <Box className="FormFooter">
                        <Grid item xs={4} className="FormHeaderOrFooterDetails" alignItems='center'>
                            <Button onClick={() => props.onChangeRiskAssignmentDisplay(RiskAssignmentDisplay.VIEW_RISK_PRIORITISATION, props.selectedRisk)} title='View Risk Prioritisation' variant="contained" size="small" color="info"  ><Class /> &nbsp; View Risk Prioritisation Matrix </Button>
                        </Grid>
                        {props.selectedRisk.status === RiskStatus.ASSIGNED_TO_CHAMPION ?
                            <Grid item xs={4} className="FormHeaderOrFooterDetails" alignItems='center'>
                                <Button onClick={() => navigate("/" + RoutePathNames.RISK_TREATMENT + '?defaultRiskId=' + props.selectedRisk!.id + '&defaultDisplay=' + RiskTreatmentDisplay.VIEW_EDIT_ACTION_PLAN)} variant="contained" size="small" color="secondary" disabled={!checkUserIsRiskChampion(props.selectedRisk, loggedOnUserProfile.email)}  ><Send /> &nbsp; GO-TO Risk Treatment </Button>
                            </Grid>
                            :
                            <Grid item xs={4} className="FormHeaderOrFooterDetails" alignItems='center'>
                                <Button onClick={() => props.onChangeRiskAssignmentDisplay(RiskAssignmentDisplay.ASSIGN_RISK, props.selectedRisk)} disabled={props.selectedRisk.status !== RiskStatus.APPROVED && props.selectedRisk.status !== RiskStatus.CHAMPION_NOMINATED && props.selectedRisk.status !== RiskStatus.CHAMPION_NOMINATION_REJECTED} title='Assign Risk' variant="contained" size="small" color="secondary"  ><Class /> &nbsp; Assign Risk </Button>
                            </Grid>
                        }
                        <Grid item xs={4} className="FormHeaderOrFooterDetails" alignItems='center'>
                            <Button onClick={() => props.onClickNotifyNominatedChampion(props.selectedRisk!)} variant="contained" size="small" color="info" disabled={props.selectedRisk.status !== RiskStatus.CHAMPION_NOMINATED || !loggedOnUserProfile.isERMTeamMember} ><Send /> &nbsp; Re-Send Champion Nomination Notification </Button>
                        </Grid>
                    </Box>
                </Paper >
            }

            {props.display === RiskAssignmentDisplay.ACCEPT_REJECT_CHAMPION && props.selectedRisk && props.selectedRisk.status === RiskStatus.CHAMPION_NOMINATED && checkUserIsRiskChampion(props.selectedRisk, loggedOnUserProfile.email) &&
                <Paper className="Form" >
                    <Box className="FormHeader">
                        <Typography className="FormHeaderOrFooterDetails">Risk Champion Nomination Acceptance / Rejection - click the Accept or Reject button below </Typography>
                    </Box>
                    <VerticalScrollableComponent viewPortHeightOffset={231}>
                        <ViewRisk risk={props.selectedRisk} />
                    </VerticalScrollableComponent>
                    <Box className="FormFooter">
                        <Grid item xs={6} className="FormHeaderOrFooterDetails" alignItems='center'>
                            <Button onClick={() => props.onClickAcceptChampionNomination(props.selectedRisk!)} variant="contained" size="small" color="success"  ><ThumbUp /> &nbsp; Accept </Button>
                        </Grid>
                        <Grid item xs={6} className="FormHeaderOrFooterDetails" alignItems='center'>
                            <Button onClick={() => handleConfirmRejectChampionNomination()} variant="contained" size="small" color="error"  ><ThumbDown /> &nbsp; Reject </Button>
                        </Grid>
                    </Box>
                </Paper >
            }
        </>
    )
}