import { AddCircleOutline, Refresh, ArrowRight, ModeEditOutline, GridView, Beenhere, DeleteOutline } from "@mui/icons-material"
import { Paper, Table, TableBody, TableRow, TableCell, ButtonGroup, Button, TextField, MenuItem, List, ListItem, ListItemText, IconButton } from "@mui/material"
import { RiskAssessmentDisplay, AssessmentTypes, RiskAssessmentStatus } from "../../helpers/Constants"
import { RiskAssessment } from "../../helpers/Interfaces"
import { getAssessmentTypeString, truncateTextToWhiteSpace } from "../../helpers/Utils"
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent"
import { useAppSelector } from "../../store/store"

interface Props {
    assessmentTypeFilter: string
    riskAssessments: RiskAssessment[]
    selectedRiskAssessment: RiskAssessment | null | undefined
    onAssessmentTypeFilterChange: (assessmentTypeFilter: string) => void
    onClickDeleteRiskAssessment: (assessmentId: number) => void
    onClickRefreshRiskAssessments: () => void
    onChangeRiskAssessmentDisplay: (display: RiskAssessmentDisplay, riskAssessment?: RiskAssessment | null) => void
}

export function RiskAssessmentWidget({ assessmentTypeFilter, onAssessmentTypeFilterChange, riskAssessments, selectedRiskAssessment, onClickRefreshRiskAssessments, onChangeRiskAssessmentDisplay, onClickDeleteRiskAssessment }: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);

    return (loggedOnUserProfile && riskAssessments &&
        <Paper className="Widget">
            <Table style={{ width: '288px' }}>
                <TableBody>
                    <TableRow >
                        <TableCell valign='middle' align='center' style={{ width: 100, paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 5 }}>
                            <ButtonGroup size="small" variant="text" color="primary">
                                <Button onClick={() => { onChangeRiskAssessmentDisplay(RiskAssessmentDisplay.CREATE_RISKASSESSMENT) }} title="Create new Risk Assessment"><AddCircleOutline color="secondary" />&nbsp;New </Button>
                                <Button onClick={() => onClickRefreshRiskAssessments()} title="Refresh Risk Assessments" color='info'><Refresh /> </Button>
                            </ButtonGroup>
                        </TableCell>
                        <TableCell valign='middle' style={{ paddingLeft: 0, paddingRight: 5, paddingTop: 0, paddingBottom: 5, }}>
                            <TextField fullWidth select variant="outlined" size="small" value={assessmentTypeFilter + ""} onChange={(event) => onAssessmentTypeFilterChange(event.target.value)}>
                                <MenuItem key="" value="All">All</MenuItem>
                                <MenuItem value={AssessmentTypes.AFFILIATE}>{getAssessmentTypeString(AssessmentTypes.AFFILIATE)}</MenuItem >
                                <MenuItem value={AssessmentTypes.PROJECT}>{getAssessmentTypeString(AssessmentTypes.PROJECT)}</MenuItem >
                                <MenuItem value={AssessmentTypes.DRILL}>{getAssessmentTypeString(AssessmentTypes.DRILL)}</MenuItem >
                                <MenuItem value={AssessmentTypes.OTHERS}>{getAssessmentTypeString(AssessmentTypes.OTHERS)}</MenuItem >
                            </TextField>
                        </TableCell >
                    </TableRow>
                    <TableRow >
                        <TableCell colSpan={2} style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
                            <VerticalScrollableComponent viewPortHeightOffset={203}>
                                <List style={{ padding: 0 }}>
                                    {riskAssessments.map(riskAssessment =>
                                        <ListItem divider={false} key={riskAssessment.id} classes={{ selected: "SelectedMenu", root: "Menu" }} selected={selectedRiskAssessment?.id === riskAssessment.id} >
                                            <ArrowRight fontSize="medium" color={selectedRiskAssessment?.id === riskAssessment.id ? "secondary" : "primary"} />
                                            <ListItemText style={{ wordWrap: 'break-word', cursor: 'pointer', width: '100%', color: riskAssessment.status === RiskAssessmentStatus.CLOSED ? "#888888" : "#000000" }} onClick={() => { onChangeRiskAssessmentDisplay(RiskAssessmentDisplay.VIEW_RISKASSESSMENT, riskAssessment) }} primary={truncateTextToWhiteSpace(riskAssessment.assessmentName, 70)} title={riskAssessment.assessmentName} />
                                            <IconButton color="info" edge="end" onClick={() => { onChangeRiskAssessmentDisplay(RiskAssessmentDisplay.EDIT_RISKASSESSMENT, riskAssessment) }} disabled={riskAssessment == null || riskAssessment.status === RiskAssessmentStatus.CLOSED} title='Edit Risk Assessment Details' >
                                                <ModeEditOutline />
                                            </IconButton>
                                            <IconButton color="info" edge="end" onClick={() => { onChangeRiskAssessmentDisplay(RiskAssessmentDisplay.VIEW_RISKS_GRIDVIEW, riskAssessment) }} disabled={riskAssessment == null} title='View / Aggregate Risks in Risk Assessment' >
                                                <GridView />
                                            </IconButton>
                                            <IconButton color="info" edge="end" onClick={() => { onChangeRiskAssessmentDisplay(RiskAssessmentDisplay.APPROVE_RISKASSESSMENT_VOTING, riskAssessment) }} disabled={riskAssessment == null || riskAssessment.status !== RiskAssessmentStatus.APPROVAL} title='Approve Risk Assessment Voting' >
                                                <Beenhere />
                                            </IconButton>
                                            <IconButton color="info" edge="end" onClick={() => onClickDeleteRiskAssessment(riskAssessment.id)} disabled={riskAssessment == null || riskAssessment.status !== RiskAssessmentStatus.NEW} title='Delete Risk Assessment' >
                                                <DeleteOutline />
                                            </IconButton>
                                        </ListItem>
                                    )}
                                </List>
                            </VerticalScrollableComponent>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    )
}
