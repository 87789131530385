import { useEffect, useState } from 'react'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const emptyState = EditorState.createEmpty();

export interface ToolbarCustomButton {
  key: string
  buttonRender: React.ReactElement<HTMLElement>
  onClick: (key: string, editorState: EditorState) => EditorState;
}

interface Props {
  editorHtmlString: string | null | undefined
  toolbarCustomButtons?: ToolbarCustomButton[]
  onChange: (editorHtmlString: string | null | undefined) => void;
}

export function RichTextEditor(props: Props) {
  const [richTextEditorState, setRichTextEditorState] = useState<EditorState | null>(null)
  const [initialEditorHtmlString,] = useState(props.editorHtmlString)

  useEffect(() => {
    function loadRichTextEditorStateFromData() {
      if (initialEditorHtmlString == null || initialEditorHtmlString === '')
        setRichTextEditorState(emptyState)
      else
        setRichTextEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(initialEditorHtmlString).contentBlocks)))
    }
    loadRichTextEditorStateFromData()
  }, [initialEditorHtmlString])

  const updateRichTextEditorState = (newState: EditorState) => {
    setRichTextEditorState(newState)
    if (newState == null || !newState.getCurrentContent().hasText() || newState.getCurrentContent().getPlainText().trim() === '') // check for htmlcontent without text (tags only)
      props.onChange('')
    else
      props.onChange(draftToHtml(convertToRaw(newState.getCurrentContent())))
  }

  return (
    richTextEditorState &&
    <div style={{ padding: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#cccccc', borderRadius: 5 }}>
      <Editor key={1} hashtag={{ separator: ' ', trigger: '#' }} editorState={richTextEditorState}
        onEditorStateChange={(state) => updateRichTextEditorState(state)} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName"
        toolbarCustomButtons={props.toolbarCustomButtons?.map((e, index) =>
          <div key={index} className='rdw-emoji-wrapper' onClick={() => updateRichTextEditorState(e.onClick(e.key, richTextEditorState))}>
            {e.buttonRender}
          </div>
        )}
        toolbar={{
          options: ['list', 'inline', 'colorPicker', 'link',],
          inline: { options: ['italic', 'bold', 'underline', 'superscript', 'subscript'], },
          list: { options: ['unordered', 'ordered', 'indent', 'outdent'], },
        }} />
    </div>
  )
}