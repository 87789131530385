import { useState } from "react";
import { Stack, TextField, MenuItem } from "@mui/material";
import { Risk } from "../helpers/Interfaces";
import { useAppSelector } from "../store/store";
import { useLocation } from "react-router-dom";
import { getRiskStatusString, getRiskPrioritisationZoneString, getFilteredAndSortedRisks } from "../helpers/Utils";
import { RiskStatus, RiskSortType, RiskAssignmentDisplay, RiskPrioritisationZones, DisplayRefreshTrigger, RiskGroupFilters, RiskTreatmentDisplay } from "../helpers/Constants";
import { HorizontalSplitPane } from "../layouts/HorizontalSplitPane";
import { useDisplay, useRisksForTreatment, useSelectedRisk } from "../helpers/GlobalHooks";
import { RiskTreatmentWidget } from "../components/risk-treatment/RiskTreatmentWidget";
import { RiskTreatmentDetails } from "../components/risk-treatment/RiskTreatmentDetails";

export function RiskTreatmentEditor() {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const location = useLocation();
    let queryParams = new URLSearchParams(location.search);
    const defaultRiskId: number | null = queryParams.get("defaultRiskId") == null ? null : Number(queryParams.get("defaultRiskId"))
    const defaultDisplay: number = queryParams.get("defaultDisplay") != null ? Number(queryParams.get("defaultDisplay")) : RiskTreatmentDisplay.VIEW_RISK
    const defaultPrioritisationFilter: string = queryParams.get("defaultPrioritisationFilter") ?? 'All'
    // states
    const [risks, setRisks, refreshRisks] = useRisksForTreatment()
    const [riskSortType, setRiskSortType] = useState<RiskSortType>(RiskSortType.SortDateCreatedDesc)
    const [prioritisationFilter, setPrioritisationFilter] = useState(defaultPrioritisationFilter)
    const [entityFilter, setEntityFilter] = useState('All')
    const [riskAssessmentFilter, setRiskAssessmentFilter] = useState('All')
    const [statusFilter, setStatusFilter] = useState('All')
    const [displayRefreshTrigger, setDisplayRefreshTrigger] = useState<DisplayRefreshTrigger | null>(null)
    const filteredRisks = getFilteredAndSortedRisks(loggedOnUserProfile, risks, entityFilter, "All", statusFilter, riskAssessmentFilter, prioritisationFilter + '', RiskGroupFilters.GLOBAL_RISKS, riskSortType)
    const { selectedRisk, setSelectedRisk } = useSelectedRisk(filteredRisks, defaultRiskId, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected)
    const { display, setDisplay } = useDisplay(filteredRisks, defaultDisplay, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected, RiskAssignmentDisplay.VIEW_RISK, RiskAssignmentDisplay.VIEW_RISK)


    function handleRiskChange(id: number, risk: Risk) {
        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
        setRisks(risks => risks.map(inst => inst.id === id ? risk : inst))
        setSelectedRisk(risk)
        //setDisplay(RiskTreatmentDisplay.VIEW_RISK)
    }

    function handleChangeRiskTreatmentDisplay(display: RiskTreatmentDisplay, risk?: Risk | null) {
        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
        if (risk != null) setSelectedRisk(risk)
        setDisplay(display);;
    }

    return (
        loggedOnUserProfile &&
        <>
            <Stack direction="row" spacing={2} style={{ width: '100%', paddingLeft: 5, paddingRight: 5, paddingTop: 0, paddingBottom: 5 }} >
                <TextField fullWidth select variant="standard" size="small" name="prioritisationFilter" value={prioritisationFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setPrioritisationFilter(e.target.value) }}>
                    <MenuItem value="All">All Prioritisation Zones</MenuItem>
                    <MenuItem value={RiskPrioritisationZones.DELEGATION_ZONE}>{getRiskPrioritisationZoneString(RiskPrioritisationZones.DELEGATION_ZONE)}</MenuItem >
                    <MenuItem value={RiskPrioritisationZones.VIGILANCE_ZONE}>{getRiskPrioritisationZoneString(RiskPrioritisationZones.VIGILANCE_ZONE)}</MenuItem >
                    <MenuItem value={RiskPrioritisationZones.VERIFICATION_ZONE}>{getRiskPrioritisationZoneString(RiskPrioritisationZones.VERIFICATION_ZONE)}</MenuItem >
                    <MenuItem value={RiskPrioritisationZones.PRIORITY_REMEDIATION}>{getRiskPrioritisationZoneString(RiskPrioritisationZones.PRIORITY_REMEDIATION)}</MenuItem >
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="statusFilter" value={statusFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setStatusFilter(e.target.value) }}>
                    <MenuItem key={0} value="All">All Status</MenuItem >
                    <MenuItem key={1} value={RiskStatus.ASSIGNED_TO_CHAMPION}>{getRiskStatusString(RiskStatus.ASSIGNED_TO_CHAMPION)}</MenuItem >
                    <MenuItem key={2} value={RiskStatus.ACTION_PLAN_SUBMITTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_SUBMITTED)}</MenuItem >
                    <MenuItem key={3} value={RiskStatus.ACTION_PLAN_REJECTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_REJECTED)}</MenuItem >
                    <MenuItem key={4} value={RiskStatus.ACTION_PLAN_APPROVED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_APPROVED)}</MenuItem >
                    <MenuItem key={6} value={RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED)}</MenuItem >
                    <MenuItem key={7} value={RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR}>{getRiskStatusString(RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR)}</MenuItem >
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="riskAssessmentFilter" value={riskAssessmentFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setRiskAssessmentFilter(e.target.value) }}>
                    <MenuItem value="All">All Risk Types</MenuItem >
                    {[...new Map(risks.filter(row => row.sourceAssessment != null).map(item => [item.sourceAssessment!.id, item.sourceAssessment!])).values()].map(row => <MenuItem key={row!.id} value={row!.id}>{row!.assessmentName}</MenuItem >)}
                </TextField>
                <TextField fullWidth select variant="standard" size="small" name="entityFilter" value={entityFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setEntityFilter(e.target.value) }}>
                    <MenuItem value="All">All Entities</MenuItem >
                    {riskRelatedTables.entities.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem >)}
                </TextField>
            </Stack>

            <HorizontalSplitPane leftWidth={299} rightWidthDynamic hideLeft={display !== RiskTreatmentDisplay.VIEW_RISK}
                left={
                    <RiskTreatmentWidget risks={filteredRisks} selectedRisk={selectedRisk} onRiskSortTypeChange={setRiskSortType}
                        onChangeRiskTreatmentDisplay={handleChangeRiskTreatmentDisplay} onClickRefreshRisks={() => refreshRisks()}
                    />}
                right={
                    <RiskTreatmentDetails risks={risks} selectedRisk={selectedRisk} onRiskChange={handleRiskChange}
                        display={display} onChangeRiskTreatmentDisplay={(display, risk) => handleChangeRiskTreatmentDisplay(display, risk)}
                    />}
            />
        </>
    )
}
