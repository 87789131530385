import { ArrowBackIos, ArrowForwardIos, HowToVote } from "@mui/icons-material";
import { Grid, Table, TableBody, TableRow, TableCell, Typography, TableHead, Radio, Box, Button } from "@mui/material";
import { ImpactLikelihoodColor, MitigationColor, RiskStatus, SeverityColor } from "../../helpers/Constants";
import { RiskAssessment, RiskRatingDto } from "../../helpers/Interfaces";
import { getRatingDescription, getRatingDescriptionHtml, showConfirmBox } from "../../helpers/Utils";
import { useAppSelector } from "../../store/store";
import {  useState } from "react";
import { submitVotes } from "../../api-services/RiskAssessmentApi";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";

interface Props {
    riskAssessment: RiskAssessment
    onVotingSubmitted: (riskAssessment: RiskAssessment) => void
}

export function VoteRiskView({ riskAssessment, onVotingSubmitted }: Props) {
    // state independent variables
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    //states
    const [riskVotings, setRiskVotings] = useState<RiskRatingDto[]>(riskAssessment.risks.filter(e => e.severity >= riskAssessment.minSeverityForVoting).map(risk => ({ riskId: risk.id, impact: 0, likelihood: 0, mitigation: 0 })))
    const [riskCounter, setRiskCounter] = useState(0)
    var risk = riskAssessment.risks.filter(e => e.severity >= riskAssessment.minSeverityForVoting)[riskCounter]


    function handleConfirmASubmitVotes() {
        if (riskAssessment == null) return
        showConfirmBox("You will not be able to make changes after submitting. Are you sure you want to submit your votes and ratings?", "Confirm Submit Votes")
            .then((confirmResult) => {
                if (confirmResult.isConfirmed)
                    submitVotes(riskAssessment, riskVotings).then(result => onVotingSubmitted(result))
            })
    }

    function handleImpactChange(riskId: number, impact: number) {
        setRiskVotings(riskVotings => riskVotings.map(riskVoting => riskVoting.riskId === riskId ? { ...riskVoting, impact: impact } : riskVoting))
    }

    function handleLikelihoodChange(riskId: number, likelihood: number) {
        setRiskVotings(riskVotings => riskVotings.map(riskVoting => riskVoting.riskId === riskId ? { ...riskVoting, likelihood: likelihood } : riskVoting))
    }

    function handleMitigationChange(riskId: number, mitigation: number) {
        setRiskVotings(riskVotings => riskVotings.map(riskVoting => riskVoting.riskId === riskId ? { ...riskVoting, mitigation: mitigation } : riskVoting))
    }

    return (
        riskAssessment && risk &&
        <>
            <VerticalScrollableComponent viewPortHeightOffset={200}>
                <Grid id="VoteRiskContainer" container spacing={2} className="FormDetails" >

                    <Grid item xs={12}>
                        <Grid container spacing={2} paddingLeft={2}  >
                            <Grid item xs={8}>
                                <Grid item xs={12}>
                                    <Table style={{ width: '100%' }} size="small"  >
                                        <TableBody color="primary">
                                            <TableRow>
                                                <TableCell valign="middle" align="left" style={{ width: 50, border: 0 }} title={'ERM-' + risk.id} ><Typography align="center" fontSize={18} style={{ padding: 5, width: '30px', color: 'white', backgroundColor: '#0288D1' }}>{riskCounter + 1}</Typography></TableCell>
                                                <TableCell valign="middle" align="left" style={{ border: 0 }}><Typography fontSize={14} color="textSecondary" >{risk.name ?? ''} </Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" ><u>Risk Description / Impact:</u></Typography>
                                    <Typography color="textPrimary">{risk.description} </Typography>
                                    <br /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" ><u>Existing Mitigations:</u></Typography>
                                    <Typography color="textPrimary" >{risk.existingMitigations} </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid item xs={12} style={{ borderWidth: 1, borderColor: '#CCCCCC', borderStyle: 'solid' }}>
                                    <Table style={{ width: '100%' }} size="small"  >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={2} align="center" style={{ backgroundColor: '#EEEEEE', border: 1 }}><Typography>Risk Ratings by Risk Identifier(s)</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: 1 }}><Typography>Category:</Typography></TableCell>
                                                <TableCell align="left" style={{ border: 1 }}><Typography>{risk.category?.name} </Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: 1 }}><Typography>Strategic Objective Impacted:</Typography></TableCell>
                                                <TableCell align="left" style={{ border: 1 }}><Typography>{risk.businessObjectiveImpacted?.name}</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: 1 }}><Typography>Entities Impacted:</Typography></TableCell>
                                                <TableCell align="left" style={{ border: 1 }}>
                                                    <Typography>{risk.entitiesImpacted.map(entityImpacted => <span key={entityImpacted.id} title={entityImpacted.baseRadicalShort}>{entityImpacted.name + ","} &nbsp;&nbsp;&nbsp;&nbsp;</span>)} </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: 0 }}><Typography> Impact:</Typography></TableCell>
                                                <TableCell align="left" style={{ border: 0 }}>   <Typography alignContent="center" align="center" fontSize={14} style={{ padding: 1, width: '30px', color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[risk.impact] }}>{risk.impact}</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: 0 }}><Typography>Likelihood:</Typography></TableCell>
                                                <TableCell align="left" style={{ border: 0 }}>  <Typography alignContent="center" align="center" fontSize={14} style={{ padding: 1, width: '30px', color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[risk.likelihood] }}>{risk.likelihood}</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: 0 }}><Typography>Mitigation:</Typography></TableCell>
                                                <TableCell align="left" style={{ border: 0 }}>   <Typography alignContent="center" align="center" fontSize={14} style={{ padding: 1, width: '30px', color: '#FFFFFF', backgroundColor: MitigationColor[risk.mitigation] }}>{risk.mitigation}</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: 0 }}><Typography>Severity:</Typography></TableCell>
                                                <TableCell align="left" style={{ border: 0 }}><Typography alignContent="center" align="center" fontSize={14} style={{ padding: 1, width: '30px', color: '#FFFFFF', backgroundColor: SeverityColor[risk.severity] }}>{risk.severity}</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {risk.status === RiskStatus.SUBMITTED &&
                                    <Table size="small"  >
                                        <TableHead color="primary">
                                            <TableRow>
                                                <TableCell align="left" style={{ backgroundColor: '#EEEEEE' }}></TableCell>
                                                <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>6</TableCell>
                                                <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>5</TableCell>
                                                <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>4</TableCell>
                                                <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>3</TableCell>
                                                <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>2</TableCell>
                                                <TableCell align="center" style={{ backgroundColor: '#EEEEEE' }}>1</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody color="primary">
                                            <TableRow>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'middle' }}><Typography color="textSecondary" fontSize={16}>Impact</Typography></TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[6] }} name={risk.id + ""} value='6' checked={riskVotings.find(i => i.riskId === risk.id)?.impact === 6} onChange={() => handleImpactChange(risk.id, 6)} title={getRatingDescription(6, riskRelatedTables.ratingDescriptions, "IMPACT")} />{getRatingDescriptionHtml(6, riskRelatedTables.ratingDescriptions, "IMPACT")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[5] }} name={risk.id + ""} value='5' checked={riskVotings.find(i => i.riskId === risk.id)?.impact === 5} onChange={() => handleImpactChange(risk.id, 5)} title={getRatingDescription(5, riskRelatedTables.ratingDescriptions, "IMPACT")} />{getRatingDescriptionHtml(5, riskRelatedTables.ratingDescriptions, "IMPACT")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[4] }} name={risk.id + ""} value='4' checked={riskVotings.find(i => i.riskId === risk.id)?.impact === 4} onChange={() => handleImpactChange(risk.id, 4)} title={getRatingDescription(4, riskRelatedTables.ratingDescriptions, "IMPACT")} />{getRatingDescriptionHtml(4, riskRelatedTables.ratingDescriptions, "IMPACT")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[3] }} name={risk.id + ""} value='3' checked={riskVotings.find(i => i.riskId === risk.id)?.impact === 3} onChange={() => handleImpactChange(risk.id, 3)} title={getRatingDescription(3, riskRelatedTables.ratingDescriptions, "IMPACT")} />{getRatingDescriptionHtml(3, riskRelatedTables.ratingDescriptions, "IMPACT")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[2] }} name={risk.id + ""} value='2' checked={riskVotings.find(i => i.riskId === risk.id)?.impact === 2} onChange={() => handleImpactChange(risk.id, 2)} title={getRatingDescription(2, riskRelatedTables.ratingDescriptions, "IMPACT")} />{getRatingDescriptionHtml(2, riskRelatedTables.ratingDescriptions, "IMPACT")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[1] }} name={risk.id + ""} value='1' checked={riskVotings.find(i => i.riskId === risk.id)?.impact === 1} onChange={() => handleImpactChange(risk.id, 1)} title={getRatingDescription(1, riskRelatedTables.ratingDescriptions, "IMPACT")} />{getRatingDescriptionHtml(1, riskRelatedTables.ratingDescriptions, "IMPACT")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'middle' }}><Typography color="textSecondary" fontSize={16}>Likelihood</Typography></TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[6] }} name={risk.id + ""} value='6' checked={riskVotings.find(i => i.riskId === risk.id)?.likelihood === 6} onChange={() => handleLikelihoodChange(risk.id, 6)} title={getRatingDescription(6, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />{getRatingDescriptionHtml(6, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[5] }} name={risk.id + ""} value='5' checked={riskVotings.find(i => i.riskId === risk.id)?.likelihood === 5} onChange={() => handleLikelihoodChange(risk.id, 5)} title={getRatingDescription(5, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />{getRatingDescriptionHtml(5, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[4] }} name={risk.id + ""} value='4' checked={riskVotings.find(i => i.riskId === risk.id)?.likelihood === 4} onChange={() => handleLikelihoodChange(risk.id, 4)} title={getRatingDescription(4, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />{getRatingDescriptionHtml(4, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[3] }} name={risk.id + ""} value='3' checked={riskVotings.find(i => i.riskId === risk.id)?.likelihood === 3} onChange={() => handleLikelihoodChange(risk.id, 3)} title={getRatingDescription(3, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />{getRatingDescriptionHtml(3, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[2] }} name={risk.id + ""} value='2' checked={riskVotings.find(i => i.riskId === risk.id)?.likelihood === 2} onChange={() => handleLikelihoodChange(risk.id, 2)} title={getRatingDescription(2, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />{getRatingDescriptionHtml(2, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: ImpactLikelihoodColor[1] }} name={risk.id + ""} value='1' checked={riskVotings.find(i => i.riskId === risk.id)?.likelihood === 1} onChange={() => handleLikelihoodChange(risk.id, 1)} title={getRatingDescription(1, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />{getRatingDescriptionHtml(1, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'middle' }}><Typography color="textSecondary" fontSize={16}>Mitigation</Typography></TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: MitigationColor[6] }} name={risk.id + ""} value='6' checked={riskVotings.find(i => i.riskId === risk.id)?.mitigation === 6} onChange={() => handleMitigationChange(risk.id, 6)} title={getRatingDescription(6, riskRelatedTables.ratingDescriptions, "MITIGATION")} />{getRatingDescriptionHtml(6, riskRelatedTables.ratingDescriptions, "MITIGATION")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: MitigationColor[5] }} name={risk.id + ""} value='5' checked={riskVotings.find(i => i.riskId === risk.id)?.mitigation === 5} onChange={() => handleMitigationChange(risk.id, 5)} title={getRatingDescription(5, riskRelatedTables.ratingDescriptions, "MITIGATION")} />{getRatingDescriptionHtml(5, riskRelatedTables.ratingDescriptions, "MITIGATION")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: MitigationColor[4] }} name={risk.id + ""} value='4' checked={riskVotings.find(i => i.riskId === risk.id)?.mitigation === 4} onChange={() => handleMitigationChange(risk.id, 4)} title={getRatingDescription(4, riskRelatedTables.ratingDescriptions, "MITIGATION")} />{getRatingDescriptionHtml(4, riskRelatedTables.ratingDescriptions, "MITIGATION")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: MitigationColor[3] }} name={risk.id + ""} value='3' checked={riskVotings.find(i => i.riskId === risk.id)?.mitigation === 3} onChange={() => handleMitigationChange(risk.id, 3)} title={getRatingDescription(3, riskRelatedTables.ratingDescriptions, "MITIGATION")} />{getRatingDescriptionHtml(3, riskRelatedTables.ratingDescriptions, "MITIGATION")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: MitigationColor[2] }} name={risk.id + ""} value='2' checked={riskVotings.find(i => i.riskId === risk.id)?.mitigation === 2} onChange={() => handleMitigationChange(risk.id, 2)} title={getRatingDescription(2, riskRelatedTables.ratingDescriptions, "MITIGATION")} />{getRatingDescriptionHtml(2, riskRelatedTables.ratingDescriptions, "MITIGATION")}</TableCell>
                                                <TableCell style={{ fontSize: 14, textAlign: 'left', verticalAlign: 'top' }}><Radio style={{ color: MitigationColor[1] }} name={risk.id + ""} value='1' checked={riskVotings.find(i => i.riskId === risk.id)?.mitigation === 1} onChange={() => handleMitigationChange(risk.id, 1)} title={getRatingDescription(1, riskRelatedTables.ratingDescriptions, "MITIGATION")} />{getRatingDescriptionHtml(1, riskRelatedTables.ratingDescriptions, "MITIGATION")}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>}
                            </Grid>
                        </Grid >
                    </Grid>

                </Grid>
            </VerticalScrollableComponent>
            <Box className="FormFooter" >
                <Grid item xs={4} className="FormHeaderOrFooterDetails">
                    <Button variant="contained" size="small" color="info" onClick={() => setRiskCounter(riskCounter => riskCounter - 1)} disabled={riskCounter <= 0} ><ArrowBackIos /> &nbsp;  Previous </Button>
                </Grid>
                <Grid item xs={4} className="FormHeaderOrFooterDetails">
                    <Button variant="contained" size="small" color="info" onClick={() => setRiskCounter(riskCounter => riskCounter + 1)} disabled={riskCounter >= riskVotings.length - 1} > Next  &nbsp; <ArrowForwardIos /> </Button>
                </Grid>
                <Grid item xs={4} className="FormHeaderOrFooterDetails">
                    <Button variant="contained" size="small" color="secondary" onClick={() => handleConfirmASubmitVotes()} disabled={riskVotings.length === 0 || riskVotings.find(v => v.impact === 0 || v.likelihood === 0 || v.mitigation === 0 /* if one of the I,L,M was not filled */) != null} ><HowToVote /> &nbsp;  Submit Vote </Button>
                </Grid>
            </Box>
        </>
    )
}