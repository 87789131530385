import { Paper, Box, Typography } from "@mui/material";
import { RiskTreatmentDisplay } from "../../helpers/Constants";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { useAppSelector } from "../../store/store";
import { Risk } from "../../helpers/Interfaces";
import { ViewRisk } from "../shared/ViewRisk";
import { ManageRiskActionPlan } from "./ManageRiskActionPlan";

interface Props {
    display: RiskTreatmentDisplay
    risks: Risk[]
    selectedRisk: Risk | null | undefined
    onChangeRiskTreatmentDisplay: (display: RiskTreatmentDisplay, risk?: Risk | null) => void
    onRiskChange: (id: number, risk: Risk) => void
}

export function RiskTreatmentDetails(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value)

    return (
        loggedOnUserProfile &&
        <>
            {props.display === RiskTreatmentDisplay.VIEW_EDIT_ACTION_PLAN && props.selectedRisk &&
                <ManageRiskActionPlan riskToTreat={props.selectedRisk} onRiskChange={props.onRiskChange} />
            }
            {props.display === RiskTreatmentDisplay.VIEW_RISK && props.selectedRisk &&
                <Paper className="Form" >
                    <Box className="FormHeader">
                        <Typography className="FormHeaderOrFooterDetails">Risk Details</Typography>
                    </Box>
                    <VerticalScrollableComponent viewPortHeightOffset={231}>
                        <ViewRisk risk={props.selectedRisk} />
                    </VerticalScrollableComponent>
                    <Box className="FormFooter">
                    </Box>
                </Paper >
            }
        </>
    )
}