import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, RiskAssessment, RiskRatingDto, RiskAssessmentDto, ApprovalRiskRatingDto } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI, showAlertError } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getRiskAssessment = async (assessmentId: number) => {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Risk Assessment"));
        callServerAPI<ServerAPIResponse<RiskAssessment>>('GET', '/api/RiskAssessment/GetRiskAssessment?id=' + assessmentId, null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export const getAllRiskAssessments = async () => {
    return await new Promise<RiskAssessment[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Risk Assessments"));
        callServerAPI<ServerAPIResponse<RiskAssessment[]>>('GET', '/api/RiskAssessment/GetAllRiskAssessments', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export const updateRisksForRiskDrill = async (riskAssessment: RiskAssessment) => {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        if (riskAssessment.drillFilterStartDate == null || riskAssessment.drillFilterEndDate == null) {
            showAlertError('Submitted Date Filters are required')
            return
        }
        MySwal.fire(showBackDrop('Loading risks into Risk Drill Assessment'))
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/UpdateRisksForRiskDrill?id=' + riskAssessment.id, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response.data.data.risks.length + " Risks loaded sucessfully", { variant: 'success' })
                resolve(response.data.data)
            })
    })
}

export const commenceIdentification = async (riskAssessment: RiskAssessment) => {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        if (riskAssessment.identificationEndDate == null || new Date(riskAssessment.identificationEndDate) < new Date()) {
            showAlertError('Please specify a valid Identification End Date for this Risk Assessment before commencing Identification')
            return
        }
        MySwal.fire(showBackDrop('Commencing Risk Identification'))
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/CommenceIdentification?id=' + riskAssessment.id, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar('Risk Assessment Identification Commenced. Responders notified by email', { variant: 'success' })
                resolve(response.data.data)
            })
    })
}


export const notifyIdentificationResponders = async (assessmentId: number) => {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop('Resending Identification notification to responders'))
        callServerAPI<ServerAPIResponse<any>>('PATCH', '/api/RiskAssessment/NotifyIdentificationResponders?id=' + assessmentId, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Risk Assessment Identification Responders notified by email", { variant: 'success' })
                resolve()
            })
    })
}

export const commenceAggregation = async (assessmentId: number) => {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop('Commencing Risk Aggregation'))
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/CommenceAggregation?id=' + assessmentId, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar('Risks Aggregation Commenced', { variant: 'success' })
                resolve(response.data.data)
            })
    })
}

export const commenceVoting = async (riskAssessment: RiskAssessment) => {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        if (riskAssessment.votingEndDate == null || new Date(riskAssessment.votingEndDate) < new Date()) {
            showAlertError('Please specify a valid Voting End Date for this Risk Assessment before commencing Voting')
            return
        }
        MySwal.fire(showBackDrop('Commencing Risk Voting'))
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/CommenceVoting?id=' + riskAssessment.id, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar('Risk Assessment Voting Commenced. Voters notified by email', { variant: 'success' })
                resolve(response.data.data)
            })
    })
}


export const notifyVotingResponders = async (assessmentId: number) => {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop('Resending Voting notification to responders'))
        callServerAPI<ServerAPIResponse<any>>('PATCH', '/api/RiskAssessment/NotifyVotingResponders?id=' + assessmentId, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Risk Assessment Voting Responders notified by email", { variant: 'success' })
                resolve()
            })
    })
}

export const commenceApproval = async (assessmentId: number) => {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop('Commencing Risks Approval'))
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/CommenceApproval?id=' + assessmentId, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar('Risks Approval Commenced', { variant: 'success' })
                resolve(response.data.data)
            })
    })
}

export async function deleteRiskAssessment(assessmentId: number) {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Delete Risk Assessment"));
        await callServerAPI<any>('DELETE', '/api/RiskAssessment/DeleteRiskAssessment?id=' + assessmentId, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response?.data?.message ?? "Risk Assessment Deleted", { variant: 'success' });
                resolve()
            })
    })
}

export async function createRiskAssessment(riskAssessment: RiskAssessment) {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating Risk Assessment"));
        callServerAPI<ServerAPIResponse<RiskAssessment>>('POST', '/api/RiskAssessment/CreateRiskAssessment', mapRiskAssessmentToRiskAssessmentDto(riskAssessment), true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Risk Assessment Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function updateRiskAssessment(riskAssessment: RiskAssessment) {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Risk Assessment"));
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/UpdateRiskAssessment?id=' + riskAssessment.id, mapRiskAssessmentToRiskAssessmentDto(riskAssessment), true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Risk Assessment Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function uploadAggregatedRisksFile(riskAssessment: RiskAssessment, file: File) {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Uploading Aggregated Risks File"));
        const formData = new FormData();
        formData.append("file", file);
        callServerAPI<ServerAPIResponse<RiskAssessment>>('POST', '/api/RiskAssessment/UploadAggregatedRisksFile?id=' + riskAssessment.id, formData, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Aggregated Risks File Uploaded", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function submitConsensusRiskRatings(riskAssessment: RiskAssessment, riskApprovalDtos: ApprovalRiskRatingDto[]) {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop('Submitting Consensus Ratings for ' + riskAssessment.assessmentName))
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/SubmitConsensusRiskRatings?id=' + riskAssessment.id, riskApprovalDtos, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Consensus Ratings Submitted", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function approveRisks(riskAssessment: RiskAssessment, riskApprovalDtos: ApprovalRiskRatingDto[]) {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop('Approving all Risks in ' + riskAssessment.assessmentName))
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/ApproveRisks?id=' + riskAssessment.id, riskApprovalDtos, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("All Risks Approved", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function submitVotes(riskAssessment: RiskAssessment, riskVoteDtos: RiskRatingDto[]) {
    return await new Promise<RiskAssessment>(async (resolve, reject) => {
        MySwal.fire(showBackDrop('Submitting Votes'))
        callServerAPI<ServerAPIResponse<RiskAssessment>>('PATCH', '/api/RiskAssessment/SubmitVotes?id=' + riskAssessment.id, riskVoteDtos, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Votes Submitted", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

function mapRiskAssessmentToRiskAssessmentDto(riskAssessment: RiskAssessment): RiskAssessmentDto {
    return ({
        ...riskAssessment, drillFilterEntitiesImpactedIdsList: riskAssessment.drillFilterEntitiesImpacted.map(e => e.id)
    })
}