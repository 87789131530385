import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, EmailTemplate } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllEmailTemplates = async () => {
    return await new Promise<EmailTemplate[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Email Templates"));
        callServerAPI<ServerAPIResponse<EmailTemplate[]>>('GET', '/api/EmailTemplate/GetAllEmailTemplates', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function updateEmailTemplate(emailTemplate: EmailTemplate) {
    return await new Promise<EmailTemplate>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Email Template"));
        callServerAPI<ServerAPIResponse<EmailTemplate>>('PATCH', '/api/EmailTemplate/UpdateEmailTemplate?templateType=' + emailTemplate.templateType, emailTemplate, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Email Template Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}
