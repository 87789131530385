import { Save } from "@mui/icons-material"
import { Paper, Box, Typography, Grid, TextField, Stack, MenuItem, Autocomplete, FormControlLabel, Checkbox, Divider, Button } from "@mui/material"
import { useState, useEffect } from "react"
import { addRiskAction, updateRiskAction } from "../../api-services/RiskApi"
import { FormEditMode, RiskActionStatus, RiskActionTimeScaleUnit } from "../../helpers/Constants"
import { Risk, RiskAction } from "../../helpers/Interfaces"
import { useAppSelector } from "../../store/store"
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent"

interface Props {
    risk: Risk
    riskActionToEdit?: RiskAction | null
    onRiskActionChange: (id: number, risk: Risk, actionId: number) => void
    formEditMode: FormEditMode
}

export function CreateEditRiskAction(props: Props) {
    // state independent variables
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const emptyRiskAction: RiskAction = {
        id: 0, name: '', description: '', status: RiskActionStatus.DRAFT, timeScale: 0, timeScaleUnit: RiskActionTimeScaleUnit.DAY, otherEntityResponsible: null,
        otherEntityContact: null, otherEntityContactedAndPlanAgreed: false, rejectionComments: null, mitigationImplemented: null, implementationDate: null,
        submittedDate: undefined, submittedBy: undefined, approvedDate: undefined, approvedBy: undefined, rejectedDate: undefined, rejectedBy: undefined,
        implementationSubmittedDate: undefined, implementationSubmittedBy: undefined, implementationApprovedDate: undefined, implementationApprovedBy: undefined,
        implementationRejectedDate: undefined, implementationRejectedBy: undefined
    }
    //states
    const [riskAction, setRiskAction] = useState<RiskAction>(props.riskActionToEdit ?? emptyRiskAction)


    // #region effects

    useEffect(() => {
        if (props.formEditMode !== FormEditMode.CREATE && props.riskActionToEdit != null)
            setRiskAction(props.riskActionToEdit);
    }, [props.riskActionToEdit, props.formEditMode])

    // #endregion effects

    function handleAddAction() {
        addRiskAction(props.risk, riskAction).then(result => props.onRiskActionChange(result.id, result, result.riskActions[result.riskActions.length - 1].id))
    }

    function handleUpdateAction() {
        const actionId = riskAction.id
        updateRiskAction(props.risk, riskAction).then(result => props.onRiskActionChange(result.id, result, actionId))
    }

    return (
        <Paper className="Form">
            <Box className="FormHeader"><Typography className="FormHeaderOrFooterDetails">  {props.formEditMode === FormEditMode.EDIT ? 'Editing Action: ' + riskAction.name : 'Creating New Action'}</Typography></Box>
            <VerticalScrollableComponent viewPortHeightOffset={231}>
                <Grid id="RiskActionGridViewContainer" spacing={3} container style={{ padding: 10 }} >
                    <Grid item xs={12}>
                        <Typography color="textSecondary" > Risk Name</Typography>
                        <Divider />
                        <Typography color="textPrimary" > {props.risk.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Action Name </Typography>
                        <TextField fullWidth required name="name" type="text" variant="outlined" size="small" value={riskAction.name ?? ''}
                            onChange={(event) => setRiskAction(riskAction => ({ ...riskAction, name: event.target.value }))} />
                    </Grid>
                    <Grid item xs={6} sm={4} >
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Timescale</Typography>
                        <Stack direction='row'>
                            <TextField required name="timeScale" type="number" variant="outlined" size="small"
                                value={riskAction.timeScale ?? 0} onChange={(event) => setRiskAction(riskAction => ({ ...riskAction, timeScale: Number(event.target.value) }))} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            &nbsp;<TextField name="timeScaleUnit" select variant="outlined" size="small" style={{ width: 200 }}
                                value={riskAction.timeScaleUnit} onChange={(event) => setRiskAction(riskAction => ({ ...riskAction, timeScaleUnit: event.target.value }))}>
                                <MenuItem key={1} value={'Day'}>Day</MenuItem >
                                <MenuItem key={2} value={'Week'}>Week</MenuItem >
                                <MenuItem key={3} value={'Month'}>Month</MenuItem >
                            </TextField>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Action Description </Typography>
                        <TextField fullWidth required name="description" type="text" variant="outlined" multiline size="small" minRows={3}
                            value={riskAction.description ?? ''} onChange={(event) => setRiskAction(riskAction => ({ ...riskAction, description: event.target.value }))} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" > Entity Responsible </Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="otherEntityResponsible" options={riskRelatedTables.entities.filter(e => !e.disabled)} getOptionLabel={(row) => row.name}
                            value={riskAction.otherEntityResponsible} isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, value) => setRiskAction(riskAction => ({ ...riskAction, otherEntityResponsible: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" />)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" > Entity Contact </Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="otherEntityContact" options={riskRelatedTables.cmdbUsers.map(u => u.email)}
                            value={riskAction.otherEntityContact}
                            onChange={(event, value) => setRiskAction(riskAction => ({ ...riskAction, otherEntityContact: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" > Action Responsibility Declaration</Typography>
                        <FormControlLabel control={<Checkbox classes={{ checked: "CheckBoxChecked" }} name="otherEntityContactedAndPlanAgreed"
                            checked={riskAction.otherEntityContactedAndPlanAgreed ?? false}
                            onChange={(event, checked) => setRiskAction(riskAction => ({ ...riskAction, otherEntityContactedAndPlanAgreed: checked }))} />}
                            label="I confirm that the Entity has been contacted and accepted responsibility for the action" />
                    </Grid>
                    {riskAction.rejectionComments && <Grid item xs={12} >
                        <Typography color="textSecondary"> Sponsor Comments </Typography>
                        <Divider />
                        <Typography color="textPrimary" >{riskAction.rejectionComments}</Typography>
                    </Grid>}
                    {props.risk.existingMitigations &&
                        <Grid item xs={12}>
                            <Typography color="textSecondary" > Existing Mitigations</Typography>
                            <Divider />
                            <Typography color="textPrimary" > {props.risk.existingMitigations}</Typography>
                        </Grid>}
                    {props.risk.suggestedMitigations &&
                        <Grid item xs={12}>
                            <Typography color="textSecondary" > Suggested Mitigations</Typography>
                            <Divider />
                            <Typography color="textPrimary" > {props.risk.suggestedMitigations}</Typography>
                        </Grid>}
                </Grid>
            </VerticalScrollableComponent>
            <Box className="FormFooter">
                {props.formEditMode === FormEditMode.EDIT && <Grid item xs={12} className="FormHeaderOrFooterDetails">
                    <Button disabled={!(riskAction.name && riskAction.description && riskAction.timeScale)} variant="contained" size="small" color="secondary" onClick={() => handleUpdateAction()} ><Save /> &nbsp; Update Action </Button>
                </Grid>}
                {props.formEditMode === FormEditMode.CREATE && <Grid item xs={12} className="FormHeaderOrFooterDetails">
                    <Button disabled={!(riskAction.name && riskAction.description && riskAction.timeScale)} variant="contained" size="small" color="secondary" onClick={() => handleAddAction()} ><Save /> &nbsp; Add Action </Button>
                </Grid>}
            </Box>
        </Paper>
    )
}