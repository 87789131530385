
interface VerticalScrollableComponentProps {
    children: React.ReactNode
    viewPortHeightOffset: number
}

export function VerticalScrollableComponent({ children, viewPortHeightOffset }: VerticalScrollableComponentProps) {

    return (
        <div style={{ width: '100%', overflowY: 'auto', height: `calc(100vh - ${viewPortHeightOffset}px)` }}>
            {children}
        </div>
    )
}