import { useState } from "react";
import { RiskAssessment } from "../helpers/Interfaces";
import { RiskAssessmentDisplay, FormEditMode, DisplayRefreshTrigger } from "../helpers/Constants";
import { showConfirmBox } from "../helpers/Utils";
import { commenceAggregation, commenceApproval, commenceIdentification, commenceVoting, deleteRiskAssessment, notifyIdentificationResponders, notifyVotingResponders, updateRisksForRiskDrill } from "../api-services/RiskAssessmentApi";
import { HorizontalSplitPane } from "../layouts/HorizontalSplitPane";
import { RiskAssessmentWidget } from "../components/risk-assessment/RiskAssessmentWidget";
import { RiskAssessmentDetails } from "../components/risk-assessment/RiskAssessmentDetails";
import { useDisplay, useRiskAssessments, useSelectedRiskAssessment } from "../helpers/GlobalHooks";

export function RiskAssessmentEditor() {
    const [riskAssessments, setRiskAssessments, refreshRiskAssessments] = useRiskAssessments()
    const [assessmentTypeFilter, setAssessmentTypeFilter] = useState('All')
    const [displayRefreshTrigger, setDisplayRefreshTrigger] = useState<DisplayRefreshTrigger | null>(null)
    const filteredRiskAssessments = riskAssessments.filter(row => assessmentTypeFilter === "All" || row.assessmentType === Number(assessmentTypeFilter))
    const { selectedRiskAssessment, setSelectedRiskAssessment } = useSelectedRiskAssessment(filteredRiskAssessments, null, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected)
    const { display, setDisplay } = useDisplay(filteredRiskAssessments, RiskAssessmentDisplay.VIEW_RISKASSESSMENT, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected, RiskAssessmentDisplay.CREATE_RISKASSESSMENT, RiskAssessmentDisplay.VIEW_RISKASSESSMENT)


    function handleLoadRisksForRiskDrill(riskAssessment: RiskAssessment) {
        updateRisksForRiskDrill(riskAssessment).then(result => {
            setRiskAssessments(riskAssessments => riskAssessments.map(inst => inst.id === result.id ? result : inst))
            setSelectedRiskAssessment(result)
            setDisplay(RiskAssessmentDisplay.VIEW_RISKS_GRIDVIEW)
        })
    }

    function handleCommenceIdentification(riskAssessment: RiskAssessment) {
        commenceIdentification(riskAssessment).then(result => {
            setRiskAssessments(riskAssessments => riskAssessments.map(inst => inst.id === result.id ? result : inst))
            setSelectedRiskAssessment(result)
            setDisplay(RiskAssessmentDisplay.VIEW_RISKASSESSMENT)
        })
    }

    function handleNotifyIdentificationResponders(riskAssessment: RiskAssessment) {
        notifyIdentificationResponders(riskAssessment.id)
    }

    function handleCommenceAggregation(riskAssessment: RiskAssessment) {
        commenceAggregation(riskAssessment.id).then(result => {
            setRiskAssessments(riskAssessments => riskAssessments.map(inst => inst.id === result.id ? result : inst))
            setSelectedRiskAssessment(result)
            setDisplay(RiskAssessmentDisplay.VIEW_RISKS_GRIDVIEW)
        })
    }

    function handleCommenceVoting(riskAssessment: RiskAssessment) {
        if (riskAssessment.risks.filter(e => e.severity >= riskAssessment.minSeverityForVoting).length === 0) {
            showConfirmBox("Risk Assessment must have at least one risk with severity >= " + riskAssessment.minSeverityForVoting + " to commence Voting.<br/><br/>Do you want to skip Voting and Commence Approval?", "Confirm Commence Risk Assessment Approval")
                .then((confirmResult) => {
                    if (confirmResult.isConfirmed)
                        handleCommenceApproval(riskAssessment)
                })
        }
        else {
            commenceVoting(riskAssessment).then(result => {
                setRiskAssessments(riskAssessments => riskAssessments.map(inst => inst.id === result.id ? result : inst))
                setSelectedRiskAssessment(result)
                setDisplay(RiskAssessmentDisplay.VIEW_RISKASSESSMENT)
            })
        }
    }

    function handleNotifyVotingResponders(riskAssessment: RiskAssessment) {
        notifyVotingResponders(riskAssessment.id)
    }

    function handleCommenceApproval(riskAssessment: RiskAssessment) {
        commenceApproval(riskAssessment.id).then(result => {
            setRiskAssessments(riskAssessments => riskAssessments.map(inst => inst.id === result.id ? result : inst))
            setSelectedRiskAssessment(result)
            setDisplay(RiskAssessmentDisplay.VIEW_RISKASSESSMENT)
        })
    }

    function handleConfirmDeleteRiskAssessment(riskAssessmentIdToDelete: number) {
        showConfirmBox("Are you sure you want to delete this Risk Assessment? \n This action cannot be undone.", "Confirm Delete")
            .then((result) => {
                if (result.isConfirmed)
                    deleteRiskAssessment(riskAssessmentIdToDelete).then(() => {
                        setRiskAssessments(riskAssessments => riskAssessments.filter(inst => inst.id !== riskAssessmentIdToDelete))
                        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered) //needed to refresh and select first risk in list
                    });
            })
    }

    function handleRiskAssessmentChange(id: number, riskAssessment: RiskAssessment, formEditMode: FormEditMode, display: RiskAssessmentDisplay) {
        if (formEditMode === FormEditMode.EDIT) {
            setRiskAssessments(riskAssessments => riskAssessments.map(inst => inst.id === id ? riskAssessment : inst))
            setSelectedRiskAssessment(riskAssessment)
            setDisplay(display)
        }
        else if (formEditMode === FormEditMode.CREATE) {
            setRiskAssessments(riskAssessments => [...riskAssessments, riskAssessment])
            setSelectedRiskAssessment(riskAssessment)
            setDisplay(display)
        }
    }

    function handleChangeRiskAssessmentDisplay(display: RiskAssessmentDisplay, riskAssessment?: RiskAssessment | null) {
        if (riskAssessment != null) setSelectedRiskAssessment(riskAssessment)
        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
        setDisplay(display);
    }

    return (
        <HorizontalSplitPane leftWidth={299} rightWidthDynamic
            left={
                <RiskAssessmentWidget riskAssessments={filteredRiskAssessments} selectedRiskAssessment={selectedRiskAssessment}
                    onChangeRiskAssessmentDisplay={(display, riskAssessment) => handleChangeRiskAssessmentDisplay(display, riskAssessment)}
                    onClickDeleteRiskAssessment={(id) => handleConfirmDeleteRiskAssessment(id)}
                    onClickRefreshRiskAssessments={() => refreshRiskAssessments()}
                    assessmentTypeFilter={assessmentTypeFilter}
                    onAssessmentTypeFilterChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setAssessmentTypeFilter(e) }}
                />}
            right={
                <RiskAssessmentDetails selectedRiskAssessment={selectedRiskAssessment} onRiskAssessmentChange={handleRiskAssessmentChange} riskAssessmentDisplay={display}
                    onChangeRiskAssessmentDisplay={(display, riskAssessment) => handleChangeRiskAssessmentDisplay(display, riskAssessment)}
                    onClickCommenceIdentification={(riskAssessment) => handleCommenceIdentification(riskAssessment)}
                    onClickCommenceAggregation={(riskAssessment) => handleCommenceAggregation(riskAssessment)}
                    onClickCommenceVoting={(riskAssessment) => handleCommenceVoting(riskAssessment)}
                    onClickCommenceApproval={(riskAssessment) => handleCommenceApproval(riskAssessment)}
                    onClickNotifyIdentifcationResponders={(riskAssessment) => handleNotifyIdentificationResponders(riskAssessment)}
                    onClickNotifyVotingResponders={(riskAssessment) => handleNotifyVotingResponders(riskAssessment)}
                    onClickLoadRisksForDrill={(riskAssessment) => handleLoadRisksForRiskDrill(riskAssessment)}
                />}
        />
    )
}
