import { ImpactLikelihoodColor, MitigationColor, SeverityColor } from "../../helpers/Constants";
import { Grid, Typography, Divider } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Risk } from "../../helpers/Interfaces";


interface Props {
    risk: Risk
    totalFrequencyOfMentions: number | null
}

export function ViewRiskCard({ risk, totalFrequencyOfMentions }: Props) {
    return (
        <Grid id="ViewRiskCardDetails" container className="FormDetails" >
            <Grid item xs={1} ></Grid>
            <Grid item xs={10} style={{ padding: 0, border: '1px solid #555555' }}>
                <Grid item xs={12} style={{ marginTop: 0, backgroundColor: '#0288D1', padding: 5 }} >
                    <Typography variant='h6' color="white" >{risk.name}</Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: 5 }}>
                    <Table style={{ width: '100%' }} size="small"   >
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" style={{ verticalAlign: 'middle', border: 0, width: '50%', margin: 0, padding: '5px 5px 5px 0px' }}>
                                    <Typography variant='h5' color="textPrimary" >Risk Category: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b> {risk.category?.name} </b> </Typography>
                                    <br />
                                    <Typography variant='h5' color="textPrimary" >Objective Impacted: &nbsp;&nbsp;<b> {risk.businessObjectiveImpacted?.name} </b> </Typography>
                                </TableCell>
                                <TableCell style={{ verticalAlign: 'middle', border: 0, width: '50%', margin: 0, padding: '5px 5px 5px 5px' }}>
                                    <div style={{ margin: 'auto', padding: 10, textAlign: 'center', borderRadius: 50, height: '80px', width: '80px', color: '#FFFFFF', backgroundColor: SeverityColor[risk.severity] }}>
                                        <Typography variant='h6' textAlign="center" >Severity </Typography>
                                        <Typography variant='h4' fontWeight={900} textAlign="center" > {risk.severity}</Typography>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} style={{ padding: 5 }}>
                    <Table style={{ width: '100%' }} size="small"   >
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" style={{ verticalAlign: 'middle', border: 0, width: '50%', margin: 0, padding: '5px 5px 5px 0px' }}>
                                    <Typography variant='h3' fontWeight={900} color="textPrimary" >
                                        {totalFrequencyOfMentions == null ? 'N/A' : (Math.round(risk.frequencyOfMention / totalFrequencyOfMentions * 100) + '%')}
                                    </Typography>
                                    <Typography variant='h5' color="textPrimary" >Frequency of Mention</Typography>
                                </TableCell>
                                <TableCell align="center" style={{ verticalAlign: 'middle', border: 0, width: '50%', margin: 0, padding: '5px 5px 5px 5px' }}>
                                    <Table style={{ width: '100%' }} size="small" >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: '1px solid #555555', margin: 0, padding: 0 }}><Typography variant='h6' fontWeight={900} style={{ paddingLeft: 5 }}> Impact:</Typography></TableCell>
                                                <TableCell align="right" style={{ width: 60, border: '1px solid #555555', margin: 0, padding: 0 }}>   <Typography variant='h6' fontWeight={900} alignContent="center" align="center" style={{ padding: 1, width: '60px', color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[risk.impact] }}>{risk.impact}</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: '1px solid #555555', margin: 0, padding: 0 }}><Typography variant='h6' fontWeight={900} style={{ paddingLeft: 5 }}>Likelihood:</Typography></TableCell>
                                                <TableCell align="right" style={{ width: 60, border: '1px solid #555555', margin: 0, padding: 0 }}>  <Typography variant='h6' fontWeight={900} alignContent="center" align="center" style={{ padding: 1, width: '60px', color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[risk.likelihood] }}>{risk.likelihood}</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" style={{ border: '1px solid #555555', margin: 0, padding: 0 }}><Typography variant='h6' fontWeight={900} style={{ paddingLeft: 5 }}>Mitigation:</Typography></TableCell>
                                                <TableCell align="right" style={{ width: 60, border: '1px solid #555555', margin: 0, padding: 0 }}>   <Typography variant='h6' fontWeight={900} alignContent="center" align="center" style={{ padding: 1, width: '60px', color: '#FFFFFF', backgroundColor: MitigationColor[risk.mitigation] }}>{risk.mitigation}</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </Grid>
                <Grid item xs={12} style={{ padding: 5 }}>
                    <Table style={{ width: '100%' }} size="small"   >
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" style={{ verticalAlign: 'top', border: 0, width: '50%', margin: 0, padding: '5px 5px 5px 0px' }}>
                                    <Typography fontWeight={900} color="textPrimary" >Risk Description / Impact:</Typography>
                                    <Divider />
                                    <Typography color="textPrimary" >{risk.description ?? ''} </Typography>
                                </TableCell>
                                <TableCell align="left" style={{ verticalAlign: 'top', border: 0, width: '50%', margin: 0, padding: '5px 5px 5px 5px' }}>
                                    <Typography fontWeight={900} color="textPrimary" >Existing Mitigations:</Typography>
                                    <Divider />
                                    <Typography color="textPrimary" >{risk.existingMitigations ?? ''} </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid item xs={1} ></Grid>
        </Grid >
    )

}
