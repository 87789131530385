import { ThumbUp, ThumbDown } from "@mui/icons-material";
import { Paper, Box, Typography, Grid, Divider, Button } from "@mui/material";
import { RiskStatus, RiskActionStatus } from "../../helpers/Constants";
import { getRiskActionStatusString, formatShortDateString, getUserFullName, showInputBox, showConfirmBox, truncateTextToWhiteSpace } from "../../helpers/Utils";
import { Risk, RiskAction } from "../../helpers/Interfaces";
import { useAppSelector } from "../../store/store";
import { approveRiskAction, rejectRiskAction } from "../../api-services/RiskApi";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";

interface Props {
    risk: Risk
    riskAction: RiskAction
    onRiskActionChange: (id: number, risk: Risk, actionId: number) => void
}

export function ViewRiskAction(props: Props) {

    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    // state and dependent variables
    const isSponsor = loggedOnUserProfile == null ? false : props.risk?.sponsor?.toUpperCase() === loggedOnUserProfile.email.toUpperCase()


    function handleApproveAction() {
        const actionId = props.riskAction.id
        showConfirmBox("Are you sure you want to approve this action plan item?", "Confirm Action Plan Item Approval")
            .then((result) => {
                if (result.isConfirmed)
                    approveRiskAction(props.risk, props.riskAction).then(result => {
                        props.onRiskActionChange(result.id, result, actionId);
                    })
            })
    }



    function handleConfirmRejectAction() {
        const actionId = props.riskAction.id
        showInputBox("Comments are required to reject this action plan item", "Action Rejection Comments", "please enter comments", "Reject")
            .then((result) => {
                if (result.value != null && result.value !== '')
                    rejectRiskAction(props.risk, props.riskAction, result.value).then(result => {
                        props.onRiskActionChange(result.id, result, actionId);
                    })
            })
    }


    return (
        <Paper className="Form">
            <Box className="FormHeader"><Typography className="FormHeaderOrFooterDetails">  {'Viewing details of Action: ' + truncateTextToWhiteSpace(props.riskAction.name, 90)} </Typography></Box>
            <VerticalScrollableComponent viewPortHeightOffset={231}>
                <Grid id="RiskActionGridViewContainer" spacing={3} container style={{ padding: 10 }} >
                    <Grid item xs={12}>
                        <Typography color="textSecondary" > Risk Name</Typography>
                        <Divider />
                        <Typography color="textPrimary" > {props.risk.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" >Action Name </Typography>
                        <Divider />
                        <Typography color="textPrimary" >{props.riskAction.name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <Typography color="textSecondary" >Timescale</Typography>
                        <Divider />
                        <Typography color="textPrimary" > {props.riskAction.timeScale} &nbsp;{props.riskAction.timeScale > 1 ? props.riskAction.timeScaleUnit + 's' : props.riskAction.timeScaleUnit}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <Typography color="textSecondary" >Status</Typography>
                        <Divider />
                        <Typography color="textPrimary" >{getRiskActionStatusString(props.riskAction.status, props.risk)}</Typography>
                    </Grid>
                    {props.riskAction.mitigationImplemented != null && <Grid item xs={12}>
                        <Typography color="textSecondary" >Details of Mitigation/Control Implemented</Typography>
                        <Divider />
                        <Typography color="textPrimary" >{props.riskAction.mitigationImplemented}</Typography>
                    </Grid>}
                    {props.riskAction.implementationDate != null && <Grid item xs={6}>
                        <Typography color="textSecondary" >Date Implemented</Typography>
                        <Divider />
                        <Typography color="textPrimary" >{formatShortDateString(props.riskAction.implementationDate, false)}</Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <Typography color="textSecondary" >Action Description</Typography>
                        <Divider />
                        <Typography color="textPrimary" >{props.riskAction.description}</Typography>
                    </Grid>
                    {props.riskAction.otherEntityResponsible &&
                        <Grid item xs={12} sm={6}>
                            <Typography color="textSecondary" > Entity Responsible </Typography>
                            <Divider />
                            <Typography color="textPrimary" >{props.riskAction.otherEntityResponsible?.name}</Typography>
                        </Grid>}
                    {props.riskAction.otherEntityContact &&
                        <Grid item xs={12} sm={6}>
                            <Typography color="textSecondary" > Entity Contact </Typography>
                            <Divider />
                            <Typography color="textPrimary" title={props.riskAction.otherEntityContact}>{getUserFullName(props.riskAction.otherEntityContact, riskRelatedTables.cmdbUsers)}</Typography>
                        </Grid>}
                    {props.riskAction.otherEntityContactedAndPlanAgreed &&
                        <Grid item xs={12}>
                            <Typography color="textSecondary" > Action Responsibility Declaration</Typography>
                            <Divider />
                            <Typography color="textPrimary" > Risk Champion confirms that the Entity has been contacted and has accepted responsibility for the action</Typography>
                        </Grid>}
                    <Grid item xs={12} >
                        <Typography color="textSecondary"> Sponsor Comments </Typography>
                        <Divider /><Typography color="textPrimary" >{props.riskAction.rejectionComments}</Typography>
                    </Grid>
                    {props.risk.existingMitigations &&
                        <Grid item xs={12}>
                            <Typography color="textSecondary" > Existing Mitigations</Typography>
                            <Divider />
                            <Typography color="textPrimary" > {props.risk.existingMitigations}</Typography>
                        </Grid>}
                    {props.risk.suggestedMitigations &&
                        <Grid item xs={12}>
                            <Typography color="textSecondary" > Suggested Mitigations</Typography>
                            <Divider />
                            <Typography color="textPrimary" > {props.risk.suggestedMitigations}</Typography>
                        </Grid>}
                </Grid>
            </VerticalScrollableComponent>

            <Box className="FormFooter">
                {isSponsor && props.risk.status === RiskStatus.ACTION_PLAN_SUBMITTED && props.riskAction.status === RiskActionStatus.SUBMITTED &&
                    <>
                        <Grid item xs={6} className="FormHeaderOrFooterDetails" alignItems='center'>
                            <Button onClick={() => handleApproveAction()} variant="contained" size="small" color="success"  ><ThumbUp /> &nbsp; Approve </Button>
                        </Grid>
                        <Grid item xs={6} className="FormHeaderOrFooterDetails" alignItems='center'>
                            <Button onClick={() => handleConfirmRejectAction()} variant="contained" size="small" color="error"><ThumbDown /> &nbsp; Reject </Button>
                        </Grid>
                    </>
                }
            </Box>
        </Paper>
    )
}