import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, UserProfile } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllUserProfiles = async () => {
    return await new Promise<UserProfile[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading User Profiles"));
        callServerAPI<ServerAPIResponse<UserProfile[]>>('GET', '/api/UserProfile/GetAllUserProfiles', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function deleteUserProfile(id: number) {
    return await new Promise<boolean>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Delete User Profile"));
        await callServerAPI<ServerAPIResponse<boolean>>('DELETE', '/api/UserProfile/DeleteUserProfile?id=' + id, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response.data.data ? "User Profile Disabled" : "User Profile Deleted", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function createUserProfile(userProfile: UserProfile) {
    return await new Promise<UserProfile>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new User Profile"));
        callServerAPI<ServerAPIResponse<UserProfile>>('POST', '/api/UserProfile/CreateUserProfile', userProfile, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("User Profile Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function updateUserProfile(userProfile: UserProfile) {
    return await new Promise<UserProfile>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating User Profile"));
        callServerAPI<ServerAPIResponse<UserProfile>>('PATCH', '/api/UserProfile/UpdateUserProfile?id=' + userProfile.id, userProfile, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("User Profile Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}
