import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RiskRelatedTables } from '../helpers/Interfaces';

interface RiskRelatedTablesState {
  value: RiskRelatedTables
}

export const riskRelatedTables = createSlice({
  name: 'riskRelatedTables',
  initialState: {
    value: { businessObjectives: [], categories: [], entities: [], impactAreas: [], ratingDescriptions: [], cmdbUsers: [], sponsorEmails: [] },
  } as RiskRelatedTablesState,
  reducers: {
    setRiskRelatedTables: (state, action: PayloadAction<RiskRelatedTables>) => {
      state.value = action.payload
    },
  },
})

export const { setRiskRelatedTables } = riskRelatedTables.actions;
export default riskRelatedTables.reducer;