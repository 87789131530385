import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConfig } from '../helpers/Interfaces';

interface AppConfigState {
  value: AppConfig
}

const defaultAppConfig: AppConfig = {
  SERVER_URL: process.env.REACT_APP_SERVER_URL ?? "",
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT ?? "",
  APP_VERSION: process.env.REACT_APP_VERSION ?? "",
  APP_LAST_UPDATED: Number(process.env.REACT_APP_LAST_UPDATED ?? 0) * 1000,
  APP_PLATFORM: process.env.REACT_APP_PLATFORM ?? "",
  REPORTS_BASE_URL: process.env.REACT_APP_REPORTS_BASE_URL ?? "",
  SUPPORT_CONTACTS: process.env.REACT_APP_SUPPORT_CONTACTS ?? "",
  USERGUIDE_RISKIDENTIFICATION: process.env.REACT_APP_USERGUIDE_RISKIDENTIFICATION ?? "",
  USERGUIDE_RISKIDENTIFICATIONASSESSMENT: process.env.REACT_APP_USERGUIDE_RISKIDENTIFICATIONASSESSMENT ?? "",
  USERGUIDE_ENTITYRISKIDENTIFICATION: process.env.REACT_APP_USERGUIDE_ENTITYRISKIDENTIFICATION ?? "",
}

export const appConfig = createSlice({
  name: 'appConfig',
  initialState: {
    value: defaultAppConfig,
  } as AppConfigState,
  reducers: {
    setAppConfig: (state, action: PayloadAction<AppConfig>) => {
      state.value = action.payload
    },
  },
})

export const { setAppConfig } = appConfig.actions;
export default appConfig.reducer;