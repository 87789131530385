import React, { useState } from "react";
import { AppBarHeader } from "./AppBarHeader";
import { AppBarFooter } from "./AppBarFooter";
import { Divider, Drawer, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import '../App.css'
import { useAppSelector } from "../store/store";
import { MainMenu } from "../helpers/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { checkGlobalMenuEnabled } from "../helpers/Utils";

interface Props {
    children: React.ReactNode
    header: string
    drawerHidden?: boolean
}

export function MainLayout({ children, header, drawerHidden = false }: Props) {
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const navigate = useNavigate()
    const location = useLocation()
    const [drawerOpen, setDrawerOpen] = useState(false);

    const appBarHeaderHeight = 50;
    const appBarFooterHeight = 50;

    const styles: { drawerItem: React.CSSProperties, contentPanel: React.CSSProperties } = {
        contentPanel: {
            width: "100%",
            marginTop: appBarHeaderHeight,
            marginBottom: appBarFooterHeight,
            paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10,
            transition: "2.5s",
            transitionTimingFunction: "ease-in",
            alignItems: "center",
            alignContent: "top",
        },
        drawerItem: {
            height: appBarHeaderHeight,
        }
    }


    function handleToggleDrawer() {
        setDrawerOpen(drawerOpen => !drawerOpen)
    }


    return (
        <>
            <AppBarHeader drawerHidden={drawerHidden} appBarHeight={appBarHeaderHeight} onToggleDrawer={handleToggleDrawer} header={header} />

            <Grid id="ApplicationDetailContainer" container spacing={0}>

                {loggedOnUserProfile && riskRelatedTables &&
                    <Drawer variant="temporary" anchor={"left"} open={drawerOpen} onClose={handleToggleDrawer} >
                        <div style={{ height: appBarHeaderHeight, marginBottom: 1 }}>
                            <IconButton onClick={handleToggleDrawer}> <ChevronLeft color="info" /> </IconButton>
                        </div>
                        <Divider />
                        <List style={{ padding: 0 }}>
                            {MainMenu.filter(a => a.displayinDrawer).sort((a, b) => a.index < b.index ? -1 : 1).map(menuItem =>
                                <ListItemButton key={menuItem.pathName} disabled={!checkGlobalMenuEnabled(menuItem, loggedOnUserProfile, riskRelatedTables.entities)} style={styles.drawerItem} divider={menuItem.hasDivider} classes={{ selected: "SelectedMenu" }}
                                    selected={location.pathname.toLowerCase() === '/' + menuItem.pathName.toLowerCase()} onClick={() => { setDrawerOpen(false); navigate('/' + menuItem.pathName) }}>
                                    <ListItemIcon sx={{ color: location.pathname.toLowerCase() === '/' + menuItem.pathName.toLowerCase() ? "#cc0000" : "#555555" }}> {menuItem.drawerIcon} </ListItemIcon>
                                    <ListItemText primary={menuItem.header} />
                                </ListItemButton>
                            )}
                        </List>
                    </Drawer>
                }

                <Grid id="AppDetailsContainer" item xs={12} style={styles.contentPanel}>
                    {children}
                </Grid>
            </Grid>

            <AppBarFooter appBarHeight={appBarFooterHeight} />
        </>
    )
}