import { AddCircleOutline } from "@mui/icons-material";
import { Paper, Box, Typography, Grid, Button } from "@mui/material";
import { RiskIdentificationDisplay, FormEditMode } from "../../helpers/Constants";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { useAppSelector } from "../../store/store";
import { CreateEditRisk } from "./CreateEditRisk";
import { RiskIdentificationActionButtons } from "./RiskIdentificationActionButtons";
import { ViewRisk } from "../shared/ViewRisk";
import { ViewRiskCard } from "../shared/ViewRiskCard";
import { RiskAssessment, Risk } from "../../helpers/Interfaces";

interface Props {
    assessmentIdForRiskIdentification: number | null
    riskAssessmentForRiskIdentification: RiskAssessment | null
    display: RiskIdentificationDisplay
    risks: Risk[]
    selectedRisk: Risk | null | undefined
    displayRiskAssessmentWelcomeMessage: boolean
    onClickDeleteRisk: (riskId: number) => void
    onClickNotifyEntityHead: (riskId: number) => void
    onChangeRiskIdentificationDisplay: (display: RiskIdentificationDisplay, risk?: Risk | null) => void
    onClickBeginRiskAssessmentIdentification: () => void
    onRiskChange: (id: number, risk: Risk, formEditMode: FormEditMode, newDisplay: RiskIdentificationDisplay) => void
}

export function RiskIdentificationDetails(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);


    return (
        loggedOnUserProfile &&
        <>
            {props.display === RiskIdentificationDisplay.CREATE_RISK &&
                <CreateEditRisk assessmentIdForRiskIdentification={props.assessmentIdForRiskIdentification} riskAssessmentForRiskIdentification={props.riskAssessmentForRiskIdentification} formEditMode={FormEditMode.CREATE} onRiskChange={props.onRiskChange} existingRiskNamesForSuggestion={[...new Set(props.risks.map(r => r.name))]} displayRiskAssessmentWelcomeMessage={props.displayRiskAssessmentWelcomeMessage} onClickBeginRiskAssessmentIdentification={props.onClickBeginRiskAssessmentIdentification} riskToEdit={null} />}

            {props.display === RiskIdentificationDisplay.EDIT_RISK && props.selectedRisk &&
                <CreateEditRisk assessmentIdForRiskIdentification={props.assessmentIdForRiskIdentification} riskAssessmentForRiskIdentification={props.riskAssessmentForRiskIdentification} formEditMode={FormEditMode.EDIT} riskToEdit={props.selectedRisk} onRiskChange={props.onRiskChange} existingRiskNamesForSuggestion={[...new Set(props.risks.map(r => r.name))]} displayRiskAssessmentWelcomeMessage={false} onClickBeginRiskAssessmentIdentification={props.onClickBeginRiskAssessmentIdentification} />}

            {props.display === RiskIdentificationDisplay.VIEW_RISK && props.selectedRisk &&
                <Paper className="Form" >
                    <Box className={props.riskAssessmentForRiskIdentification == null ? "FormHeader" : "BoldFormHeader"}>
                        <Typography className={props.riskAssessmentForRiskIdentification == null ? "FormHeaderOrFooterDetails" : "BoldFormHeaderDetails"}>
                            {props.riskAssessmentForRiskIdentification == null ? 'Risk Details' : 'Viewing Identified Risks for ' + props.riskAssessmentForRiskIdentification.assessmentName}
                        </Typography>
                    </Box>
                    <VerticalScrollableComponent viewPortHeightOffset={props.assessmentIdForRiskIdentification == null ? 231 : 231 - 31}>
                        <ViewRisk risk={props.selectedRisk} />
                    </VerticalScrollableComponent>
                    <Box className="FormFooter">
                        <Grid item xs={12} className="FormHeaderOrFooterDetails">
                            <Button variant="contained" size="small" color="secondary" onClick={() => props.onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.CREATE_RISK)} title="Create new Risk" disabled={props.riskAssessmentForRiskIdentification != null && (props.riskAssessmentForRiskIdentification.identificationStartDate == null || props.riskAssessmentForRiskIdentification.votingStartDate != null)}><AddCircleOutline />&nbsp;&nbsp;Add New Risk </Button>&nbsp;&nbsp;
                            <RiskIdentificationActionButtons onClickDeleteRisk={props.onClickDeleteRisk} onClickNotifyEntityHead={props.onClickNotifyEntityHead} riskIdentificationDisplay={props.display} risk={props.selectedRisk} onChangeRiskIdentificationDisplay={props.onChangeRiskIdentificationDisplay} loggedOnUserProfile={loggedOnUserProfile} riskAssessmentForRiskIdentification={props.riskAssessmentForRiskIdentification} />
                        </Grid>
                    </Box>
                </Paper >}

            {props.display === RiskIdentificationDisplay.PREVIEW_RISK && props.selectedRisk &&
                <Paper className="Form" >
                    <Box className={props.riskAssessmentForRiskIdentification == null ? "FormHeader" : "BoldFormHeader"}>
                        <Typography className={props.riskAssessmentForRiskIdentification == null ? "FormHeaderOrFooterDetails" : "BoldFormHeaderDetails"}>
                            {props.riskAssessmentForRiskIdentification == null ? 'Risk Card' : 'Viewing Identified Risks for ' + props.riskAssessmentForRiskIdentification.assessmentName}
                        </Typography>
                    </Box>
                    <VerticalScrollableComponent viewPortHeightOffset={props.assessmentIdForRiskIdentification == null ? 231 : 231 - 31}>
                        <ViewRiskCard risk={props.selectedRisk} totalFrequencyOfMentions={props.selectedRisk.sourceAssessment == null ? null : (props.risks.filter(r => r.sourceAssessment != null && r.sourceAssessment.id === props.selectedRisk!.sourceAssessment?.id).reduce((total, r) => total + r.frequencyOfMention, 0))} />
                    </VerticalScrollableComponent>
                    <Box className="FormFooter">
                        <Grid item xs={12} className="FormHeaderOrFooterDetails">
                            <Button variant="contained" size="small" color="secondary" onClick={() => props.onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.CREATE_RISK)} title="Create new Risk" disabled={props.riskAssessmentForRiskIdentification != null && (props.riskAssessmentForRiskIdentification.identificationStartDate == null || props.riskAssessmentForRiskIdentification.votingStartDate != null)}><AddCircleOutline />&nbsp;&nbsp;Add New Risk </Button>&nbsp;&nbsp;
                            <RiskIdentificationActionButtons onClickDeleteRisk={props.onClickDeleteRisk} onClickNotifyEntityHead={props.onClickNotifyEntityHead} riskIdentificationDisplay={props.display} risk={props.selectedRisk} onChangeRiskIdentificationDisplay={props.onChangeRiskIdentificationDisplay} loggedOnUserProfile={loggedOnUserProfile} riskAssessmentForRiskIdentification={props.riskAssessmentForRiskIdentification} />
                        </Grid>
                    </Box>
                </Paper >}
        </>
    )
}