import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, Category } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllCategories = async () => {
    return await new Promise<Category[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Categories"));
        callServerAPI<ServerAPIResponse<Category[]>>('GET', '/api/Category/GetAllCategories', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function deleteCategory(id: number) {
    return await new Promise<boolean>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Delete Category"));
        await callServerAPI<ServerAPIResponse<boolean>>('DELETE', '/api/Category/DeleteCategory?id=' + id, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response.data.data ? "Category Disabled" : "Category Deleted", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function createCategory(category: Category) {
    return await new Promise<Category>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new Category"));
        callServerAPI<ServerAPIResponse<Category>>('POST', '/api/Category/CreateCategory', category, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Category Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function updateCategory(category: Category) {
    return await new Promise<Category>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Category"));
        callServerAPI<ServerAPIResponse<Category>>('PATCH', '/api/Category/UpdateCategory?id=' + category.id, category, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Category Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}
