import { Card, CardActionArea, CardContent, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { MainMenu, iconColorsArray } from "../helpers/Constants";
import { checkGlobalMenuEnabled } from "../helpers/Utils";
import { useAppSelector } from "../store/store";
import { useNavigate } from "react-router-dom";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";

export function Home() {

    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const navigate = useNavigate()

    return (
        <Paper className="Form" style={{ paddingTop: 10, paddingBottom: 5 }} >
            {loggedOnUserProfile && riskRelatedTables.entities.length > 0 &&
                <Stack direction="column" spacing={0} style={{ width: '100%', }}  >
                    <div key={1} style={{ display: "flex", padding: 5, alignItems: "center", justifyContent: "center" }}>
                        <img style={{ width: 350 }} src='../../assets/images/logo_large_center.png' alt="logo_large_center.png" />
                    </div>
                    <VerticalScrollableComponent viewPortHeightOffset={355}>
                        <Grid container spacing={2} style={{ padding: 10 }}>
                            {MainMenu.filter(a => a.displayinMenu).sort((a, b) => a.index < b.index ? -1 : 1).map((a, index) => {
                                var enabled = checkGlobalMenuEnabled(a, loggedOnUserProfile, riskRelatedTables.entities);
                                return (
                                    <React.Fragment key={a.index}>
                                        {(enabled || !a.isSetupMenu) &&
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}   >
                                                <Card style={{ cursor: enabled ? "pointer" : "not-allowed", borderRadius: 0 }} elevation={0} >
                                                    <CardActionArea disabled={!enabled} className="HomePageCard" onClick={() => navigate('/' + a.pathName)} >
                                                        <CardContent>
                                                            <Typography align="center" style={{ color: enabled ? iconColorsArray[index] : 'grey' }}> {a.homePageIcon} </Typography>
                                                            <Typography align="center" sx={{ fontSize: 14 }} color="textPrimary" > {a.header} </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </Grid>
                    </VerticalScrollableComponent>
                </Stack>
            }
        </Paper>
    );
}