import { Typography } from "@mui/material";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { RiskAssessment } from "../../helpers/Interfaces";

interface Props {
    riskAssessmentForRiskIdentification: RiskAssessment
}

export function NoOngoingRiskIdentification({ riskAssessmentForRiskIdentification }: Props) {
    return (
        <VerticalScrollableComponent viewPortHeightOffset={160}>
            <Typography color="secondary" paddingTop={5} paddingBottom={5} align="center" width="100%" fontSize={16}>
                No Risk identification Exercise is currently ongoing for the {riskAssessmentForRiskIdentification?.assessmentName}
            </Typography>
        </VerticalScrollableComponent>
    )
}