import { Refresh, Sort, ArrowRight, Class, AssignmentInd } from "@mui/icons-material";
import { Paper, Table, TableBody, TableRow, TableCell, ButtonGroup, Button, Menu, MenuItem, List, ListItem, ListItemText, IconButton } from "@mui/material";
import { RiskStatus, RiskSortType, RiskAssignmentDisplay } from "../../helpers/Constants";
import { truncateTextToWhiteSpace } from "../../helpers/Utils";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { useAppSelector } from "../../store/store";
import { useState } from "react";
import { Risk } from "../../helpers/Interfaces";


interface Props {
    risks: Risk[]
    selectedRisk: Risk | null | undefined
    onRiskSortTypeChange: (riskSortType: RiskSortType) => void
    onClickRefreshRisks: () => void
    onChangeRiskAssignmentDisplay: (display: RiskAssignmentDisplay, risk?: Risk | null) => void
}

export function RiskAssignmentWidget(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    //states
    const [showSortMenu, setShowSortMenu] = useState(false)
    const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<Element | null>(null)

    return (loggedOnUserProfile && props.risks &&
        <Paper className="Widget">
            <Table style={{ width: '288px' }}>
                <TableBody>
                    <TableRow >
                        <TableCell align='center' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 10 }}>
                            <ButtonGroup size="small" variant="text" color="primary"  >
                                <Button onClick={props.onClickRefreshRisks} title="Refresh Risks"><Refresh /> </Button>
                                <Button onClick={(event) => { setSortMenuAnchorEl(event.currentTarget); setShowSortMenu(true) }} title="Sort Risks By"><Sort /> </Button>
                            </ButtonGroup>
                            <Menu anchorEl={sortMenuAnchorEl} open={showSortMenu} onClose={() => setShowSortMenu(false)} >
                                <MenuItem id="sortDateCreatedDesc" onClick={() => { setShowSortMenu(false); props.onRiskSortTypeChange(RiskSortType.SortDateCreatedDesc) }}>Date Created &#8595;</MenuItem>
                                <MenuItem id="sortDateCreatedAsc" onClick={() => { setShowSortMenu(false); props.onRiskSortTypeChange(RiskSortType.SortDateCreatedAsc) }}>Date Created &#8593;</MenuItem>
                                <MenuItem id="sortNameDesc" onClick={() => { setShowSortMenu(false); props.onRiskSortTypeChange(RiskSortType.SortNameDesc) }}>Name &#8595;</MenuItem>
                                <MenuItem id="sortNameAsc" onClick={() => { setShowSortMenu(false); props.onRiskSortTypeChange(RiskSortType.SortNameAsc) }}>Name &#8593;</MenuItem>
                            </Menu>
                        </TableCell >
                    </TableRow>
                    <TableRow >
                        <TableCell style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
                            <VerticalScrollableComponent viewPortHeightOffset={233}>
                                <List style={{ padding: 0 }}>
                                    {props.risks.map(risk =>
                                        <ListItem divider={false} button key={risk.id} classes={{ selected: "SelectedMenu" }} selected={props.selectedRisk?.id === risk.id} >
                                            <ArrowRight fontSize="medium" color={props.selectedRisk?.id === risk.id ? "secondary" : "primary"} />
                                            <ListItemText style={{ width: '100%' }} onClick={() => props.onChangeRiskAssignmentDisplay(RiskAssignmentDisplay.VIEW_RISK, risk)} primary={truncateTextToWhiteSpace(risk.name, 50)} title={risk.name} />
                                            <IconButton color="info" edge="end" onClick={() => props.onChangeRiskAssignmentDisplay(RiskAssignmentDisplay.VIEW_RISK_PRIORITISATION, risk)}  title='View Risk Prioritisation Matrix' >
                                                <Class />
                                            </IconButton>
                                            <IconButton color="info" edge="end" onClick={() => props.onChangeRiskAssignmentDisplay(RiskAssignmentDisplay.ASSIGN_RISK, risk)} disabled={risk.status !== RiskStatus.APPROVED && risk.status !== RiskStatus.CHAMPION_NOMINATED && risk.status !== RiskStatus.CHAMPION_NOMINATION_REJECTED} title='Assign Risk' >
                                                <AssignmentInd />
                                            </IconButton>
                                        </ListItem>
                                    )}
                                </List>
                            </VerticalScrollableComponent>
                        </TableCell >
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    )
}
