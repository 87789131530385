import { useState } from "react";
import { Stack, TextField, MenuItem } from "@mui/material";
import { Risk } from "../helpers/Interfaces";
import { useAppSelector } from "../store/store";
import { useLocation } from "react-router-dom";
import { showConfirmBox, getRiskStatusString, getFilteredAndSortedRisks } from "../helpers/Utils";
import { RiskIdentificationDisplay, RiskGroupFilters, RiskStatus, FormEditMode, RiskSortType, DisplayRefreshTrigger } from "../helpers/Constants";
import { deleteRisk, notifyEntityHeadForAllEntityRisks, notifyEntityHeadForEntityRisk, submitAllRisks } from "../api-services/RiskApi";
import { HorizontalSplitPane } from "../layouts/HorizontalSplitPane";
import { RiskIdentificationWidget } from "../components/risk-identification/RiskIdentificationWidget";
import { RiskIdentificationDetails } from "../components/risk-identification/RiskIdentificationDetails";
import { useDisplay, useRiskAssessment, useRisks, useSelectedRisk } from "../helpers/GlobalHooks";



export function RiskIdentificationEditor() {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const location = useLocation();
    let queryParams = new URLSearchParams(location.search);
    const assessmentIdForRiskIdentification: number | null = queryParams.get("assessmentIdForRiskIdentification") == null ? null : Number(queryParams.get("assessmentIdForRiskIdentification"))
    const defaultRiskId: number | null = queryParams.get("defaultRiskId") == null ? null : Number(queryParams.get("defaultRiskId"))
    const defaultRiskGroupFilter: number | null = queryParams.get("defaultRiskGroupFilter") == null ? null : Number(queryParams.get("defaultRiskGroupFilter"))
    const defaultDisplay: number = queryParams.get("defaultDisplay") != null ? Number(queryParams.get("defaultDisplay")) : (assessmentIdForRiskIdentification != null ? RiskIdentificationDisplay.CREATE_RISK : RiskIdentificationDisplay.PREVIEW_RISK)
    // states and state dependent variables
    const [risks, setRisks, refreshRisks] = useRisks()
    const [riskAssessmentForRiskIdentification,] = useRiskAssessment(assessmentIdForRiskIdentification)
    const [riskSortType, setRiskSortType] = useState<RiskSortType>(RiskSortType.SortDateCreatedDesc)
    const [categoryFilter, setCategoryFilter] = useState('All')
    const [entityFilter, setEntityFilter] = useState('All')
    const [riskAssessmentFilter, setRiskAssessmentFilter] = useState(assessmentIdForRiskIdentification != null ? assessmentIdForRiskIdentification + '' : 'All')
    const [statusFilter, setStatusFilter] = useState('All')
    const [riskGroupFilter, setRiskGroupFilter] = useState<number>(defaultRiskGroupFilter ?? RiskGroupFilters.MY_RISKS)
    const [displayRefreshTrigger, setDisplayRefreshTrigger] = useState<DisplayRefreshTrigger | null>(null)
    const [displayRiskAssessmentWelcomeMessage, setDisplayRiskAssessmentWelcomeMessage] = useState(true)
    const filteredRisks = getFilteredAndSortedRisks(loggedOnUserProfile, risks, entityFilter, categoryFilter, statusFilter, riskAssessmentFilter, "All", riskGroupFilter, riskSortType)
    const { selectedRisk, setSelectedRisk } = useSelectedRisk(filteredRisks, defaultRiskId, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected)
    const { display, setDisplay } = useDisplay(filteredRisks, defaultDisplay, displayRefreshTrigger !== DisplayRefreshTrigger.ItemListFiltered, displayRefreshTrigger === DisplayRefreshTrigger.ItemSelected, RiskIdentificationDisplay.CREATE_RISK, RiskIdentificationDisplay.PREVIEW_RISK)



    function handleConfirmSubmitAllRisks() {
        showConfirmBox("You will not be able to make changes after submitting. Are you sure you want to Submit all Risks?", "Confirm Submit")
            .then((result) => {
                if (result.isConfirmed)
                    submitAllRisks(risks).then(risks => {
                        for (let i = 0; i < risks.length; i++)
                            setRisks(risks => risks.map(r => r.id !== risks[i].id ? r : risks[i]))
                    })
            })
    }

    function handleConfirmDeleteRisk(riskIdToDelete: number) {
        showConfirmBox("Are you sure you want to delete this Risk?", "Confirm Delete")
            .then((result) => {
                if (result.isConfirmed)
                    deleteRisk(riskIdToDelete).then(() => {
                        setRisks(risks => risks.filter(inst => inst.id !== riskIdToDelete))
                        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered) //needed to refresh and select first risk in list
                    })
            })
    }

    function handleConfirmNotifyEntityHeadForEntityRisk(riskIdToNotifyEntityHead: number) {
        showConfirmBox("Are you sure you want to notify the Entity Head regarding the identification of this Entity Risk?", "Confirm Notify Entity Head")
            .then((result) => {
                if (result.isConfirmed) notifyEntityHeadForEntityRisk(riskIdToNotifyEntityHead);
            })
    }

    function handleConfirmNotifyEntityHeadForAllEntityRisks() {
        showConfirmBox("Are you sure you want to notify the Entity Head regarding the identification of Entity Risks?", "Confirm Notify Entity Head")
            .then((result) => {
                if (result.isConfirmed) notifyEntityHeadForAllEntityRisks()
            })
    }

    function handleRiskChange(id: number, risk: Risk, formEditMode: FormEditMode, display: RiskIdentificationDisplay) {
        if (formEditMode === FormEditMode.EDIT) {
            setRisks(risks => risks.map(inst => inst.id === id ? risk : inst))
            setSelectedRisk(risk)
            setDisplay(display)
        }
        else if (formEditMode === FormEditMode.CREATE) {
            setRisks(risks => [risk, ...risks])
            setSelectedRisk(risk)
            setDisplay(display)
        }
    }

    function handleChangeRiskIdentificationDisplay(display: RiskIdentificationDisplay, risk?: Risk | null) {
        if (risk != null) setSelectedRisk(risk)
        setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemSelected)
        setDisplay(display);
    }


    return (
        loggedOnUserProfile &&
        <>
            {assessmentIdForRiskIdentification == null &&
                <Stack direction="row" spacing={2} style={{ width: '100%', paddingLeft: 5, paddingRight: 5, paddingTop: 0, paddingBottom: 5 }} >
                    <TextField fullWidth select variant="standard" size="small" name="statusFilter" value={statusFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setStatusFilter(e.target.value) }}>
                        <MenuItem key={0} value="All">All Status</MenuItem >
                        <MenuItem key={1} value={RiskStatus.IDENTIFIED}>{getRiskStatusString(RiskStatus.IDENTIFIED)}</MenuItem >
                        <MenuItem key={2} value={RiskStatus.SUBMITTED}>{getRiskStatusString(RiskStatus.SUBMITTED)}</MenuItem >
                        <MenuItem key={3} value={RiskStatus.APPROVED}>{getRiskStatusString(RiskStatus.APPROVED)}</MenuItem >
                        <MenuItem key={5} value={RiskStatus.CHAMPION_NOMINATED}>{getRiskStatusString(RiskStatus.CHAMPION_NOMINATED)}</MenuItem >
                        <MenuItem key={6} value={RiskStatus.CHAMPION_NOMINATION_REJECTED}>{getRiskStatusString(RiskStatus.CHAMPION_NOMINATION_REJECTED)}</MenuItem >
                        <MenuItem key={7} value={RiskStatus.ASSIGNED_TO_CHAMPION}>{getRiskStatusString(RiskStatus.ASSIGNED_TO_CHAMPION)}</MenuItem >
                        <MenuItem key={8} value={RiskStatus.ACTION_PLAN_SUBMITTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_SUBMITTED)}</MenuItem >
                        <MenuItem key={9} value={RiskStatus.ACTION_PLAN_REJECTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_REJECTED)}</MenuItem >
                        <MenuItem key={10} value={RiskStatus.ACTION_PLAN_APPROVED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_APPROVED)}</MenuItem >
                        <MenuItem key={12} value={RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED}>{getRiskStatusString(RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED)}</MenuItem >
                        <MenuItem key={13} value={RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR}>{getRiskStatusString(RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR)}</MenuItem >
                        <MenuItem key={19} value={RiskStatus.UNEXPOSED}>{getRiskStatusString(RiskStatus.UNEXPOSED)}</MenuItem >
                    </TextField>
                    <TextField fullWidth select variant="standard" size="small" name="riskAssessmentFilter" value={riskAssessmentFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setRiskAssessmentFilter(e.target.value) }}>
                        <MenuItem value="All">All Risk Types</MenuItem >
                        <MenuItem value='0'>Unassessed Risk</MenuItem >
                        {[...new Map(risks.filter(row => row.sourceAssessment != null).map(item => [item.sourceAssessment!.id, item.sourceAssessment!])).values()].map(row => <MenuItem key={row!.id} value={row!.id}>{row!.assessmentName}</MenuItem >)}
                    </TextField>
                    <TextField fullWidth select variant="standard" size="small" name="entityFilter" value={entityFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setEntityFilter(e.target.value) }}>
                        <MenuItem value="All">All Entities</MenuItem >
                        {riskRelatedTables.entities.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem >)}
                    </TextField>
                    <TextField fullWidth select variant="standard" size="small" name="categoryFilter" value={categoryFilter} onChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setCategoryFilter(e.target.value) }}>
                        <MenuItem value="All">All Categories</MenuItem >
                        {riskRelatedTables.categories.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem >)}
                    </TextField>
                </Stack>
            }

            <HorizontalSplitPane leftWidth={299} rightWidthDynamic
                left={
                    <RiskIdentificationWidget risks={filteredRisks} selectedRisk={selectedRisk} onRiskSortTypeChange={setRiskSortType}
                        assessmentIdForRiskIdentification={assessmentIdForRiskIdentification} riskAssessmentForRiskIdentification={riskAssessmentForRiskIdentification}
                        riskGroupFilter={riskGroupFilter} onRiskGroupFilterChange={(e) => { setDisplayRefreshTrigger(DisplayRefreshTrigger.ItemListFiltered); setRiskGroupFilter(Number(e)) }}
                        onChangeRiskIdentificationDisplay={handleChangeRiskIdentificationDisplay}
                        onClickDeleteRisk={handleConfirmDeleteRisk}
                        onClickNotifyEntityHead={handleConfirmNotifyEntityHeadForEntityRisk}
                        onClickSubmitAllRisks={handleConfirmSubmitAllRisks}
                        onClickNotifyEntityHeadAllEntityRisks={handleConfirmNotifyEntityHeadForAllEntityRisks}
                        onClickRefreshRisks={() => refreshRisks()}
                    />}
                right={
                    <RiskIdentificationDetails risks={risks} selectedRisk={selectedRisk} onRiskChange={handleRiskChange}
                        assessmentIdForRiskIdentification={assessmentIdForRiskIdentification} riskAssessmentForRiskIdentification={riskAssessmentForRiskIdentification}
                        displayRiskAssessmentWelcomeMessage={displayRiskAssessmentWelcomeMessage} onClickBeginRiskAssessmentIdentification={() => setDisplayRiskAssessmentWelcomeMessage(false)}
                        display={display} onChangeRiskIdentificationDisplay={handleChangeRiskIdentificationDisplay}
                        onClickDeleteRisk={handleConfirmDeleteRisk}
                        onClickNotifyEntityHead={handleConfirmNotifyEntityHeadForEntityRisk}
                    />}
            />
        </>
    )
}