import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, ImpactArea } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getAllImpactAreas = async () => {
    return await new Promise<ImpactArea[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Impact Areas"));
        callServerAPI<ServerAPIResponse<ImpactArea[]>>('GET', '/api/ImpactArea/GetAllImpactAreas', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function deleteImpactArea(id: number) {
    return await new Promise<boolean>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Delete Impact Area"));
        await callServerAPI<ServerAPIResponse<boolean>>('DELETE', '/api/ImpactArea/DeleteImpactArea?id=' + id, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response.data.data ? "Impact Area Disabled" : "Impact Area Deleted", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function createImpactArea(impactArea: ImpactArea) {
    return await new Promise<ImpactArea>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new Impact Area"));
        callServerAPI<ServerAPIResponse<ImpactArea>>('POST', '/api/ImpactArea/CreateImpactArea', impactArea, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Impact Area Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function updateImpactArea(impactArea: ImpactArea) {
    return await new Promise<ImpactArea>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Impact Area"));
        callServerAPI<ServerAPIResponse<ImpactArea>>('PATCH', '/api/ImpactArea/UpdateImpactArea?id=' + impactArea.id, impactArea, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Impact Area Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}
