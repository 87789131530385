import { Typography, Box, Tab, Tabs } from "@mui/material";
import { ResponsiveContainer, LineChart, ReferenceLine, Line, XAxis, YAxis, Tooltip, TooltipProps } from "recharts";
import { SeverityDescription } from "../../helpers/Constants";
import { Risk } from "../../helpers/Interfaces";
import { getRiskPrioritisationZoneString, formatShortDateString } from "../../helpers/Utils";
import { ViewRiskCard } from "../shared/ViewRiskCard";
import { useState } from "react";
import { CustomTabPanel } from "../../layouts/CustomTabPanel";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { infoColor } from "../../layouts/theme";


interface Props {
    risk: Risk
    totalFrequencyOfMentions: number | null
}

enum ViewMonitoringOverviewTabs {
    RiskCard = 1,
    RiskTrend = 2,
}

export function ViewMonitoringOverview(props: Props) {
    const [currentTab, setCurrentTab] = useState<ViewMonitoringOverviewTabs>(ViewMonitoringOverviewTabs.RiskCard)


    function customTooltip({ active, payload, label }: TooltipProps<ValueType, NameType>) {
        if (active && payload && payload.length) {
            return (
                <Typography color="textPrimary" variant="caption" gutterBottom style={{ backgroundColor: "white" }}>
                    <span >Source: {payload[0].payload.changeSource}</span><br />
                    <span >Date: {payload[0].payload.changeCreatedDateDesc}</span><br />
                    <span >Impact: {payload[0].payload.impact}</span><br />
                    <span >Likelihood: {payload[0].payload.likelihood}</span><br />
                    <span >Mitigation: {payload[0].payload.mitigation}</span><br />
                    <span >Severity: {SeverityDescription[payload[0].payload.severity]}</span><br />
                    <span >Prioritisation: {getRiskPrioritisationZoneString(payload[0].payload.prioritisation)}</span><br />
                </Typography>
            );
        }
        return null;
    }

    function formatYAxis(value: number) {
        return getRiskPrioritisationZoneString(value) ?? ""
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(Number(newValue))} textColor="secondary" indicatorColor="secondary">
                    <Tab label="Risk Card" value={ViewMonitoringOverviewTabs.RiskCard} style={{ fontSize: 14 }} />
                    <Tab label="Risk Trend" value={ViewMonitoringOverviewTabs.RiskTrend} style={{ fontSize: 14 }} />
                </Tabs>
            </Box>
            <CustomTabPanel tabValue={ViewMonitoringOverviewTabs.RiskCard} selectedTab={currentTab}>
                <ViewRiskCard risk={props.risk} totalFrequencyOfMentions={props.totalFrequencyOfMentions} />
            </CustomTabPanel>
            <CustomTabPanel tabValue={ViewMonitoringOverviewTabs.RiskTrend} selectedTab={currentTab}>
                <ResponsiveContainer width='100%' height={420}  >
                    <LineChart height={300} data={props.risk.riskRatingChanges.sort((a, b) => a.changeCreatedDate < b.changeCreatedDate ? -1 : 1).map(c => { c.changeCreatedDateDesc = formatShortDateString(c.changeCreatedDate); return c })} margin={{ top: 5, right: 5, bottom: 35, left: 25 }}>
                        {props.risk.actionPlanImplementationTargetPrioritisation != null && <ReferenceLine y={props.risk.actionPlanImplementationTargetPrioritisation} label={{ value: 'Target Prioritisation', angle: 0, position: 'insideTopRight', style: { fontSize: 12, color: 'green', fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" } }} stroke="green" />}
                        <Line type="linear" dataKey="prioritisation" stroke="red" />
                        <XAxis dataKey="changeCreatedDateDesc" tickLine={false} label={{ dy: 30, fill: infoColor, value: 'Date', position: 'center', style: { fontSize: 14, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" } }} />
                        <YAxis type="number" dataKey="prioritisation" tickLine={false} tickFormatter={formatYAxis} domain={[0, 5]} interval={0} tickCount={6} style={{ color: 'red' }} />
                        <Tooltip content={e => customTooltip(e)} />
                    </LineChart>
                </ResponsiveContainer>
            </CustomTabPanel>
        </>
    )
}
