import { getRatingDescription, truncateTextToWhiteSpace } from "../../helpers/Utils";
import { Grid, Typography, Divider, Stack, Paper, Box } from "@mui/material";
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea, ResponsiveContainer, Label } from "recharts";
import { Risk } from "../../helpers/Interfaces";
import { useAppSelector } from "../../store/store";
import { ImpactLikelihoodColor, MitigationColor, SeverityColor, SeverityDescription } from "../../helpers/Constants";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";


interface Props {
    riskToPrioritise: Risk
}

export function PrioritiseRisk(props: Props) {
    // state independent variables
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);


    return (<Paper className="Form" >
        <Box className="FormHeader"><Typography className="FormHeaderOrFooterDetails">Risk Prioritisation for {props.riskToPrioritise.name} </Typography></Box>
        <VerticalScrollableComponent viewPortHeightOffset={231}>
            <Stack alignContent='center' alignItems='center' id="RiskPrioritisationFormDetails" direction='row' divider={<Divider orientation="vertical" flexItem />} spacing={2} className="FormDetails">
                <Grid item xs={8} textAlign='center'>
                    <Typography color="textPrimary" >Prioritisation Zone</Typography>
                    <ResponsiveContainer width='100%' height={420} >
                        <ScatterChart width={400} height={400} margin={{ top: 10, right: 20, bottom: 10, left: 20 }} style={{ fontFamily: 'Roboto' }}  >
                            <CartesianGrid />
                            <ReferenceArea x1={0.5} x2={3.5} y1={0.5} y2={2.5} fill="green" fillOpacity={100}><Label value={"Delegation\nZone"} offset={0} position="center" fill='#ffffff' fontSize={16} /> </ReferenceArea>
                            <ReferenceArea x1={3.5} x2={6.5} y1={0.5} y2={2.5} fill="#555555" fillOpacity={100}><Label value={"Vigilance\nZone"} offset={0} position="center" fill='#ffffff' fontSize={16} /> </ReferenceArea>
                            <ReferenceArea x1={0.5} x2={3.5} y1={2.5} y2={4.5} fill="blue" fillOpacity={100}><Label value={"Verification\nZone"} offset={0} position="center" fill='#ffffff' fontSize={16} /> </ReferenceArea>
                            <ReferenceArea x1={3.5} x2={6.5} y1={2.5} y2={4.5} fill="#cc0000" fillOpacity={100}><Label value={"Priority\nRemediation\nZone"} offset={0} position="center" fill='#ffffff' fontSize={16} /> </ReferenceArea>
                            <XAxis type="number" ticks={[1, 2, 3, 4, 5, 6]} tickLine={false} dataKey="x" name="Mitigation" domain={[0.5, 6.5]} label={{ value: 'Mitigation', offset: -5, position: 'bottom', }} />
                            <YAxis type="number" ticks={[1, 2, 3, 4]} dataKey="y" name="Severity" domain={[0.5, 4.5]} label={{ value: 'Severity', angle: -90, position: 'left' }} />
                            <Tooltip cursor={{ stroke: 'white', strokeWidth: 2 }} />
                            <Scatter name='Risk Prioritisation Zone' data={[{ x: props.riskToPrioritise.mitigation, y: props.riskToPrioritise.severity }]} fill="#ffffff" shape="circle" />
                        </ScatterChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={4} >
                    <Stack direction="column" spacing={1} style={{ width: '100%', }} divider={<Divider orientation="horizontal" flexItem />}>

                        {props.riskToPrioritise.impact && <Stack direction="column">
                            <Typography color="textSecondary" >Impact</Typography>
                            <Stack direction="row" spacing={2} alignItems='center'>
                                <Box><Typography align="center" fontSize={16} style={{ padding: 5, width: '30px', color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[props.riskToPrioritise.impact] }}>{props.riskToPrioritise.impact}</Typography>
                                </Box><Typography style={{ padding: 5 }} color="textPrimary" title={getRatingDescription(props.riskToPrioritise.impact, riskRelatedTables.ratingDescriptions, "IMPACT")} >{truncateTextToWhiteSpace(getRatingDescription(props.riskToPrioritise.impact, riskRelatedTables.ratingDescriptions, "IMPACT"), 90)}</Typography>
                            </Stack>
                        </Stack>}

                        {props.riskToPrioritise.likelihood && <Stack direction="column">
                            <Typography color="textSecondary" >Likelihood</Typography>
                            <Stack direction="row" spacing={2} alignItems='center'>
                                <Typography align="center" fontSize={16} style={{ padding: 5, width: '30px', color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[props.riskToPrioritise.likelihood] }}>{props.riskToPrioritise.likelihood}</Typography>
                                <Typography style={{ padding: 5 }} color="textPrimary" >{getRatingDescription(props.riskToPrioritise.likelihood, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}</Typography>
                            </Stack>
                        </Stack>}

                        {props.riskToPrioritise.mitigation && <Stack direction="column">
                            <Typography color="textSecondary" >Mitigation</Typography>
                            <Stack direction="row" spacing={2} alignItems='center'>
                                <Box><Typography align="center" fontSize={16} style={{ padding: 5, width: '30px', color: '#FFFFFF', backgroundColor: MitigationColor[props.riskToPrioritise.mitigation] }}>{props.riskToPrioritise.mitigation}</Typography>
                                </Box><Typography style={{ padding: 5 }} color="textPrimary" >{getRatingDescription(props.riskToPrioritise.mitigation, riskRelatedTables.ratingDescriptions, "MITIGATION")}</Typography>
                            </Stack>
                        </Stack>}

                        {props.riskToPrioritise.severity && <Stack direction="column">
                            <Typography color="textSecondary" >Severity</Typography>
                            <Stack direction="row" spacing={2} alignItems='center'>
                                <Box><Typography align="center" fontSize={16} style={{ padding: 5, width: '30px', color: '#FFFFFF', backgroundColor: SeverityColor[props.riskToPrioritise.severity] }}>{props.riskToPrioritise.severity}</Typography>
                                </Box><Typography style={{ padding: 5 }} color="textPrimary" >{SeverityDescription[props.riskToPrioritise.severity]}</Typography>
                            </Stack>
                        </Stack>}

                    </Stack>
                </Grid>
            </Stack >
        </VerticalScrollableComponent>
        <Box className="FormFooter">
        </Box>
    </Paper >
    )
}
