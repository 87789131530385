import * as React from "react";

interface Props {
    children: React.ReactNode
    selectedTab: number
    tabValue: number
}

export function CustomTabPanel({ children, tabValue, selectedTab, ...other }: Props) {
    return (
        <div style={{ paddingTop: 20, paddingLeft: 10, paddingRight: 10 }} role="tabpanel" hidden={selectedTab !== tabValue} id={`tabpanel-${tabValue}`} aria-labelledby={`tab-${tabValue}`} {...other}>
            {children}
        </div>
    );
}