import MaterialTable from "@material-table/core";
import { Box, Paper, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { BusinessObjective } from "../helpers/Interfaces";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";
import { infoColor } from "../layouts/theme";
import { getAllBusinessObjectives, createBusinessObjective, updateBusinessObjective, deleteBusinessObjective } from "../api-services/BusinessObjectiveApi";

export function ManageBusinessObjectives() {
    //state
    const [businessObjectives, setBusinessObjectives] = useState<BusinessObjective[]>([])

    useEffect(() => {
        getAllBusinessObjectives().then(businessObjectives => setBusinessObjectives(businessObjectives))
    }, [])


    function addRecord(newData: BusinessObjective) {
        return new Promise<void>((resolve, reject) => {
            createBusinessObjective(newData).then(result => {
                setBusinessObjectives(businessObjectives => [...businessObjectives, result])
                resolve()
            })
        })
    }

    function updateRecord(newData: BusinessObjective, oldData: BusinessObjective | undefined) {
        return new Promise<void>((resolve, reject) => {
            updateBusinessObjective(newData).then(result => {
                setBusinessObjectives(businessObjectives => businessObjectives.map(inst => inst.id === newData.id ? result : inst))
                resolve()
            })
        })
    }

    function deleteRecord(oldData: BusinessObjective) {
        return new Promise<void>((resolve, reject) => {
            deleteBusinessObjective(oldData.id).then(disabled => {
                if (disabled)
                    setBusinessObjectives(businessObjectives => businessObjectives.map(inst => inst.id === oldData.id ? { ...oldData, disabled: true } : inst))
                else
                    setBusinessObjectives(businessObjectives => businessObjectives.filter(inst => inst.id !== oldData.id))
                resolve()
            })
        })
    }


    return (
        <Box className='HorizontalOverFlowContainer'>
            <Paper className="Form">
                <br />
                <VerticalScrollableComponent viewPortHeightOffset={162}>
                    <Grid id="EntitiesViewContainer" container style={{ padding: 5 }} >
                        <Grid item xs={12}>
                            {
                                <MaterialTable components={{ Container: props => <Paper {...props} style={{ width: '100%' }} elevation={0} />, }}
                                    columns={[
                                        { title: 'Id', field: 'id', type: 'numeric', align: 'left', initialEditValue: 0, editable: 'never', width: 50 },
                                        { title: 'Name', field: 'name', align: 'left', validate: rowData => rowData.name ? true : 'Name is required' },
                                        { title: 'Disabled', field: 'disabled', type: 'boolean', align: 'left', initialEditValue: false, width: 30 },
                                    ]}
                                    data={businessObjectives}
                                    editable={{
                                        onRowAdd: (newData) => addRecord(newData),
                                        onRowUpdate: (newData, oldData) => updateRecord(newData, oldData),
                                        onRowDelete: (oldData) => deleteRecord(oldData),
                                    }}

                                    localization={{ header: { actions: '' } }}

                                    options={{
                                        toolbarButtonAlignment: "left", searchFieldAlignment: 'left', paginationAlignment: "flex-start",
                                        pageSize: 5, pageSizeOptions: [5, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false, rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, actionsCellStyle: { width: '50px', color: infoColor }, headerStyle: { color: infoColor },
                                    }}
                                />}
                        </Grid>
                    </Grid>
                </VerticalScrollableComponent>
                <br />

            </Paper>
        </Box>
    )
}
