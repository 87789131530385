import { Paper, Box, Typography, Button, Grid } from "@mui/material";
import { RiskMonitoringDisplay, RiskStatus } from "../../helpers/Constants";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { Risk } from "../../helpers/Interfaces";
import { ViewRisk } from "../shared/ViewRisk";
import { CreateValidateRiskChange } from "./CreateValidateRiskChange";
import { ViewMonitoringOverview } from "./ViewMonitoringOverview";
import { CanOnlyFlagOrSubmitRiskChangeForApprovedRisks } from "../../helpers/BusinessRules";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface Props {
    display: RiskMonitoringDisplay
    risks: Risk[]
    selectedRisk: Risk | null | undefined
    onChangeRiskMonitoringDisplay: (display: RiskMonitoringDisplay, risk?: Risk | null) => void
    onRiskChange: (id: number, risk: Risk) => void
    onFlagRiskAsUnexposed: (risk: Risk | null | undefined) => void
    onFlagRiskAsExposed: (risk: Risk | null | undefined) => void
}

export function RiskMonitoringDetails(props: Props) {

    return (
        <>
            {props.display === RiskMonitoringDisplay.EDIT_RISK && props.selectedRisk &&
                <CreateValidateRiskChange riskToEdit={props.selectedRisk} onRiskChange={props.onRiskChange} />
            }

            {props.display === RiskMonitoringDisplay.VIEW_RISK && props.selectedRisk &&
                <Paper className="Form" style={{ paddingBottom: 5 }}>
                    <Box className="FormHeader">
                        <Typography className="FormHeaderOrFooterDetails">Risk Details</Typography>
                    </Box>
                    <VerticalScrollableComponent viewPortHeightOffset={236}>
                        <ViewRisk risk={props.selectedRisk} />
                    </VerticalScrollableComponent>
                    <Box className="FormFooter">
                        {props.selectedRisk.status !== RiskStatus.UNEXPOSED && <Grid item xs={12} className="FormHeaderOrFooterDetails">
                            <Button variant="contained" size="small" color="info" onClick={() => props.onFlagRiskAsUnexposed(props.selectedRisk)} title="Flag Risk as Unexposed" disabled={!CanOnlyFlagOrSubmitRiskChangeForApprovedRisks(props.selectedRisk).validate()}><VisibilityOff />&nbsp;&nbsp;Flag As Unexposed </Button>&nbsp;&nbsp;
                        </Grid>}
                        {props.selectedRisk.status === RiskStatus.UNEXPOSED && <Grid item xs={12} className="FormHeaderOrFooterDetails">
                            <Button variant="contained" size="small" color="info" onClick={() => props.onFlagRiskAsExposed(props.selectedRisk)} title="Flag Risk as Exposed"><Visibility />&nbsp;&nbsp;Flag As Exposed </Button>&nbsp;&nbsp;
                        </Grid>}
                    </Box>
                </Paper >
            }

            {props.display === RiskMonitoringDisplay.PREVIEW_RISK && props.selectedRisk &&
                <Paper className="Form" >
                    <Box className="FormHeader" >
                        <Typography className="FormHeaderOrFooterDetails"> Risk Monitoring Overview </Typography>
                    </Box>
                    <VerticalScrollableComponent viewPortHeightOffset={191}>
                        <ViewMonitoringOverview risk={props.selectedRisk} totalFrequencyOfMentions={props.selectedRisk.sourceAssessment == null ? null : (props.risks.filter(r => r.sourceAssessment != null && r.sourceAssessment.id === props.selectedRisk!.sourceAssessment?.id).reduce((total, r) => total + r.frequencyOfMention, 0))} />
                    </VerticalScrollableComponent>
                </Paper >}
        </>
    )
}