import { ServerAPIResponse, Risk, SubmitRisksDto, RiskDto, AssignRiskDto, RiskActionDto, RiskAction, RiskActionImplementationDto, RiskChange, RiskChangeDto } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI, showAlertError } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { enqueueSnackbar } from "notistack";


const MySwal = withReactContent(Swal);

export const getAllRisks = async () => {
    return await new Promise<Risk[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Risks"));
        callServerAPI<ServerAPIResponse<Risk[]>>('GET', '/api/Risk/GetAllRisks', null, true)
            .then(response => {
                MySwal.close()
                const result = response.data.data.sort((a, b) => (a.submittedDate == null && b.submittedDate == null) ? (a.createdDate > b.createdDate ? -1 : 1) : ((b.submittedDate == null || a.submittedDate! > b.submittedDate) ? -1 : 1))
                resolve(result)
            })
    })
}

export const getAllRisksForAssignment = async () => {
    return await new Promise<Risk[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Risks"));
        callServerAPI<ServerAPIResponse<Risk[]>>('GET', '/api/Risk/GetAllRisksForAssignment', null, true)
            .then(response => {
                MySwal.close()
                const result = response.data.data.sort((a, b) => (a.submittedDate == null && b.submittedDate == null) ? (a.createdDate > b.createdDate ? -1 : 1) : ((b.submittedDate == null || a.submittedDate! > b.submittedDate) ? -1 : 1))
                resolve(result)
            })
    })
}

export const getAllRisksForTreatment = async () => {
    return await new Promise<Risk[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Risks"));
        callServerAPI<ServerAPIResponse<Risk[]>>('GET', '/api/Risk/GetAllRisksForTreatment', null, true)
            .then(response => {
                MySwal.close()
                const result = response.data.data.sort((a, b) => (a.submittedDate == null && b.submittedDate == null) ? (a.createdDate > b.createdDate ? -1 : 1) : ((b.submittedDate == null || a.submittedDate! > b.submittedDate) ? -1 : 1))
                resolve(result)
            })
    })
}

export const getAllRisksForMonitoring = async () => {
    return await new Promise<Risk[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Risks"));
        callServerAPI<ServerAPIResponse<Risk[]>>('GET', '/api/Risk/GetAllRisksForMonitoring', null, true)
            .then(response => {
                MySwal.close()
                const result = response.data.data.sort((a, b) => (a.submittedDate == null && b.submittedDate == null) ? (a.createdDate > b.createdDate ? -1 : 1) : ((b.submittedDate == null || a.submittedDate! > b.submittedDate) ? -1 : 1))
                resolve(result)
            })
    })
}

function mapRiskToRiskDto(risk: Risk): RiskDto {
    return ({
        ...risk, sourceAssessmentId: risk.sourceAssessment?.id ?? null,
        categoryId: risk.category?.id,
        businessObjectiveImpactedId: risk.businessObjectiveImpacted?.id,
        entitiesImpactedIdsList: risk.entitiesImpacted.map(e => e.id),
        riskImpactAreaRatings: risk.riskImpactAreaRatings.map(e => ({ impactAreaId: e.impactArea.id, rating: e.rating }))
    })
}

export async function getRiskPdf(riskId: number) {
    return await new Promise<Blob>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Exporting Risk to PDF"));
        callServerAPI<Blob>('GET', '/api/Risk/GetRiskPdf?id=' + riskId, null, true, 'blob')
            .then(response => {
                MySwal.close();
                enqueueSnackbar("PDF file has been downloaded", { variant: 'success' });
                resolve(response.data)
            })
    })
}

export const createRisk = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        const data = mapRiskToRiskDto(risk)
        MySwal.fire(showBackDrop("Adding identified Risk"));
        callServerAPI<ServerAPIResponse<Risk>>('POST', '/api/Risk/CreateRisk', data, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Identified Risk Added", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const createAndSubmitRisk = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        const data = mapRiskToRiskDto(risk)
        MySwal.fire(showBackDrop("Adding and submitting identified Risk"));
        callServerAPI<ServerAPIResponse<Risk>>('POST', '/api/Risk/CreateAndSubmitRisk', data, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Identified Risk Added and Submitted", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const updateRisk = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        const data = mapRiskToRiskDto(risk)
        MySwal.fire(showBackDrop("Updating identified Risk"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', '/api/Risk/UpdateRisk?id=' + risk.id, data, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Identified Risk Updated", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export async function deleteRisk(riskIdToDelete: number) {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Deleting Risk"));
        callServerAPI<any>('DELETE', '/api/Risk/DeleteRisk?id=' + riskIdToDelete, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response?.data?.message ?? "Risk Deleted", { variant: 'success' });
                resolve()
            })
    })
}

export const submitRisk = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        const data: SubmitRisksDto = { riskIds: [risk.id] }
        MySwal.fire(showBackDrop("Submitting identified Risk"));
        callServerAPI<ServerAPIResponse<Risk[]>>('PATCH', '/api/Risk/SubmitRisks', data, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Identified Risk Submitted", { variant: 'success' });
                resolve(response.data.data[0]);
            })
    })
}

export const submitAllRisks = async (risks: Risk[]) => {
    return await new Promise<Risk[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Submitting Risks"));
        const data: SubmitRisksDto = { riskIds: risks.map(row => row.id) }
        callServerAPI<ServerAPIResponse<Risk[]>>('PATCH', '/api/Risk/SubmitRisks', data, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response?.data?.message ?? "All Risks Submitted", { variant: 'success' });
                if (response.data.message) showAlertError(response.data.message)
                resolve(response.data.data)
            })
    })
}

export async function notifyEntityHeadForEntityRisk(riskIdToNotifyEntityHead: number) {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Notifying Entity Head on Entity Risk Identification"));
        callServerAPI<any>('PATCH', '/api/Risk/NotifyEntityHeadForIdentifiedEntityRisk?id=' + riskIdToNotifyEntityHead, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response?.data?.message ?? "Entity Head notified", { variant: 'success' });
                resolve()
            })
    })
}

export async function notifyEntityHeadForAllEntityRisks() {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Notifying Entity Head on Entity Risks Identification"));
        callServerAPI<any>('PATCH', '/api/Risk/NotifyEntityHeadForAllIdentifiedEntityRisks', null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response?.data?.message ?? "Entity Head notified", { variant: 'success' });
                resolve()
            })
    })
}

export const assignRisk = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        let backDropessage = "Assigning Risk to Champion"
        MySwal.fire(showBackDrop(backDropessage));
        const data: AssignRiskDto = {
            riskId: risk.id, championEntity: risk.championEntity?.id, champion: risk.champion, alternateChampion: risk.alternateChampion,
            sponsor: risk.sponsor, targetMitigation: risk.targetMitigation, responseDate: risk.responseDate
        }
        callServerAPI<ServerAPIResponse<Risk[]>>('PATCH', '/api/Risk/AssignRisk', data, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Assigned Risk Sponsor and Champions have been notified", { variant: 'success' });
                if (response.data.message) showAlertError(response.data.message)
                resolve(response.data.data[0])
            })
    })
}

export async function notifyNominatedChampion(riskId: number) {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Resending Champion Nomination Notification"));
        callServerAPI<any>('PATCH', '/api/Risk/NotifyNominatedChampion?id=' + riskId, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(response?.data?.message ?? "Assigned Risk Champions have been notified", { variant: 'success' });
                resolve()
            })
    })
}

export const acceptChampionNomination = async (riskId: number) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Accepting Champion Nomination"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', '/api/Risk/AcceptChampionNomination?id=' + riskId, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Champion Nomination Accepted", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const rejectChampionNomination = async (riskId: number, rejectionComments: string) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Rejecting Champion Nomination"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/RejectChampionNomination?id=${riskId}&rejectionComments=${rejectionComments ?? ''}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Enterprise Risk team have been notified of your rejection of this Nomination", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

function mapRiskActionToRiskActionDto(riskAction: RiskAction): RiskActionDto {
    return ({ ...riskAction, otherEntityResponsible: riskAction.otherEntityResponsible?.id })
}

export const addRiskAction = async (risk: Risk, riskAction: RiskAction) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Adding New Action"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/AddRiskAction?id=${risk.id}`, mapRiskActionToRiskActionDto(riskAction), true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("New Action Added", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const updateRiskAction = async (risk: Risk, riskAction: RiskAction) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Action"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/UpdateRiskAction?id=${risk.id}&riskActionId=${riskAction.id}`, mapRiskActionToRiskActionDto(riskAction), true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Updated", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const deleteRiskAction = async (risk: Risk, actionIdToDelete: number) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Deleting Action"));
        callServerAPI<ServerAPIResponse<Risk>>('DELETE', `/api/Risk/DeleteRiskAction?id=${risk.id}&riskActionId=${actionIdToDelete}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Deleted", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const submitActionPlan = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Submitting Action Plan"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/SubmitActionPlan?id=${risk.id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Plan Submitted", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const approveRiskAction = async (risk: Risk, riskAction: RiskAction) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Approving Action"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/ApproveRiskAction?id=${risk.id}&riskActionId=${riskAction.id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Approved", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const rejectRiskAction = async (risk: Risk, riskAction: RiskAction, comments: string) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Rejecting Action"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/RejectRiskAction?id=${risk.id}&riskActionId=${riskAction.id}&comments=${comments}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Rejected", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const submitActionPlanValidation = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Submitting Action Plan Validation"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/SubmitActionPlanValidation?id=${risk.id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Validation Submitted. Risk Champion notified", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const updateRiskActionImplementation = async (risk: Risk, riskAction: RiskAction) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Action Implementation"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/UpdateRiskActionImplementation?id=${risk.id}&riskActionId=${riskAction.id}`, riskAction as RiskActionImplementationDto, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Implementation Updated", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const submitActionPlanImplementation = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Submitting Action Plan Implementation"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/SubmitActionPlanImplementation?id=${risk.id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Plan Implementation Submitted", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const approveActionPlanImplementation = async (risk: Risk) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop('Approving Action Implementation'));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/ApproveActionPlanImplementation?id=${risk.id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Plan Implementation Approved", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const rejectActionPlanImplementation = async (risk: Risk, comments: string) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Rejecting Action Plan Implementation"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/RejectActionPlanImplementation?id=${risk.id}&comments=${comments}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Action Plan Implementation Rejected", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

function mapRiskChangeToRiskChangeDto(riskChange: RiskChange): RiskChangeDto {
    return ({
        ...riskChange, categoryId: riskChange.category?.id,
        businessObjectiveImpactedId: riskChange.businessObjectiveImpacted?.id,
        entitiesImpactedIdsList: riskChange.entitiesImpacted.map(e => e.id),
        championEntity: riskChange.championEntity?.id
    })
}

export const submitRiskChange = async (risk: Risk, riskChange: RiskChange) => {
    return await new Promise<Risk>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating Risk with Changes"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/SubmitRiskChange?id=${risk.id}`, mapRiskChangeToRiskChangeDto(riskChange), true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Risk updated with changes", { variant: 'success' });
                resolve(response.data.data);
            })
    })
}

export const flagRiskAsUnexposed = async (risk: Risk) => {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Flagging Risk as Unexposed"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/FlagRiskAsUnexposed?id=${risk.id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Risk flagged as Unexposed", { variant: 'success' });
                resolve();
            })
    })
}

export const flagRiskAsExposed = async (risk: Risk) => {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("returning Risk to Approved (Exposed) status"));
        callServerAPI<ServerAPIResponse<Risk>>('PATCH', `/api/Risk/FlagRiskAsExposed?id=${risk.id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Risk returned to Approved Status", { variant: 'success' });
                resolve();
            })
    })
}