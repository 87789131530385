import { createTheme } from '@mui/material/styles';

export const infoColor = "#0288D1";
export const secondaryColor = "#cc0000";
export const primaryColor = "#000000";
export const backgroundColor = "#dddddd";

export function AppTheme() {
    return createTheme({
        palette: {
            primary: { main: primaryColor, contrastText: "#ffffff" },
            secondary: { main: secondaryColor, contrastText: "#ffffff" },
            info: { main: infoColor, contrastText: "#ffffff" },
            action: { selected: secondaryColor, focus: secondaryColor },
            background: { default: backgroundColor },
            text: { primary: primaryColor, secondary: infoColor, disabled: "#777777", },


        },
        typography: {
            htmlFontSize: 16,
            fontSize: 12,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
        }
    });
};