import MaterialTable from "@material-table/core";
import { Box, Paper, Grid, Autocomplete, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { UserProfile } from "../helpers/Interfaces";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";
import { infoColor } from "../layouts/theme";
import { getAllUserProfiles, createUserProfile, updateUserProfile, deleteUserProfile } from "../api-services/UserProfileApi";
import { useAppSelector } from "../store/store";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

export function ManageUserProfiles() {
    // state independent variables
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    //state
    const [userProfiles, setUserProfiles] = useState<UserProfile[]>([])

    useEffect(() => {
        getAllUserProfiles().then(userProfiles => setUserProfiles(userProfiles))
    }, [])


    function addRecord(newData: UserProfile) {
        return new Promise<void>((resolve, reject) => {
            createUserProfile(newData).then(result => {
                setUserProfiles(userProfiles => [...userProfiles, result])
                resolve()
            })
        })
    }

    function updateRecord(newData: UserProfile, oldData: UserProfile | undefined) {
        return new Promise<void>((resolve, reject) => {
            updateUserProfile(newData).then(result => {
                setUserProfiles(userProfiles => userProfiles.map(inst => inst.id === newData.id ? result : inst))
                resolve()
            })
        })
    }

    function deleteRecord(oldData: UserProfile) {
        return new Promise<void>((resolve, reject) => {
            deleteUserProfile(oldData.id).then(disabled => {
                if (disabled)
                    setUserProfiles(userProfiles => userProfiles.map(inst => inst.id === oldData.id ? { ...oldData, disabled: true } : inst))
                else
                    setUserProfiles(userProfiles => userProfiles.filter(inst => inst.id !== oldData.id))
                resolve()
            })
        })
    }


    return (
        <Box className='HorizontalOverFlowContainer'>
            <Paper className="Form">
                <br />
                <VerticalScrollableComponent viewPortHeightOffset={162}>
                    <Grid id="EntitiesViewContainer" container style={{ padding: 5 }} >
                        <Grid item xs={12}>
                            {
                                <MaterialTable components={{ Container: props => <Paper {...props} style={{ width: '100%' }} elevation={0} />, }}
                                    columns={[
                                        { title: 'IGG', field: 'userId', align: 'left', validate: rowData => rowData.userId ? true : 'IGG is required' },
                                        {
                                            title: 'Email', field: 'email', align: 'left', validate: rowData => rowData.email ? true : 'Email is required',
                                            editComponent: props =>
                                                <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="email" options={riskRelatedTables.cmdbUsers.map(c => c.email)}
                                                    value={props.value} onChange={(event, value) => { props.onChange(value) }} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" style={{ overflowWrap: 'anywhere' }} />)} />,
                                            render: data =>
                                                <Typography color="textPrimary" style={{ fontFamily: "Arial" }}> {data.email}</Typography>
                                        },
                                        { title: 'First Name', field: 'firstName', align: 'left', validate: rowData => rowData.firstName ? true : 'First Name is required' },
                                        { title: 'Last Name', field: 'lastName', align: 'left', validate: rowData => rowData.firstName ? true : 'Last Name is required' },
                                        { title: 'Admin', field: 'isAdmin', align: 'left', type: 'boolean', initialEditValue: false },
                                        { title: 'ERM Team Member', field: 'isERMTeamMember', align: 'left', type: 'boolean', initialEditValue: false },
                                        { title: 'Sponsor', field: 'isSponsor', align: 'left', type: 'boolean', initialEditValue: false },
                                        { title: 'View All (Identification)', field: 'riskIdentificationSheetDisplayAll', align: 'left', type: 'boolean', initialEditValue: false },
                                        { title: 'View All (Monitoring)', field: 'riskMonitoringSheetDisplayAll', align: 'left', type: 'boolean', initialEditValue: false },
                                    ]}
                                    data={userProfiles}
                                    editable={{
                                        onRowAdd: (newData) => addRecord(newData),
                                        onRowUpdate: (newData, oldData) => updateRecord(newData, oldData),
                                        onRowDelete: (oldData) => deleteRecord(oldData),
                                    }}

                                    localization={{ header: { actions: '' } }}

                                    options={{
                                        toolbarButtonAlignment: "left", searchFieldAlignment: 'left', paginationAlignment: "flex-start",
                                        pageSize: 5, pageSizeOptions: [5, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false, rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, actionsCellStyle: { width: '50px', color: infoColor }, headerStyle: { color: infoColor },
                                        exportAllData: true, exportMenu: [
                                            { label: 'Export PDF', exportFunc: (cols, datas) => { ExportPdf(cols, datas, "UserProfiles") } },
                                            { label: 'Export CSV', exportFunc: (cols, datas) => { ExportCsv(cols, datas, "UserProfiles") } }
                                        ]
                                    }}
                                />}
                        </Grid>
                    </Grid>
                </VerticalScrollableComponent>
                <br />

            </Paper>
        </Box>
    )
}
