import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServerGlobalSettings } from '../helpers/Interfaces';

interface ServerGlobalSettingsState {
  value: ServerGlobalSettings | null
}

export const serverGlobalSettings = createSlice({
  name: 'serverGlobalSettings',
  initialState: {
    value: null,
  } as ServerGlobalSettingsState,
  reducers: {
    setServerGlobalSettings: (state, action: PayloadAction<ServerGlobalSettings | null>) => {
      state.value = action.payload
    },
  },
})

export const { setServerGlobalSettings } = serverGlobalSettings.actions;
export default serverGlobalSettings.reducer;