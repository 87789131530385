import MaterialTable from "@material-table/core";
import { Box, Paper, Grid, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { RatingDescription } from "../helpers/Interfaces";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";
import { infoColor } from "../layouts/theme";
import { getAllRatingDescriptions, updateRatingDescription } from "../api-services/RatingDescriptionApi";

export function ManageRatingDescriptions() {
    //state
    const [ratingDescriptions, setRatingDescriptions] = useState<RatingDescription[]>([])

    useEffect(() => {
        getAllRatingDescriptions().then(ratingDescriptions => setRatingDescriptions(ratingDescriptions))
    }, [])


    function updateRecord(newData: RatingDescription, oldData: RatingDescription | undefined) {
        return new Promise<void>((resolve, reject) => {
            updateRatingDescription(newData).then(result => {
                setRatingDescriptions(ratingDescriptions => ratingDescriptions.map(inst => inst.id === newData.id ? result : inst))
                resolve()
            })
        })
    }

    return (
        <Box className='HorizontalOverFlowContainer'>
            <Paper className="Form">
                <br />
                <VerticalScrollableComponent viewPortHeightOffset={162}>
                    <Grid id="EntitiesViewContainer" container style={{ padding: 5 }} >
                        <Grid item xs={12}>
                            {
                                <MaterialTable components={{ Container: props => <Paper {...props} style={{ width: '3000px' }} elevation={0} />, }}
                                    columns={[
                                        { title: 'Type', field: 'ratingType', align: 'left', editable: 'never', width: 100 },
                                        {
                                            title: 'Rating 1 Description', field: 'rating1Description', align: 'left',
                                            editComponent: props =>
                                                <TextField style={{ fontSize: 11 }} fullWidth required id="rating1Description" type="text" variant="outlined" multiline size="small" minRows={3}
                                                    value={props.value} onChange={(event) => { props.onChange(event.target.value) }} />,
                                        },
                                        {
                                            title: 'Rating 2 Description', field: 'rating2Description', align: 'left',
                                            editComponent: props =>
                                                <TextField style={{ fontSize: 11 }} fullWidth required id="rating2Description" type="text" variant="outlined" multiline size="small" minRows={3}
                                                    value={props.value} onChange={(event) => { props.onChange(event.target.value) }} />,
                                        },
                                        {
                                            title: 'Rating 3 Description', field: 'rating3Description', align: 'left',
                                            editComponent: props =>
                                                <TextField style={{ fontSize: 11 }} fullWidth required id="rating3Description" type="text" variant="outlined" multiline size="small" minRows={3}
                                                    value={props.value} onChange={(event) => { props.onChange(event.target.value) }} />,
                                        },
                                        {
                                            title: 'Rating 4 Description', field: 'rating4Description', align: 'left',
                                            editComponent: props =>
                                                <TextField style={{ fontSize: 11 }} fullWidth required id="rating4Description" type="text" variant="outlined" multiline size="small" minRows={3}
                                                    value={props.value} onChange={(event) => { props.onChange(event.target.value) }} />,
                                        },
                                        {
                                            title: 'Rating 5 Description', field: 'rating5Description', align: 'left',
                                            editComponent: props =>
                                                <TextField style={{ fontSize: 11 }} fullWidth required id="rating5Description" type="text" variant="outlined" multiline size="small" minRows={3}
                                                    value={props.value} onChange={(event) => { props.onChange(event.target.value) }} />,
                                        },
                                        {
                                            title: 'Rating 6 Description', field: 'rating6Description', align: 'left',
                                            editComponent: props =>
                                                <TextField style={{ fontSize: 11 }} fullWidth required id="rating6Description" type="text" variant="outlined" multiline size="small" minRows={3}
                                                    value={props.value} onChange={(event) => { props.onChange(event.target.value) }} />,
                                        },
                                    ]}
                                    data={ratingDescriptions}
                                    editable={{
                                        onRowAdd: undefined,
                                        onRowUpdate: (newData, oldData) => updateRecord(newData, oldData),
                                        onRowDelete: undefined,
                                    }}

                                    localization={{ header: { actions: '' } }}

                                    options={{
                                        toolbarButtonAlignment: "left", searchFieldAlignment: 'left', paginationAlignment: "flex-start",
                                        pageSize: 5, pageSizeOptions: [5, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false, rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, actionsCellStyle: { width: '50px', color: infoColor }, headerStyle: { color: infoColor },
                                    }}
                                />}
                        </Grid>
                    </Grid>
                </VerticalScrollableComponent>
                <br />

            </Paper>
        </Box>
    )
}
